import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const CC_LOOKUP = gql`
  mutation careCreditAccountLookup($accountId: ID!) {
    careCreditAccountLookup(accountId: $accountId) ${CLIENT} {
      createdRequest
    }
  }
`;

export const CC_AUTHENTICATION = gql`
  mutation careCreditAuthentication($memberId: ID!, $accountId: ID!) {
    careCreditAuthentication(
      authParams: { accountId: $accountId, memberId: $memberId }
    ) ${CLIENT} {
      errors
      commandId
    }
  }
`;

// BillAmountInputType is taken from the patient gw by the infrastructure
// It's format is
//   type BillAmountInputType {
//     billId: ID!
//     amount: Integer!
//   }
//

export const CC_TRANSACTION = gql`
  mutation careCreditTransaction(
    $memberId: ID!
    $accountId: ID!
    $sessionToken: String!
    $billsAndAmounts: [BillAmountInputType!]
  ) {
    careCreditTransaction(
      transactionParams: {
        memberId: $memberId
        accountId: $accountId
        sessionToken: $sessionToken
        billsAndAmounts: $billsAndAmounts
      }
    ) ${CLIENT} {
      errors
      commandId
    }
  }
`;
