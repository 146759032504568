/**
 * invoke a function with a retry fallback upon rejection
 * @param {function} fn
 * @param {number} retriesNum
 * @param {number} interval
 * @return {Promise<any>}
 */
export const retryFunction = (fn, retriesNum = 1, interval = 500) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesNum === 0) {
            // maximum retries exceeded
            console.error('maximum retries exceeded', fn);
            reject(error);
            return;
          }
          console.error('retrying function...', fn);
          retryFunction(fn, interval, retriesNum - 1).then(resolve, reject);
        }, interval);
      });
  });
};

export const sleep = ms => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};
