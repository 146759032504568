export const paymentTypes = {
  FULL: 'full',
  PARTIAL: 'partial',
  SCHEDULED_PAYMENT: 'scheduled payment',
  PLAN_PAYMENT: 'plan payment',
  CARE_CREDIT_TYPE: 'care credit'
};

export const URL_PARAMS = {
  PAYMENT_TYPE: 'Payment_type',
  PAYMENT_AMOUNT: 'Payment_amount',
  ACCOUNT_BALANCE: 'Account_balance',
  PROVIDER: 'Provider',
  COMPANY: 'Company',
  PAYABLE_IDS: 'Payable_ids',
  BROWSER: 'Browser',
  DEVICE: 'Device'
};
