import { types } from 'mobx-state-tree';
import moment from 'moment';
import { FIND_ACCOUNT } from '../graphql/mutations';
import { VERIFY_CODE } from '../graphql/queries';
import ApiModel from './base/ApiModel';

const AccountLookup = types
  .model('AccountLookup', {
    identityType: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    dateOfBirth: types.maybeNull(types.string),
    foundAccount: types.maybeNull(types.string)
  })
  .actions(self => ({
    setSearchValues: function (values) {
      self.dateOfBirth = moment(values.dateOfBirth).format('YYYY-MM-DD');
      self.email = values.email;
      self.phoneNumber = values.phoneNumber.replace(/-/g, '');
      self.identityType = values.identityType;
    },
    findAccount: async values => {
      self.setSearchValues(values);
      return await self.mutate(FIND_ACCOUNT, values, { isCritical: false });
    },
    verifyCode: async values => {
      const {
        verifyCode: { accountNumber }
      } = await self.query(VERIFY_CODE, values, {
        isCritical: false
      });
      self.setFoundAccount(accountNumber);
    },
    setFoundAccount: accountNumber => {
      self.foundAccount = accountNumber;
    }
  }));

export default types.compose(ApiModel, AccountLookup);
