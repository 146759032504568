/**
 * Find key in object by value
 * findByValue({key_a:'value A', key_b:'value B'}, 'value B') = "key_b"
 * @param obj - the object to be iterated over
 * @param {String} val - the object value of which key we wish to find
 * @returns {string}
 */
export const findKeyByValue = (obj, val) =>
  Object.keys(obj).find(key => obj[key] === val);

/**
 * Check if specified property is in the specified object or its prototype chain
 * @param obj - object to assert the property on
 * @param {String} prop - the object key
 * @returns {boolean}
 */
export const hasProp = (obj, prop) => prop in obj;

/**
 * Check if object is empty {}
 * @param obj - object to assert the property on
 * @returns {boolean}
 */
export const isEmptyObject = obj => obj && Object.keys(obj).length === 0;
