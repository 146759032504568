import { css } from 'styled-components';
import MaterialUiTheme from './materialUiTheme';

const {
  breakpoints: { values }
} = MaterialUiTheme;

// Iterate through MaterialUiTheme breakpoint values and add them as media queries for Styled-Components

const vasArr = Object.keys(values);
export const media = {};
for (let i = vasArr.length - 1; i > -1; i--) {
  let next = values[vasArr[i + 1]];
  if (!next) {
    next = values[vasArr[i]] + (values[vasArr[i]] - values[vasArr[i - 1]]);
  }

  media[vasArr[i]] = (...args) => css`
    @media (max-width: ${next - 1}px) {
      ${css(...args)}
    }
  `;
}

/*
* this is a standard solution, however it effectively sets [media breakpoint].down
* so media.xs receives max-width:0
* media.sm receives max-width:600
* to truly match breakpoints with those of Material-Ui, a different solution is needed
*
*
export const media = Object.keys(values).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${values[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});
*/
