const _loadScript = (source, beforeEl, async = true, defer = true) => {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    const prior = beforeEl || document.getElementsByTagName('script')[0];

    script.async = async;
    script.defer = defer;

    const onloadHandler = (_, isAbort) => {
      if (
        isAbort ||
        !script.readyState ||
        /loaded|complete/.test(script.readyState)
      ) {
        script.onload = null;
        script.onreadystatechange = null;
        script = undefined;

        if (isAbort) {
          reject();
        } else {
          resolve();
        }
      }
    };

    script.onload = onloadHandler;
    script.onreadystatechange = onloadHandler;
    script.src = source;
    prior.parentNode.insertBefore(script, prior);
  });
};

export const loadScript = script => _loadScript(script);
export const loadScriptsList = async scriptsList => {
  for (const script of scriptsList) {
    try {
      await _loadScript(script);
    } catch (e) {
      return Promise.reject(e);
    }
  }
  return Promise.resolve();
};
