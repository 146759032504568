import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const CERNER_GET_TOKEN = gql`
  query cernerAuthenticationDetails($commandId: ID!) {
    cernerAuthenticationDetails(id: $commandId) ${CLIENT} {
      errors
      sessionId
      providerInternalName
      issuer
    }
  }
`;
