import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const FIND_ACCOUNT = gql`
  mutation findAccount(
    $identityType: String!
    $company: String!
    $provider: String!
    $email: String
    $phoneNumber: String
    $dateOfBirth: String!
  ) {
    findAccount(
      identityType: $identityType
      email: $email
      phoneNumber: $phoneNumber
      dateOfBirth: $dateOfBirth
      company: $company
      provider: $provider
    ) ${CLIENT}
  }
`;
