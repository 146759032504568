import { types } from 'mobx-state-tree';

const BillDiscount = types.model('BillDiscount', {
  discountAmount: types.number,
  claimNo: types.string
});

const Discount = types
  .model('Discount', {
    uniqueIdentifier: types.string,
    amount: types.maybeNull(types.number),
    expirationDate: types.maybeNull(types.string),
    billsDiscounts: types.optional(types.array(BillDiscount), [])
  })
  .actions(self => ({
    setDiscountFromSelectedBills: (selectedBillsIDs, bills) => {
      const addBillToDiscountList = (totalDiscount, bill) => {
        self.billsDiscounts.push({
          claimNo: bill.claimNo,
          discountAmount: bill.eligiblePromptDiscount
        });
        return totalDiscount + bill.eligiblePromptDiscount;
      };

      self.billsDiscounts = []; // clear existing discounts
      let filteredBills = bills.filter(b => !!b.eligiblePromptDiscount);
      if (selectedBillsIDs.length) {
        filteredBills = filteredBills.filter(b =>
          selectedBillsIDs.includes(b.id)
        );
      }
      self.amount = filteredBills.reduce(addBillToDiscountList, 0);
    }
  }));

export default Discount;
