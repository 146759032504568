import { flow, types } from 'mobx-state-tree';
import { GET_COMMAND_RESULT } from '../graphql/queries';
import ApiModel from './base/ApiModel';

const REQUEST_TIME_INTERVAL = 2000;
const MAX_ATTEMPTS = 5;

const ResultModel = types.model('ResultModel', {
  isSuccess: types.optional(types.boolean, false),
  description: types.optional(types.string, '')
});
export const Command = types
  .model('Command', {
    id: types.maybeNull(types.string),
    status: types.maybeNull(
      types.enumeration('Status', ['DONE', 'IN_PROGRESS'])
    ),
    result: types.maybe(ResultModel)
  })
  .views(self => ({
    get computedResult() {
      // Define a computed view to get the result based on your model's data
      if (self.result) {
        return {
          isSuccess: self.result.isSuccess,
          description: self.result.description
        };
      }
      return null; // Return null if there's no result
    }
  }))
  .actions(self => ({
    fetchCommand: flow(function* (commandId) {
      console.log(`fetching command ${commandId}`);
      self.id = commandId;

      try {
        const { commandV2 } = yield self.query(
          GET_COMMAND_RESULT,
          {
            id: commandId
          },
          { isCritical: false }
        );
        self.status = commandV2.status;
        if (self.status === 'DONE')
          self.result = {
            ...commandV2.result
          };
      } catch (error) {
        console.error(
          `Error while fetching command with id ${commandId} :`,
          error
        );
        throw error;
      }
    }),
    pollCommand: flow(function* (commandId) {
      console.log(`polling command ${commandId}`);
      try {
        yield self.poll(
          () => self.fetchCommand(commandId),
          () => self.status === 'DONE',
          REQUEST_TIME_INTERVAL,
          MAX_ATTEMPTS
        );

        return {
          isSuccess: true
        };
      } catch (error) {
        return {
          isSuccess: false,
          error: error
        };
      }
    })
  }));

export default types.compose(ApiModel, Command);
