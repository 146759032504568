import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import context from './sharedCompnentsContext';

/**
 * provide context for all shared_components
 * this component should be added at <App/> level
 */
const ContextProvider = ({ children, language }) => {
  const [currentLanguage, setLanguage] = useState();

  //console.log('------------------------currentLanguage:', currentLanguage);

  useEffect(() => {
    //listen to language change and load translation
    const checkLanguage = async () => {
      if (language && language !== currentLanguage) {
        setLanguage(language);
      }
    };

    checkLanguage();
  }, [language]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <context.Provider value={{ currentLanguage }}>
        {children}
      </context.Provider>
    </LocalizationProvider>
  );
};

export default ContextProvider;
