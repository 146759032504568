import { types } from 'mobx-state-tree';

import { isEmptyObject } from '../utils/Object';
import { paymentOptionTypes } from '../constants/paymentOptions';
import FullOffers from './FullOffers';
import { paymentTypes } from '../constants/mixpanel';
import { otherPaymentOptions } from '../constants/fullOffers';

const PaymentOptionsCalculator = types
  .model('PaymentOptionsCalculator', {
    name: 'PaymentOptionsCalculator',
    assistFeature: types.optional(types.boolean, false),
    paymentPlanSetting: types.optional(types.boolean, false),
    isPPv4: types.optional(types.boolean, false),
    maxPaymentsNumber: types.optional(types.number, 0),
    isEligibleForPaymentPlan: types.optional(types.boolean, false),
    hasPaymentPlan: types.optional(types.boolean, false),
    partialPaymentPermission: types.optional(types.boolean, false),
    careCreditOptionPermission: types.optional(types.boolean, false), //unused??
    fullOffers: types.maybeNull(FullOffers),
    useFullOffers: types.optional(types.boolean, false),
    isCuraeOn: types.optional(types.boolean, false),
    limitPPOffers: types.optional(types.boolean, false)
  })
  .actions(self => {
    const {
      CUSTOM_PLAN,
      FULL_PAYMENT,
      PARTIAL_PAYMENT,
      PAYMENT_PLAN,
      CARE_CREDIT,
      QUICK_ACTION,
      CURAE
    } = paymentOptionTypes;
    let _ppOffer, _amount;

    const {
      PAYMENT,
      ASSIST_PP,
      PAY_FULL_PLAN,
      ADD_OUTSIDE_BALANCE,
      FINANCING_PP,
      INSIDE_FINANCING_PP,
      OUTSIDE_FINANCING_PP
    } = paymentTypes;

    const getSinglePaymentOptions = (
      paymentType,
      hasPaymentPlan,
      financingOption,
      isSelected = true
    ) => [
      {
        paymentType: FULL_PAYMENT,
        defaultSelection: isSelected,
        fullAmount: _amount
      },
      ...(self.partialPaymentPermission
        ? [{ paymentType: PARTIAL_PAYMENT, fullAmount: _amount }]
        : []),
      ...(((paymentType === PAYMENT && !hasPaymentPlan) ||
        paymentType === PAY_FULL_PLAN) &&
      financingOption
        ? [
            {
              paymentType: financingOption,
              defaultSelection: false,
              fullAmount: _amount
            }
          ]
        : [])
    ];

    const isOfferValid = offer =>
      self.useFullOffers
        ? !!offer
        : self.isEligibleForPaymentPlan &&
          offer &&
          offer <= self.maxPaymentsNumber;
    //&& amount/offer >= self.limits.minPaymentAmount

    const getAmountPerInstallment = installments =>
      Math.floor(_amount / installments);

    const getPPOption = (ppOffers, offerLocation) =>
      isOfferValid(ppOffers[`${offerLocation}Offer`]) &&
      ppOffers[`${offerLocation}Offer`] >= 3 && {
        fullAmount: ppOffers[`${offerLocation}OfferFullAmount`],
        paymentType: PAYMENT_PLAN,
        payments: ppOffers[`${offerLocation}Offer`],
        amountPerInstallment: ppOffers[`${offerLocation}OfferAmountPerPayment`],
        ...(ppOffers[`${offerLocation}Offer`] === ppOffers.defaultOption && {
          defaultSelection: true
        })
      };

    const getPPOptions = ppOffers =>
      ['first', 'second', 'third'].map(location =>
        getPPOption(ppOffers, location)
      );

    const addOffer = (offerObj, offersList) => {
      const { offer, amount, amountPerPayment, payzenId } = offerObj;
      isOfferValid(offer) &&
        offersList.push({
          paymentType: PAYMENT_PLAN,
          fullAmount: amount || _amount,
          payments: offer,
          payzenId: payzenId,
          amountPerInstallment:
            amountPerPayment || getAmountPerInstallment(offer),
          defaultSelection: self.useFullOffers
            ? self.fullOffers.recommendedOffer === offer
            : _ppOffer.defaultOption === offer
        });
    };

    const addOffers = offersList => {
      const validOffers = [];
      offersList.forEach(offerObj => addOffer(offerObj, validOffers));
      return validOffers;
    };

    const addCustomPpOption = (list, pushNewList, defaultSelection) => {
      const objToPush = {
        paymentType: CUSTOM_PLAN,
        fullAmount: _amount,
        ...(defaultSelection && { defaultSelection: defaultSelection })
      };

      self.isEligibleForPaymentPlan &&
        list.push(pushNewList ? [objToPush] : objToPush);
    };

    const populateFirstOptions = (
      firstTwoPpOffers,
      thirdPpOffer,
      paymentType,
      financingOption,
      amount
    ) => {
      return [
        ...addOffers([
          ...firstTwoPpOffers,
          ...((paymentType === FINANCING_PP && thirdPpOffer) || [])
        ]),
        ...((paymentType === ASSIST_PP &&
          financingOption && [
            {
              paymentType: financingOption,
              defaultSelection: false,
              fullAmount: amount
            }
          ]) ||
          [])
      ];
    };

    const populateSecondOptions = (paymentType, firstOptions, thirdPpOffer) => {
      //more options
      let secondOptions = [];
      if (firstOptions.length > 1 && paymentType !== FINANCING_PP)
        secondOptions = addOffers(thirdPpOffer);

      return secondOptions;
    };

    return {
      getPaymentOptionsPPv4: (
        paymentType,
        amount,
        limitedOffer,
        hasPaymentPlan,
        careCreditOption,
        specialAmount
      ) => {
        const {
          recommendedOffer,
          shortOffer,
          longOffer,
          recommendedOfferTotalAmountPerInstallment,
          shortOfferTotalAmountPerInstallment,
          longOfferTotalAmountPerInstallment,
          recommendedOfferTotalBalanceIncludingFees,
          shortOfferTotalBalanceIncludingFees,
          longOfferTotalBalanceIncludingFees,
          recommendedOfferPzId,
          longOfferPzId,
          shortOfferPzId,
          otherOptions,
          validForPlan
        } = self.fullOffers || {};

        const { CUSTOM_PAYMENT_PLAN } = otherPaymentOptions;

        return self.getPaymentOptions(
          paymentType,
          amount,
          limitedOffer,
          {
            defaultOption: recommendedOffer,
            firstOffer: shortOffer,
            firstOfferFullAmount: shortOfferTotalBalanceIncludingFees,
            firstOfferAmountPerPayment: shortOfferTotalAmountPerInstallment,
            firstOfferPzId: shortOfferPzId,
            secondOffer: recommendedOffer,
            secondOfferFullAmount: recommendedOfferTotalBalanceIncludingFees,
            secondOfferAmountPerPayment:
              recommendedOfferTotalAmountPerInstallment,
            secondOfferPzId: recommendedOfferPzId,
            thirdOffer: longOffer,
            thirdOfferFullAmount: longOfferTotalBalanceIncludingFees,
            thirdOfferAmountPerPayment: longOfferTotalAmountPerInstallment,
            thirdOfferPzId: longOfferPzId,
            otherOptionsPatient:
              otherOptions === CUSTOM_PAYMENT_PLAN ? 'custom payment plan' : '',
            validForPlan: validForPlan
          },
          hasPaymentPlan,
          careCreditOption,
          specialAmount
        );
      },
      getPaymentOptions: (
        paymentType,
        amount,
        limitedOffer,
        ppOffer = {},
        hasPaymentPlan,
        careCreditOption,
        specialAmount
      ) => {
        let financingOption;
        if (careCreditOption) financingOption = CARE_CREDIT;
        else if (self.isCuraeOn) financingOption = CURAE;

        const options = [];
        _ppOffer = ppOffer;
        _amount = amount;
        const limitedOfferBetweenOneAndSix =
          limitedOffer && limitedOffer < 6 && limitedOffer > 1;
        const isCustom =
          !self.limitPPOffers &&
          ppOffer &&
          paymentType !== FINANCING_PP &&
          ppOffer.otherOptionsPatient === 'custom payment plan';
        const limitedOfferIsOne = (isSelected = false) => {
          //statically add an offer
          //for PPv4, use third (long) offer in this case
          isOfferValid(ppOffer?.defaultOption) &&
            options.push([
              {
                fullAmount: ppOffer.thirdOfferFullAmount || _amount,
                paymentType: PAYMENT_PLAN,
                payments: ppOffer.thirdOffer || ppOffer.defaultOption,
                amountPerInstallment:
                  ppOffer.thirdOfferAmountPerPayment ||
                  getAmountPerInstallment(ppOffer?.defaultOption),
                ...(isSelected && { defaultSelection: true })
              }
            ]);
          isCustom && addCustomPpOption(options, true);
        };
        const limitedPPOffersOptions = () => {
          const [firstOffer, secondOffer, thirdOffer] = getPPOptions(ppOffer);
          if (limitedOffer === 3) {
            options.push([
              ...((secondOffer && [secondOffer]) || []),
              ...((thirdOffer && [thirdOffer]) || [])
            ]);
          } else if (limitedOffer > 3) {
            options.push(
              ...[
                [
                  ...((firstOffer && [firstOffer]) || []),
                  ...((secondOffer && [secondOffer]) || []),
                  ...((!firstOffer && thirdOffer && [thirdOffer]) || [])
                ],
                ...((firstOffer &&
                  secondOffer &&
                  thirdOffer && [[thirdOffer]]) ||
                  [])
              ]
            );
          }
        };
        const limitedOfferIsSix = (isSelected = false) => {
          //add a 6 offer, 12 for 'need more options'
          //in this specific scenario in PPv2, ppOffer.defaultOption is the same as firstOffer
          //in PPv4, shortOffer (being passed as here firstOffer) is 0, so take the second and third offers
          if (isOfferValid(ppOffer.defaultOption)) {
            options[0].push({
              fullAmount: ppOffer.secondOfferFullAmount || _amount,
              paymentType: PAYMENT_PLAN,
              payments: ppOffer.defaultOption,
              amountPerInstallment:
                ppOffer.secondOfferAmountPerPayment ||
                getAmountPerInstallment(ppOffer.defaultOption),
              ...(isSelected && { defaultSelection: true })
            });

            const nextOffer = !self.useFullOffers
              ? ppOffer.secondOffer
              : ppOffer.thirdOffer;

            isOfferValid(nextOffer) &&
              options.push([
                {
                  fullAmount: ppOffer.thirdOfferFullAmount || _amount,
                  paymentType: PAYMENT_PLAN,
                  payments: nextOffer,
                  amountPerInstallment:
                    ppOffer.thirdOfferAmountPerPayment ||
                    getAmountPerInstallment(nextOffer)
                }
              ]);
            isCustom && addCustomPpOption(options, true);
          }
        };
        const limitedOfferIsLessThanSix = () => {
          //PPv2: user has a valid limitedOffer not found in assistOfferByScore
          //PPv4: recommended offer is < 6; shortOffer (being passed as here firstOffer) is 0, so take second;
          isOfferValid(limitedOffer) &&
            options[0].push({
              fullAmount: ppOffer?.secondOfferFullAmount || _amount,
              paymentType: PAYMENT_PLAN,
              payments: limitedOffer,
              amountPerInstallment:
                ppOffer?.secondOfferAmountPerPayment ||
                getAmountPerInstallment(limitedOffer)
            });
          addCustomPpOption(options, true);
        };
        const isValidForManualPlanV4 =
          !(isEmptyObject(ppOffer) || ppOffer === null) &&
          ppOffer.validForPlan &&
          ppOffer.defaultOption === 0 &&
          self.isPPv4;
        const isValidForManualPlanV2 =
          !self.assistFeature &&
          self.paymentPlanSetting &&
          !self.isPPv4 &&
          (!hasPaymentPlan || paymentType === ADD_OUTSIDE_BALANCE);
        if (
          [
            PAYMENT,
            PAY_FULL_PLAN,
            INSIDE_FINANCING_PP,
            OUTSIDE_FINANCING_PP
          ].includes(paymentType)
        ) {
          if (
            limitedOffer === 0 &&
            !isValidForManualPlanV4 &&
            !self.partialPaymentPermission
          ) {
            //straight to payment information page
            return null;
          }
          options.push(
            getSinglePaymentOptions(
              paymentType,
              hasPaymentPlan,
              financingOption
            )
          );
          //static exceptions
          if (self.limitPPOffers) {
            return options;
          } else if (limitedOffer === 1) {
            limitedOfferIsOne();
          } else if (limitedOffer === 6) {
            limitedOfferIsSix();
          } else if (isValidForManualPlanV2 || isValidForManualPlanV4) {
            addCustomPpOption(options[0], null);
          } else if (
            limitedOfferBetweenOneAndSix &&
            ((!self.useFullOffers &&
              (isEmptyObject(ppOffer) || ppOffer === null)) ||
              self.useFullOffers)
          ) {
            limitedOfferIsLessThanSix();
          }
        } else if (
          [ASSIST_PP, ADD_OUTSIDE_BALANCE, FINANCING_PP].includes(
            paymentType
          ) ||
          (paymentType === QUICK_ACTION && !!self.fullOffers?.recommendedOffer)
        ) {
          if (!ppOffer || isEmptyObject(ppOffer)) {
            //no assistOfferByScore entry found, return single payment options
            if (limitedOfferBetweenOneAndSix && !self.useFullOffers) {
              options[0] = [];
              limitedOfferIsLessThanSix();
            } else {
              options.push(
                getSinglePaymentOptions(PAYMENT, false, careCreditOption)
              );
              isValidForManualPlanV2 && addCustomPpOption(options, true);
            }
            return options;
          }
          if (
            self.limitPPOffers &&
            [ASSIST_PP, QUICK_ACTION].includes(paymentType)
          ) {
            limitedPPOffersOptions();
          } else if (limitedOffer === 1) {
            limitedOfferIsOne(true);
          } else if (limitedOffer === 6) {
            options.push([]);
            limitedOfferIsSix(true);
          } else {
            const firstTwoPpOffers = [
              {
                offer: ppOffer.firstOffer,
                amount: _ppOffer.firstOfferFullAmount,
                amountPerPayment: _ppOffer.firstOfferAmountPerPayment,
                payzenId: _ppOffer.firstOfferPzId
              },
              {
                offer: ppOffer.secondOffer,
                amount: _ppOffer.secondOfferFullAmount,
                amountPerPayment: _ppOffer.secondOfferAmountPerPayment,
                payzenId: _ppOffer.secondOfferPzId
              }
            ];
            const thirdPpOffer = [
              {
                offer: ppOffer.thirdOffer,
                amount: _ppOffer.thirdOfferFullAmount,
                amountPerPayment: _ppOffer.thirdOfferAmountPerPayment,
                payzenId: _ppOffer.thirdOfferPzId
              }
            ];

            const firstOptions = populateFirstOptions(
              firstTwoPpOffers,
              thirdPpOffer,
              paymentType,
              financingOption,
              _amount
            );
            const secondOptions = populateSecondOptions(
              paymentType,
              firstOptions,
              thirdPpOffer
            );
            options.push(firstOptions);
            secondOptions.length && options.push(secondOptions);
            isCustom && addCustomPpOption(options, true);
          }
        }
        if (paymentType === QUICK_ACTION) {
          if (!options[0]) options[0] = [];
          if (
            self.isPPv4 &&
            !self.fullOffers?.recommendedOffer &&
            self.fullOffers?.validForPlan
          )
            addCustomPpOption(options[0], false, true);
          if (specialAmount) _amount = specialAmount;
          options[0].push(
            ...getSinglePaymentOptions(PAYMENT, false, false, false)
          );
        }
        return options;
      }
    };
  });

export default PaymentOptionsCalculator;
