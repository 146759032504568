import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { storeContext } from '../context';
import AppLoaderHandler from './general/Loaders/AppLoaderHandler';

const AppContainer = observer(({ children }) => {
  const { dataLoaded } = useContext(storeContext);
  return <>{dataLoaded ? children : <AppLoaderHandler />}</>;
});

export default AppContainer;
