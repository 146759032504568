import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const CREDIT_CARD_PAYMENT = gql`
  mutation creditCardPayment(
    $emailAddress: PaymentEmailAddress!
    $email: String
    $changeEmail: Boolean
    $paymentAttributes: PaymentAttributeInput!
    $appointmentsPaymentsAttributes: AppointmentsPaymentsAttributesInput
    $totalPaymentAmount: Int
    $feeAmount: Int
    $savedCreditCard: SavedCreditCardInput
    $creditCard: CreditCardInput
    $scheduledOn: String
    $isTowardsPayzenPlan: Boolean
  ) {
    creditCardPayment(
      emailAddress: $emailAddress
      email: $email
      changeEmail: $changeEmail
      paymentAttributes: $paymentAttributes
      appointmentsPaymentsAttributes: $appointmentsPaymentsAttributes
      totalPaymentAmount: $totalPaymentAmount
      feeAmount: $feeAmount
      savedCreditCard: $savedCreditCard
      creditCard: $creditCard
      scheduledOn: $scheduledOn
      isTowardsPayzenPlan: $isTowardsPayzenPlan
    ) ${CLIENT} {
      errors
      payment {
        id
        email
        payments {
          id
        }
        creditCard {
          id
          expiredAt
        }
        uniqueId
      }
      pullPaymentStatus
      pullPaymentRequestId
    }
  }
`;

export const CREATE_PAYMENT_PLAN = gql`
  mutation createPaymentPlan(
    $emailAddress: PaymentEmailAddress
    $email: String
    $changeEmail: Boolean
    $savedCreditCard: SavedCreditCardInput
    $creditCard: CreditCardInput
    $echeck: EcheckInput
    $savedEcheck: SavedEcheckInput
    $paymentPlan: PaymentPlanInput!
    $paymentPlanId: ID
    $version: String
  ) {
    createPaymentPlan(
      emailAddress: $emailAddress
      email: $email
      changeEmail: $changeEmail
      savedCreditCard: $savedCreditCard
      creditCard: $creditCard
      echeck: $echeck
      savedEcheck: $savedEcheck
      paymentPlan: $paymentPlan
      paymentPlanId: $paymentPlanId
      version: $version
    ) ${CLIENT} {
      errors
      commandId
      paymentPlan {
        uniqueId
      }
    }
  }
`;

export const UPDATE_PAYMENT_PLAN = gql`
  mutation updatePaymentPlan(
    $emailAddress: PaymentEmailAddress
    $email: String
    $changeEmail: Boolean
    $savedCreditCard: SavedCreditCardInput
    $creditCard: CreditCardInput
    $echeck: EcheckInput
    $savedEcheck: SavedEcheckInput
    $paymentPlan: PaymentPlanInput!
    $paymentPlanId: ID!
    $version: String
  ) {
    updatePaymentPlan(
      emailAddress: $emailAddress
      email: $email
      changeEmail: $changeEmail
      savedCreditCard: $savedCreditCard
      creditCard: $creditCard
      echeck: $echeck
      savedEcheck: $savedEcheck
      paymentPlan: $paymentPlan
      paymentPlanId: $paymentPlanId
      version: $version
    ) ${CLIENT} {
      errors
      commandId
      paymentPlan {
        uniqueId
      }
    }
  }
`;

export const ECHECK_PAYMENT = gql`
  mutation echeckPayment(
    $emailAddress: PaymentEmailAddress!
    $email: String
    $changeEmail: Boolean
    $paymentAttributes: PaymentAttributeInput!
    $appointmentsPaymentsAttributes: AppointmentsPaymentsAttributesInput
    $totalPaymentAmount: Int
    $feeAmount: Int
    $echeck: EcheckInput!
    $isTowardsPayzenPlan: Boolean
  ) {
    echeckPayment(
      emailAddress: $emailAddress
      email: $email
      changeEmail: $changeEmail
      paymentAttributes: $paymentAttributes
      appointmentsPaymentsAttributes: $appointmentsPaymentsAttributes
      totalPaymentAmount: $totalPaymentAmount
      feeAmount: $feeAmount
      echeck: $echeck
      isTowardsPayzenPlan: $isTowardsPayzenPlan
    ) ${CLIENT} {
      errors
      payment {
        createdAt
        email
        id
        uniqueId
      }
    }
  }
`;

export const CONVERT_PAYMENT_PLAN = gql`
  mutation editPaymentPlanBilling(
    $emailAddress: PaymentEmailAddress
    $email: String
    $changeEmail: Boolean
    $savedCreditCard: SavedCreditCardInput
    $creditCard: CreditCardInput
    $echeck: EcheckInput
    $paymentPlanId: ID!
    $accountNumber: String
  ) {
    editPaymentPlanBilling(
      emailAddress: $emailAddress
      email: $email
      changeEmail: $changeEmail
      savedCreditCard: $savedCreditCard
      creditCard: $creditCard
      echeck: $echeck
      paymentPlanId: $paymentPlanId
      accountNumber: $accountNumber
    ) ${CLIENT} {
      errors
      commandId
      paymentPlan {
        paymentMethodType
        paymentMethod {
          cardType
          id
        }
        uniqueId
      }
    }
  }
`;

export const PAYPAL_PAYMENT = gql`
  mutation paypalPayment(
    $emailAddress: PaymentEmailAddress!
    $email: String
    $changeEmail: Boolean
    $paymentAttributes: PaymentAttributeInput!
    $appointmentsPaymentsAttributes: AppointmentsPaymentsAttributesInput
    $totalPaymentAmount: Int
    $feeAmount: Int
    $paypal: PaypalInput!
  ) {
    paypalPayment(
      emailAddress: $emailAddress
      email: $email
      changeEmail: $changeEmail
      paymentAttributes: $paymentAttributes
      appointmentsPaymentsAttributes: $appointmentsPaymentsAttributes
      totalPaymentAmount: $totalPaymentAmount
      feeAmount: $feeAmount
      paypal: $paypal
    ) ${CLIENT} {
      errors
      payment {
        createdAt
        email
        id
        uniqueId
      }
    }
  }
`;

export const CURAE_PAYMENT = gql`
  mutation curaePayment(
    $emailAddress: PaymentEmailAddress!
    $email: String
    $changeEmail: Boolean
    $paymentAttributes: PaymentAttributeInput!
    $appointmentsPaymentsAttributes: AppointmentsPaymentsAttributesInput
    $totalPaymentAmount: Int
    $feeAmount: Int
  ) {
    curaePayment(
      emailAddress: $emailAddress
      email: $email
      changeEmail: $changeEmail
      paymentAttributes: $paymentAttributes
      appointmentsPaymentsAttributes: $appointmentsPaymentsAttributes
      totalPaymentAmount: $totalPaymentAmount
      feeAmount: $feeAmount
    ) ${CLIENT} {
      errors
      curaeTransactionId
    }
  }
`;

export const PAY_TOWARD_FINANCED_PLAN = gql`
  mutation financedPlanPatientPayment(
    $amount: Int!
    $payableId: ID!
    $genericIdentifier: ID!
    $memberId: ID!
    $savedCreditCard: SavedCreditCardInput
    $savedEcheck: SavedEcheckInput
    $creditCard: CreditCardInput
    $echeck: EcheckInput
  ) {
    financedPlanPatientPayment(
      amount: $amount
      payableId: $payableId
      genericIdentifier: $genericIdentifier
      memberId: $memberId
      savedCreditCard: $savedCreditCard
      savedEcheck: $savedEcheck
      creditCard: $creditCard
      echeck: $echeck
    ) ${CLIENT} {
      errors
      commandId
    }
  }
`;

export const PAYMENT_STATUS_CHECK = gql`
  query paymentStatusCheck(
    $requestId: String!
  ) {
    paymentStatusCheck(
      requestId: $requestId
    ) ${CLIENT} {
      status
      response
    }
  }
`;

export const CREATE_CREDIT_CARD = gql`
  mutation createCreditCard($cardId: Int, $memberId: Int!, $creditCard: CreditCardInput) {
    creditCard(cardId: $cardId, memberId: $memberId, creditCard: $creditCard) ${CLIENT} {
      id
      billingName
      cardType
      expiredAt
      financingCustomerToken
      financingProcessorToken
      last4
    }
  }
`;

export const CREATE_ECHECK = gql`
  mutation createEcheck($echeckId: Int, $memberId: Int!, $echeck: EcheckInput) {
    echeck(echeckId: $echeckId, memberId: $memberId, echeck: $echeck) ${CLIENT} {
      id
      billingName
      accountType
      routingNumber
      accountNumber
      financingCustomerToken
      financingProcessorToken
    }
  }
`;

export const DIGITAL_WALLET_CREATE_SESSION = gql`
    mutation digitalWalletCreateSession(
      $walletType: String!,
      $isPaymentPlan: Boolean!,
      $initiativeContextUrl: String!
    ) {
      digitalWalletCreateSession(
      walletType: $walletType,
      isPaymentPlan: $isPaymentPlan
      initiativeContextUrl: $initiativeContextUrl
    ) ${CLIENT} {
      sessionResponse
    }
  }
`;

export const DIGITAL_WALLET_PAYMENT = gql`
  mutation digitalWalletPayment(
    $emailAddress: PaymentEmailAddress!
    $paymentAttributes: PaymentAttributeInput!
    $digitalWallet: DigitalWalletInputType!
  ) {
    digitalWalletPayment(
      emailAddress: $emailAddress
      paymentAttributes: $paymentAttributes
      digitalWallet: $digitalWallet
    ) ${CLIENT} {
      payment {
        createdAt
        email
        id
        uniqueId
      }
    }
  }
`;
