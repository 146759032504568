import { flow, types } from 'mobx-state-tree';
import { GET_LIMITS } from '../graphql/queries';
import ApiModel from './base/ApiModel';

const Limits = types
  .model('Limits', {
    maxPaymentsNumber: types.number,
    minStandingAmount: types.number,
    lastPaymentMinAmount: types.number,
    minPaymentAmount: types.number,
    dataApplied: types.optional(types.boolean, true)
  })
  .actions(self => {
    const _limits = { 0: {} };
    return {
      getLimits: flow(function* (standingAmount, offer) {
        self.dataApplied = false;
        if (!_limits[standingAmount]) {
          try {
            const { limits } = yield self.query(GET_LIMITS, {
              standingAmount: standingAmount,
              offer: offer
            });
            _limits[standingAmount] = limits;
          } catch (e) {
            console.log('Limits load error:', e);
          }
        }
        return Promise.resolve({
          limits: _limits[standingAmount],
          standingAmount
        });
      }),
      applyLimits: standingAmount => {
        self.applyWithApiStatus(_limits[standingAmount]);
      }
    };
  });

export default types.compose(ApiModel, Limits);
