import { types, getSnapshot } from 'mobx-state-tree';

/**
 * common model actions
 * MobX models can compose and implement
 */

const CommonActions = types.model('CommonActions', {}).actions(self => ({
  hasDataChanged: (newParams, excludePropsList = []) => {
    console.log('-------hasDataChanged:', excludePropsList);
    const currentData = getSnapshot(self);
    let hasChanged = false;
    let changedProps = null;
    for (let key in newParams) {
      //assert no change in currentData snapshot && self.views getters
      if (
        newParams[key] !== currentData[key] &&
        newParams[key] !== self[key] &&
        !excludePropsList.includes(key)
      ) {
        hasChanged = true;
        !changedProps && (changedProps = []);
        changedProps.push(key);
        //console.log('has changed:', key);
        //console.log('newParams[key]:', newParams[key], typeof newParams[key]);
        //console.log('self[key]:', self[key], typeof self[key]);
      }
    }
    return { changed: hasChanged, changedProps };
  },
  changeNullToEmptyString: () => {
    const iterate = obj => {
      for (let key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
          iterate(obj[key]);
        } else if (obj[key] === null) {
          obj[key] = '';
        }
      }
    };
    iterate(self);
  }
}));

export default CommonActions;
