import { onPatch } from 'mobx-state-tree';

const _listeners = {};
const _listEntries = {};
const _createEntry = path => {
  if (!_listeners[path]) {
    _listeners[path] = [];
  }
};

/**
 * onPatch listener, if patch.path is registered, all callbacks with path will be invoked
 * currently supports only 1 instance (model) that serves RootStore, can be expanded to support multiple models if needed
 * onPatchListener.init(self)
 * onPatchListener.register({ path: '/translations/currentLanguage' }, onTranslationsLoaded, true)
 * onPatchListener.registerList([{ path: '/providerLinks/dataApplied', value: true },{ path: '/settings/dataApplied', value: true }], onDataLoaded);
 */

export default {
  init: model => {
    onPatch(model, patch => {
      // console.log('PATH: ' + patch.path)
      const removeEntryFromListeners = (obj, index) =>
        !obj.persist && _listeners[patch.path].splice(index, 1);
      if (_listeners[patch.path]) {
        //invoke all callbacks and remove _listeners entry
        _listeners[patch.path].forEach((obj, index) => {
          if (!obj.value || (obj.value && patch.value === obj.value)) {
            if (obj.listId) {
              //remove entry from list
              _listEntries[obj.listId] = _listEntries[obj.listId].filter(
                entry => entry.path !== patch.path
              );
              for (let listId in _listEntries) {
                if (_listEntries[listId].length) {
                  removeEntryFromListeners(obj, index);
                } else {
                  //all entries in list are complete,  invoke callback
                  obj.callback();
                  removeEntryFromListeners(obj, index);
                }
              }
            } else {
              obj.callback();
              removeEntryFromListeners(obj, index);
            }
          }
        });
      }
    });
  },
  /**
   *
   * @param path {String} = patch path
   * @param value {*} = optional: new value in patch
   * @param callback {Function} = the callback to be executed
   * @param persist {Boolean} = should the listener entry persist indefinitely or be removed once callback was called
   */
  register: ({ path, value }, callback, persist = false) => {
    _createEntry(path);
    _listeners[path].push({ callback, value, persist });
  },

  /**
   * @param {Object[]} list
   * @param {String} list[].path =  patch path
   * @param {*} list[].value = option: new value in patch
   * @param callback {Function} = the callback to be executed
   * @param persist {Boolean} = should the listener entry persist indefinitely or be removed once callback was called
   */
  registerList: (list, callback, persist = false) => {
    const listId = Math.floor(Math.random() * 100000);
    _listEntries[listId] = [...list];

    list.forEach(entry => {
      _createEntry(entry.path);
      _listeners[entry.path].push({
        callback,
        value: entry.value,
        persist,
        listId
      });
    });
  }
};
