export const paymentOptionTypes = {
  FULL_PAYMENT: 'fullPayment',
  PARTIAL_PAYMENT: 'partialPayment',
  PAYMENT_PLAN: 'paymentPlan',
  NEXT_INSTALLMENT: 'nextInstallment',
  CUSTOM_PLAN: 'customPlan',
  CARE_CREDIT: 'care_credit',
  QUICK_ACTION: 'quickActionPage',
  CURAE: 'curae'
};

export const customPlanErrors = {
  BELOW_MIN_AMOUNT: 1,
  OVER_MAX_AMOUNT: 2,
  GENERAL_ERROR: 3
};

export const footerToShow = {
  PAYMENT_FOOTER: 'payment_footer',
  CARE_CREDIT_FOOTER: 'care_credit_footer'
};

export const paymentHeaderType = {
  PAY_NOW: 'pay_now',
  PAY_OVER_TIME: 'pay_over_time',
  ADD_NEW_VISITS: 'add_new_visits',
  ADD_NEW_VISIT: 'add_new_visit',
  PAY_NEW_VISIT: 'pay_new_visit',
  PAY_NEW_VISITS: 'pay_new_visits'
};

export const serverTermsErrorTitles = {
  INVALID_PAYMENT_PLAN_MONTHLY_AMOUNT: 'INVALID_PAYMENT_PLAN_MONTHLY_AMOUNT'
};

export const serverTermsErros = {
  MIN_AMOUNT: 'min_amount_per_installment',
  MAX_AMOUNT: 'max_amount_per_installment'
};
