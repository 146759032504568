import {
  applySnapshot,
  flow,
  getSnapshot,
  onPatch,
  types
} from 'mobx-state-tree';
import { orderBy, sortBy } from 'lodash';
import moment from 'moment/moment';

import ApiModel from './base/ApiModel';
import PaymentPlanModel from './PaymentPlan';
import PayeeModel from './Payee';
import { Bill } from './Bill';
import Limits from './Limits';
import PaymentOptionsCalculator from './PaymentOptionsCalculator';
import SurveyMonkeyModel from './SurveyMonkey';
import FullOffers from './FullOffers';
import { offerTypes } from '../constants/fullOffers';
import {
  GET_FEE_PAYMENT_AMOUNT,
  GET_FULL_OFFERS,
  GET_SINGLE_BILL
} from '../graphql/queries';
import { hasProp } from '../utils/Object';
import { dateInUTC } from '../utils/Date';
import {
  customPlanErrors,
  paymentOptionTypes
} from '../constants/paymentOptions';
import { paymentTypes } from '../constants/mixpanel';
import { careCreditInterestTypes } from '../constants/careCredit';
import { billStatuses, groupingBy } from '../constants/bill';
import PaymentPlanTerms from './PaymentPlanTerms';
import Discount from './Discount';
import SessionParams from '../utils/SessionParams';
import Appointments from './Appointments';
import { buildPaymentOptions } from './paymentOptionsBuilder';

const findInArrayById = (arr, id) => arr.find(item => item.id === id);

const GroupHeaderType = types.model('GroupHeaderType', {
  type: types.string,
  bill: types.string
});

const OneClickPaymentMethod = types.model('OneClickPaymentMethod', {
  amount: types.integer,
  creditCard: types.model('CreditCard', {
    id: types.string,
    last4: types.string
  })
});

const GroupsSorting = types.model('GroupsSorting', {
  groupField: types.string,
  headers: types.array(GroupHeaderType)
});

const DefaultBillsSorting = types.model('DefaultBillsSorting', {
  key: types.string,
  order: types.union(types.literal('asc'), types.literal('desc'))
});
const defaultBillsSortingFallback = { key: 'admissionDate', order: 'desc' };

const AssistOffersByScore = types.model('AssistOffersByScore', {
  patientScore: types.number,
  defaultOption: types.number,
  firstOffer: types.number,
  secondOffer: types.number,
  thirdOffer: types.number,
  otherOptionsPatient: types.string
});

const { FIXED, DEFERRED } = careCreditInterestTypes;

const GuarantorOfAccount = types.model('GuarantorOfAccount', {
  score: types.maybeNull(
    types.frozen({
      capacity: types.maybeNull(types.number),
      segmentCode: types.maybeNull(types.number)
    })
  ),
  externalId: types.maybeNull(types.string)
});

const UserPayment = types.model('UserPayment', {
  id: types.number,
  scheduled_on: types.string
});
const ScheduledPayment = types
  .model('ScheduledPayment', {
    id: types.string,
    amount: types.number,
    userPayment: types.maybeNull(UserPayment)
  })
  .views(self => ({
    get scheduledOn() {
      return self.userPayment.scheduled_on;
    }
  }));

const Payable = types
  .model('PayableModel', {
    name: 'PayableModel',
    accountNumber: types.optional(types.string, ''),
    id: types.optional(types.string, ''),
    accountId: types.optional(types.string, ''),
    standingAmount: types.optional(types.number, 0),
    aNextPayment: types.optional(types.number, 0),
    offer: types.maybeNull(types.number),
    bills: types.optional(types.array(Bill), []),
    paymentPlan: types.maybeNull(PaymentPlanModel),
    payee: types.maybeNull(PayeeModel),
    guarantor: types.maybeNull(GuarantorOfAccount),
    assistMaxPpSetting: types.maybeNull(types.number),
    limits: types.maybeNull(Limits),
    fullOffers: types.maybeNull(FullOffers),
    paymentOptionsCalculator: types.maybeNull(PaymentOptionsCalculator),
    surveyMonkeyModel: types.maybeNull(SurveyMonkeyModel),
    assistOffersByScore: types.maybeNull(types.array(AssistOffersByScore)),
    scheduledPayments: types.optional(types.array(ScheduledPayment), []),
    groupsSorting: types.maybeNull(GroupsSorting),
    defaultBillsSorting: types.optional(
      DefaultBillsSorting,
      defaultBillsSortingFallback
    ),
    showPastBills: types.optional(types.boolean, false),
    selectBills: types.optional(types.boolean, false),
    isPPv4: types.optional(types.boolean, false),
    quickActionPageSetting: types.optional(types.boolean, false),
    paymentPlanSettingEnabled: types.optional(types.boolean, false),
    accountIdentifierToDisplaySetting: types.optional(types.string, ''),
    isLoadingFullOffers: types.optional(types.boolean, false),
    selectableNextPayment: types.optional(types.number, 0),
    selectedBillsIds: types.optional(types.array(types.string), []),
    selectedBillsClaimNumbers: types.optional(types.array(types.string), []),
    isPageletMode: types.optional(types.boolean, false),
    discount: types.maybeNull(Discount),
    isCuraeOn: types.optional(types.boolean, false),
    oneClickPaymentMethod: types.maybeNull(OneClickPaymentMethod)
  })
  .views(self => {
    let _billDetailsViewed = 0;
    let _billsOnPaymentPlan = 0;
    return {
      get groups() {
        /* sort bills array into separate arrays by self.groupsSorting
         * groups: [
         *  {type: 'hospital', bills:[SingleBill]},
         *  {type: 'clinic', bills:[SingleBill]}
         * ]
         */

        const sortBills = billGroups => {
          return billGroups.map(billGroup => {
            const { key, order } =
              self.defaultBillsSorting || defaultBillsSortingFallback;
            const isPastDue = bill => {
              return bill.amountDue <= 0;
            };
            billGroup.bills = self.filterBills(
              orderBy(
                billGroup.bills,
                [isPastDue, key, 'id'],
                ['asc', order, 'desc']
              )
            );
            return billGroup;
          });
        };

        const appointmentsGroup = self.appointmentsGroup || [];
        if (!self.groupsSorting) {
          console.log('-------------no self.groupsSorting found');
          const singleBillGroups = appointmentsGroup.length
            ? [...appointmentsGroup, { bills: self.bills, type: 'Bills' }]
            : [{ bills: self.bills }];
          return sortBills(singleBillGroups);
        }
        const ref = {};
        const { headers, groupField } = self.groupsSorting;

        const getValueByType = groupField => {
          const foundHeader = headers.find(
            header => header.bill === groupField
          );
          return foundHeader ? foundHeader.type : 'Other';
        };

        const billGroupsByPartnerName = () => {
          return Object.values(
            self.bills.reduce((acc, curr) => {
              let groupKey = getValueByType(curr[groupField])
                .replace(/ /g, '_')
                .toLowerCase();

              if (!acc[groupKey]) {
                acc[groupKey] = { type: groupKey, bills: [] };
              }

              acc[groupKey].bills.push(curr);
              return acc;
            }, {})
          );
        };

        const billGroupsByRawVisitType = () => {
          return self.bills.reduce((acc, curr) => {
            if (!hasProp(ref, curr[groupField])) {
              //if (!ref.hasOwnProperty(curr[groupField])) {
              ref[curr[groupField]] = acc.length;
              acc.push({
                type: getValueByType(curr[groupField]),
                bills: [curr]
              });
            } else {
              acc[ref[curr[groupField]]].bills.push(curr);
            }

            return acc;
          }, []);
        };

        const billGroups = (() => {
          switch (groupField) {
            case groupingBy.PARTNER_NAME:
              return billGroupsByPartnerName();
            case groupingBy.RAW_VISIT_TYPE:
              return billGroupsByRawVisitType();
          }
        })();

        return sortBills([...appointmentsGroup, ...billGroups]);
      },
      get totalBills() {
        return self.filterBills(self.bills).length;
      },
      get onlyBillId() {
        const bills = self.filterBills(self.bills);
        if (
          bills.length === 1 &&
          !self.isPayzenPaymentPlan &&
          !self.hasAppointments
        ) {
          return bills[0].id;
        }
        return null;
      },
      get hasBillBeforeCollection() {
        return self.bills.some(bill => bill.isBeforeCollection);
      },
      get hasBillPastDue() {
        return self.bills.some(bill => bill.isBillPastDue);
      },
      get hasPaymentPlan() {
        return !!(self.paymentPlan && self.paymentPlan.id);
      },
      get hasActivePaymentPlan() {
        return self.hasPaymentPlan && self.paymentPlan.isActive;
      },
      get hasOffersPPv4() {
        const recommendedOffer = self.fullOffers?.recommendedOffer;
        return (
          !!recommendedOffer &&
          (recommendedOffer > 1 || self.fullOffers?.longOffer > 1)
        );
      },
      get hasOffer() {
        const { lightOffer, recommendedOffer, longOffer } =
          self.fullOffers || {};
        return !!lightOffer || !!recommendedOffer || !!longOffer;
      },
      get missedScheduledPayments() {
        const now = new Date();
        return self.scheduledPayments.filter(
          payment => new Date(payment.scheduledOn) < now
        );
      },
      get successfulScheduledPayments() {
        const now = new Date();
        return self.scheduledPayments.filter(
          payment => new Date(payment.scheduledOn) > now
        );
      },
      get balanceNotOnPaymentPlan() {
        if (self.hasPaymentPlan) {
          return self.paymentPlan.amountOutOfPlan;
        }
        return 0;
      },
      get billIds() {
        return self.filterBills(self.bills).reduce((acc, curr) => {
          acc.push(curr.id);
          return acc;
        }, []);
      },
      set billsOnPaymentPlan(val) {
        _billsOnPaymentPlan = val;
      },
      get billsOnPaymentPlan() {
        return _billsOnPaymentPlan;
      },
      set billDetailsViewed(val) {
        _billDetailsViewed = val;
      },
      get billDetailsViewed() {
        return _billDetailsViewed;
      },
      get limitedOffer() {
        const amount = self.selectableNextPayment
          ? self.selectableNextPayment
          : self.standingAmount;

        const isLoading = self.isLoadingOffer;

        return !isLoading
          ? self.getLimitedOffer(amount, self.limits, self.isPageletMode)
          : null;
      },
      get limitedOfferPaymentAmount() {
        if (!self.isPPv4) {
          return self.selectBills
            ? Math.floor(self.selectableNextPayment / self.limitedOffer)
            : Math.floor(self.standingAmount / self.limitedOffer);
        } else {
          return self.fullOffers?.recommendedOfferTotalAmountPerInstallment;
        }
      },
      get displayOfferInBillsPage() {
        return !self.isLoadingOffer
          ? !!self.limitedOffer && self.limitedOffer > 1
          : true;
      },
      get payableData() {
        const getNextInstallmentDate = () => {
          return self.paymentPlan.nextInstallment?.installmentDate
            ? moment(
                self.paymentPlan.nextInstallment?.installmentDate,
                'YYYY-MM-DD'
              ).format('MM/DD/YYYY')
            : null;
        };
        return {
          id: self.id,
          openBills: self.totalBills,
          hasBillBeforeCollection: self.hasBillBeforeCollection,
          hasBillPastDue: self.hasBillPastDue,
          remainingBalance: self.standingAmount,
          offer: self.limitedOffer,
          isSelfPay: self.isSelfPay,
          dunningCodes: self.dunningCodes,
          totalReliefDiscount: self.totalReliefDiscount,
          totalBillsDiscount: self.totalBillsDiscount,
          ...(self.guarantor &&
            self.guarantor.score && {
              guarantorSegment: self.guarantor.score.segmentCode,
              guarantorCapacity: self.guarantor.score.capacity
            }),
          ...(self.hasPaymentPlan && {
            paymentPlanType: self.paymentPlan.planType,
            paymentPlanStatus: self.paymentPlan.status,
            balanceOnPaymentPlan: self.paymentPlan.balanceRemaining,
            balanceNotOnPaymentPlan: self.balanceNotOnPaymentPlan,
            billsOnPaymentPlan: self.billsOnPaymentPlan,
            missedPayments: self.paymentPlan.missedInstallmentsCount,
            type: self.paymentPlan.planType,
            status: self.paymentPlan.status,
            nextInstallmentDate: getNextInstallmentDate()
          })
        };
      },
      get billsNotOnPaymentPlanAmount() {
        return self.bills
          .filter(b => self.billIdsNotOnPaymentPlan.includes(b.id))
          .reduce((acc, bill) => acc + bill.amountDue, 0);
      },
      get billIdsNotOnPaymentPlan() {
        return self.hasPaymentPlan
          ? self.bills.filter(b => !b.paymentPlanExists).map(b => b.id)
          : [];
      },
      get allBillsSelected() {
        if (self.selectBills) {
          return ![...self.bills, ...self.appointments].some(
            bill => bill.selectable && !bill.isSelected
          );
        }
        return false;
      },
      get isLoadingOffer() {
        if (self.isPPv4) {
          return self.isLoadingFullOffers;
        }
        if (self.limits) return !self.limits.dataApplied;
        return false;
      },
      get hasBillSelectable() {
        if (self.selectBills && self.totalBills + self.totalAppointments > 1) {
          return [...self.bills, ...self.appointments].some(
            bill => bill.selectable
          );
        }
        return false;
      },
      get totalBillsDiscount() {
        return self.bills.reduce((acc, curr) => {
          return acc + curr.totalDiscounts;
        }, 0);
      },
      get totalPromptDiscountAdjustments() {
        return self.bills.reduce((acc, curr) => {
          return acc + curr.aPromptDiscount;
        }, 0);
      },
      get hasPromptPayAdjustment() {
        return self.totalPromptDiscountAdjustments > 0;
      },
      get eligibleForQuickActionPage() {
        const hasBillsOutOfPaymentPlan =
          !self.hasPaymentPlan || self.billIdsNotOnPaymentPlan.length > 0;
        const isPaymentPlanDelinquent = self.paymentPlan?.isDelinquent;
        const hasAppointments = self.hasAppointments;
        const eligible = !!(
          self.quickActionPageSetting &&
          !self.isDisplaySB490Verbiage &&
          self.isPPv4 &&
          self.standingAmount > 0 &&
          hasBillsOutOfPaymentPlan &&
          !isPaymentPlanDelinquent &&
          !hasAppointments
        );
        return eligible;
      },
      get isSelfPay() {
        return self.bills.every(b => b.selfPay);
      },
      get dunningCodes() {
        return self.bills.map(b => b.dunningCode);
      },
      get totalReliefDiscount() {
        let allReliefDiscounts = self.bills.map(bill => bill.aReliefDiscount);
        return allReliefDiscounts.reduce((prev, curr) => prev + curr, 0);
      },
      get selectedBillsForCareCredit() {
        const isAllBillsSelected =
          self.selectedBillsIds.length === 0 || self.allBillsSelected;
        return isAllBillsSelected
          ? []
          : self.sortedBillsByPaymentApplicationStrategy
              .filter(bill => self.selectedBillsIds.includes(bill.id))
              .map(bill => ({
                billId: bill.id,
                amount: bill.amountDue
              }));
      },
      get offerServiceFee() {
        return (self.isPPv4 && self.fullOffers?.serviceFee) || 0;
      },
      get sortedBillsByPaymentApplicationStrategy() {
        const sortStrategy =
          self.payee?.paymentApplicationStrategy || 'default_fifo';

        switch (sortStrategy) {
          case 'pb_first':
            return sortBy(self.bills, function (bill) {
              return [
                bill.status !== billStatuses.IN_COLLECTION,
                bill.rawVisitType !== 'PB',
                bill.admissionDate
              ];
            });
          case 'hb_first':
            return sortBy(self.bills, function (bill) {
              return [
                bill.status !== billStatuses.IN_COLLECTION,
                bill.rawVisitType !== 'HB',
                bill.admissionDate
              ];
            });
          case 'dunning_code_then_amount':
            //Only Methodist supports prioirity_dunning_code which is saved in yml under consumer so it is ignored
            return orderBy(
              self.bills,
              [item => parseInt(item.amountDue), item => item.admissionDate],
              ['desc', 'asc']
            );
          default:
            return sortBy(self.bills, function (bill) {
              return [
                bill.status !== billStatuses.IN_COLLECTION,
                bill.admissionDate
              ];
            });
        }
      },
      get selectedBillsAmount() {
        return self.bills
          .filter(bill => self.selectedBillsIds.includes(bill.id))
          .reduce((acc, bill) => {
            return acc + bill.amountDue || 0;
          }, 0);
      },
      get accountIdentifierToDisplay() {
        if (
          self.accountIdentifierToDisplaySetting == 'external_id' &&
          self.guarantor.externalId
        )
          return self.guarantor.externalId;
        return SessionParams.accountNumber;
      },
      get hasPromptPayDiscount() {
        return !!(self.discount && self.discount.amount > 0);
      },
      get hasFinancedLightOffer() {
        const { shortOfferType, recommendedOfferType, longOfferType } =
          self.fullOffers || {};
        return [shortOfferType, recommendedOfferType, longOfferType].includes(
          offerTypes.LIGHT_OFFER
        );
      },
      get hasFinancedFinalOffer() {
        const { shortOfferType, recommendedOfferType, longOfferType } =
          self.fullOffers || {};
        return [shortOfferType, recommendedOfferType, longOfferType].includes(
          offerTypes.FINAL_OFFER
        );
      },
      get isFinancedOffer() {
        return self.hasFinancedLightOffer || self.hasFinancedFinalOffer;
      },
      get isProviderTypeOffer() {
        return (
          self.fullOffers?.recommendedOfferType === offerTypes.PROVIDER_OFFER
        );
      },
      get isPayzenPaymentPlan() {
        return self.paymentPlan?.fundedBy === 'payzen';
      },
      get estimatedAmountDue() {
        return self.aNextPayment + self.appointmentsAmountOutOfPlan;
      },
      get accountBalance() {
        return self.standingAmount + self.appointmentsTotalBalance;
      },
      get selectedBillIdsInsideThePlan() {
        return self.selectedBillsIds.filter(billId =>
          self.paymentPlan?.billIds?.includes(billId)
        );
      },
      get selectedBillIdsOutsideThePlan() {
        return self.selectedBillsIds.filter(
          billId => !self.paymentPlan?.billIds?.includes(billId)
        );
      },
      get selectedBillAmountOutsideThePlan() {
        return self.bills
          .filter(({ id }) => self.selectedBillIdsOutsideThePlan.includes(id))
          .reduce((acc, bill) => {
            return acc + bill.amountDue || 0;
          }, 0);
      },
      get isOnlyAppointmentsSelected() {
        return (
          !!self.selectedAppointments.length && !self.selectedBillsIds.length
        );
      }
    };
  })
  .actions(self => {
    let timeoutRef;
    let _isSelectingAllBills = false;
    const {
      PAYMENT,
      ONE_CLICK_PAYMENT,
      ASSIST_PP,
      PAY_FULL_PLAN,
      PP_CONVERT,
      PP_UPDATE_PAYMENT_METHOD,
      NOTIFICATION_BANNER,
      PP_BRING_CURRENT,
      CATCH_UP_PROMPT,
      NOTIFICATION_BANNER_CATCH_UP,
      ADD_OUTSIDE_BALANCE,
      FINANCING_PP,
      INSIDE_PP,
      OUTSIDE_PP,
      INSIDE_FINANCING_PP,
      OUTSIDE_FINANCING_PP
    } = paymentTypes;

    const { FULL_PAYMENT, QUICK_ACTION, CURAE } = paymentOptionTypes;

    return {
      initWithSettings: ({
        billsGroups,
        defaultSorting,
        showPastBills,
        select_a_bill,
        payment_plan,
        assist_feature,
        payment_method_permission,
        care_credit_digital_buy,
        payment_plan_v4,
        quick_action_page_experience,
        account_identifier_to_display,
        curae,
        limit_pp_offers,
        payzen,
        pre_service,
        display_SB490_verbiage_in_PE
      }) => {
        self.setAppointments(select_a_bill, pre_service);
        self.setBillsGroups(billsGroups);
        self.setDefaultBillsSorting(defaultSorting);
        self.showPastBills = showPastBills;
        self.selectBills = select_a_bill;
        self.paymentPlanSettingEnabled = !!payment_plan;
        self.assistFeature = assist_feature;
        self.paymentMethodPermission = payment_method_permission;
        self.careCreditSetting = care_credit_digital_buy;
        self.isPPv4 = payment_plan_v4;
        self.quickActionPageSetting = quick_action_page_experience;
        self.accountIdentifierToDisplaySetting =
          account_identifier_to_display?.identifier_to_display;
        self.isCuraeOn = curae;
        self.limitPPOffers = limit_pp_offers;
        self.isPayzenOn = payzen;
        self.isDisplaySB490Verbiage = display_SB490_verbiage_in_PE;
        self.setPaymentOptionsCalculator();
        self.setSurveyMonkeyModel();
        self.setPaymentPlanTerms();
        self.setData();
      },
      fetchFeePayment: flow(function* (amount) {
        try {
          const { feePaymentAmount } = yield self.query(
            GET_FEE_PAYMENT_AMOUNT,
            {
              accountNumber: self.accountNumber,
              claimNumbers: self.selectedBillsClaimNumbers.toJSON(),
              amount
            }
          );

          return feePaymentAmount;
        } catch {
          return 0;
        }
      }),
      setData: () => {
        self.bills = self.mapBillProps();
        self.addAmountRankAndDateToBills();
        if (self.discount) {
          // no bill selected equall to all bills selected
          self.discount.setDiscountFromSelectedBills([], self.bills);
        }
        if (self.selectBills) {
          self.listenToBillSelection();
          self.setSelectedAmountAndIds();
        } else {
          self.selectedBillsClaimNumbers = self.bills.map(bill => bill.claimNo);
        }
      },
      setReportError: reportErrorCb => {
        self.reportError = reportErrorCb;
      },
      setPageletMode: isPageletMode => {
        self.isPageletMode = isPageletMode;
      },
      setBillsGroups: groupObj => {
        groupObj && (self.groupsSorting = groupObj);
      },
      setDefaultBillsSorting: sortingObj => {
        //sortingObj = {key:string, order:'asc'/'desc'}
        self.defaultBillsSorting = DefaultBillsSorting.create(
          sortingObj || defaultBillsSortingFallback
        );
      },
      setAppointments: (isSelectable, appointmentSetting) => {
        if (!appointmentSetting) return;
        self.initAppointments(isSelectable);
      },
      setPaymentOptionsCalculator: () => {
        const shouldUsePPv4 = !!(self.isPPv4 && self.fullOffers);
        if (self.isProviderTypeOffer) {
          self.fullOffers.removeLightOffers();
        }
        self.paymentOptionsCalculator = PaymentOptionsCalculator.create({
          useFullOffers: shouldUsePPv4,
          fullOffers: shouldUsePPv4 ? getSnapshot(self.fullOffers) : null,
          maxPaymentsNumber: Math.min(
            self.limits?.maxPaymentsNumber || self.assistMaxPpSetting,
            self.assistMaxPpSetting
          ),
          isEligibleForPaymentPlan:
            self.standingAmount >= self.limits?.minStandingAmount ||
            self.fullOffers?.validForPlan,
          hasPaymentPlan: !!self.paymentPlan,
          assistFeature: !!self.assistFeature,
          paymentPlanSetting: self.paymentPlanSettingEnabled,
          isPPv4: self.isPPv4,
          partialPaymentPermission:
            self.paymentMethodPermission?.payment_methods?.includes(
              'partial_payment'
            ) || false,
          careCreditOptionPermission: !!self.careCreditSetting,
          isCuraeOn: !!self.isCuraeOn,
          limitPPOffers: self.limitPPOffers
        });
      },
      setSurveyMonkeyModel: () => {
        self.surveyMonkeyModel = SurveyMonkeyModel.create({
          payableId: self.id,
          providerName: self.payee.provider.internalName,
          companyName: self.payee.companyName,
          standingAmount: self.standingAmount
        });
      },
      setPaymentPlanTerms: () => {
        self.paymentPlanTerms = PaymentPlanTerms.create({
          serviceFee: self.fullOffers?.serviceFee
        });
        onPatch(self.paymentPlanTerms, patch => {
          if (patch.path.includes('unsupportedError')) {
            const { unsupportedError } = self.paymentPlanTerms;
            unsupportedError &&
              self.reportError({
                errorMessage: unsupportedError,
                query: 'termsResult'
              });
          }
        });
      },
      findById: id => findInArrayById(self.bills, id),
      mapBillProps: () => {
        //get props from main Payable data and populate each Bill in data.bills list with relevant data
        //Payable.paymentPlan.bills ==> bill.paymentPlanExists
        //Payable.selectBills ==> bill.selectable
        const billIds =
          self.paymentPlan && self.paymentPlan.billIds
            ? self.paymentPlan.billIds
            : [];

        let billsOnPaymentPlan = 0;

        self.bills.forEach(bill => {
          if (billIds.length) {
            bill.paymentPlanExists && billsOnPaymentPlan++;
          }
          //select a bill logic: bill is not selectable if is on payment plan
          bill.selectable = self.selectBills && !bill.paymentPlanExists;
          bill.selectable && (bill.isSelected = true);
        });

        self.billsOnPaymentPlan = billsOnPaymentPlan;
        return self.bills;
      },
      addAmountRankAndDateToBills: () => {
        const amountSorting = orderBy(self.bills, ['amountDue'], ['desc']);
        const dateSorting = orderBy(
          self.bills,
          [bill => Date.parse(bill.admissionDate)],
          ['desc']
        );
        amountSorting.forEach((bill, index) => (bill.amountRank = index + 1));
        dateSorting.forEach((bill, index) => (bill.dateRank = index + 1));
      },
      getSingleBillDetails: flow(function* (billId) {
        //Check if best practice is to save entire data, or rely on GraphQL caching instead
        const singleBill = findInArrayById(self.bills, billId);
        if (!singleBill) {
          return Promise.reject('bill not found');
        }
        if (!singleBill.isDataComplete) {
          try {
            const res = yield self.query(GET_SINGLE_BILL, { id: billId });
            applySnapshot(singleBill, {
              ...getSnapshot(singleBill),
              ...res.bill,
              ...{ isDataComplete: true },
              ...{ accountNumber: self.accountIdentifierToDisplay }
            });

            self.billDetailsViewed++;
            return Promise.resolve(singleBill);
          } catch (e) {
            console.error('getSingleBillDetails error:', e);
          }
        } else {
          return Promise.resolve(singleBill);
        }
      }),
      filterBills: bills => {
        //redundant once server-side filtering is verified
        return bills.filter(bill => {
          const { amountDue, paymentPlanExists } = bill;
          const isFinancedBill = paymentPlanExists && self.isPayzenPaymentPlan;
          return (
            amountDue > 0 ||
            (amountDue <= 0 && (self.showPastBills || isFinancedBill))
          );
        });
      },
      getPlanSelectedData: (installments, isCustomPlan) => {
        if (self.isPPv4) {
          if (!isCustomPlan) {
            return self.fullOffers.getFullOffer(installments);
          } else {
            return self.paymentPlanTerms.getCurrentTermsData();
          }
        }
        return null;
      },
      updateFullOffers: flow(function* () {
        const applyFullOffers = fullOffersResult => {
          applySnapshot(self.fullOffers, fullOffersResult?.fullOffers || null);
          applySnapshot(self.paymentOptionsCalculator, {
            ...getSnapshot(self.paymentOptionsCalculator),
            ...{
              fullOffers: fullOffersResult?.fullOffers || null,
              isEligibleForPaymentPlan:
                fullOffersResult?.fullOffers?.validForPlan
            }
          });
        };

        const billsAmount =
          (self.hasPaymentPlan &&
            self.paymentPlan?.amountOutOfPlan +
              self.paymentPlan?.balanceRemaining) ||
          self.selectedBillsAmount;
        const totalBalance =
          billsAmount + self.selectedAppointmentsRemainingBalance;
        if (self.fullOffers?.totalBalance === totalBalance) return;
        if (totalBalance === 0) {
          return applyFullOffers({
            fullOffers: {
              recommendedOffer: null,
              shortOffer: null,
              longOffer: null
            }
          });
        }
        self.isLoadingFullOffers = true;
        try {
          if (self.isPayzenOn) {
            yield self.fullOffers.getPayzenOffers(
              self.id,
              self.selectedBillsIds.toJSON()
            );
          } else {
            const { fullOffersResult } = yield self.query(GET_FULL_OFFERS, {
              totalBalance,
              payableAccountNumber: self.accountNumber
            });
            if (fullOffersResult.errors) {
              //TODO: handle errors here
            }
            if (fullOffersResult?.fullOffers.totalBalance === totalBalance) {
              applyFullOffers(fullOffersResult);
            }
          }
          self.isLoadingFullOffers = false;
        } catch (e) {
          //
          self.isLoadingFullOffers = false;
        }
      }),
      updateLimits: flow(function* () {
        if (self.limits) {
          const { limits, standingAmount } = yield self.limits.getLimits(
            self.selectedBillsAmount,
            self.offer
          );
          if (standingAmount === self.selectedBillsAmount) {
            self.limits.applyLimits(standingAmount);
            applySnapshot(self.paymentOptionsCalculator, {
              ...getSnapshot(self.paymentOptionsCalculator),
              ...{
                maxPaymentsNumber: Math.min(
                  limits?.maxPaymentsNumber || self.assistMaxPpSetting,
                  self.assistMaxPpSetting
                ),
                isEligibleForPaymentPlan: !!(
                  limits &&
                  self.selectedBillsAmount >= limits?.minStandingAmount
                )
              }
            });
          }
        }
      }),
      listenToBillSelection: () => {
        const onBillSelection = patch => {
          if (patch.path.includes('isSelected') && !_isSelectingAllBills) {
            clearTimeout(timeoutRef);
            timeoutRef = setTimeout(() => {
              // function is triggered if a bill wasn't selected during the last few seconds
              self.setSelectedAmountAndUpdateLimits();
            }, 1500);
          }
        };
        onPatch(self.appointments, onBillSelection);
        onPatch(self.bills, onBillSelection);
      },
      setSelectedAmountAndUpdateLimits: flow(function* () {
        self.setSelectedAmountAndIds();
        //if PP exists - No UI option to recreate/update PP with partial amount, do not update fullOffers/limits
        //payment option shown is always paymentPlan.amountOutOfPlan
        if (self.hasPaymentPlan && !self.hasAppointments) return;
        if (self.isPPv4) {
          yield self.updateFullOffers();
          self.getLimitedOffer(self.selectableNextPayment);
        } else {
          yield self.updateLimits();
          if (self.limitedOffer) {
            self.getLimitedOffer(self.selectableNextPayment, self.limits);
          }
        }
      }),
      setSelectedAmountAndIds: () => {
        let totalSelectedAmount = 0;
        const selectedBillsIds = [];
        const selectedBillsClaimNumbers = [];
        const addBillToLists = bill => {
          selectedBillsIds.push(bill.id);
          selectedBillsClaimNumbers.push(bill.claimNo);
        };
        self.bills.forEach(bill => {
          if (bill.selectable && bill.isSelected) {
            totalSelectedAmount += bill.amountDue;
            addBillToLists(bill);
          } else if (bill.paymentPlanExists) {
            //bills on payment plan are automatically selected
            addBillToLists(bill);
          }
        });
        if (self.hasPaymentPlan) {
          //bills on payment plan are automatically selected
          totalSelectedAmount += self.paymentPlan.nextStandingAmount;
        }
        if (self.hasAppointments) {
          totalSelectedAmount += self.selectedAppointmentsRemainingBalance;
        }
        // This is here for the option that the patient selects none of the bills we force the selection of all
        if (totalSelectedAmount === 0 && selectedBillsIds.length === 0) {
          totalSelectedAmount =
            self.standingAmount + self.appointmentsTotalBalance;
        }
        self.selectableNextPayment = totalSelectedAmount;
        self.selectedBillsIds = selectedBillsIds;
        self.selectedBillsClaimNumbers = selectedBillsClaimNumbers;
        if (self.discount) {
          self.discount.setDiscountFromSelectedBills(
            selectedBillsIds,
            self.bills
          );
        }
      },
      setSelectAllBills: (select, selectAppointments = true) => {
        _isSelectingAllBills = true;
        self.bills.forEach(bill => {
          if (bill.selectable) {
            bill.isSelected = select;
          }
        });
        if (selectAppointments) {
          self.selectAllAppointments(select);
        }
        _isSelectingAllBills = false;
        self.setSelectedAmountAndUpdateLimits();
      },
      getLimitedOffer: (standingAmount, limits, ignoreCurrentPp) => {
        const eligibleForPp = () => {
          return (
            standingAmount &&
            ((self.paymentPlanSettingEnabled && self.assistFeature) ||
              (self.isPPv4 && self.hasOffersPPv4) ||
              self.isPayzenOn) &&
            (!self.hasPaymentPlan || ignoreCurrentPp)
          );
        };
        if (self.isPPv4) {
          if (!eligibleForPp()) {
            return null;
          }
          return self.fullOffers?.recommendedOffer;
        }
        if (self.offer === 0) return 0;
        if (
          !limits ||
          standingAmount < limits.minStandingAmount ||
          !self.assistOffersByScore ||
          !eligibleForPp()
        ) {
          return null;
        }
        const maxPayments = limits ? limits.maxPaymentsNumber : 100;
        const maxPaymentAssist = self.assistMaxPpSetting || 100;
        const offerToUse =
          self.assistOffersByScore.find(
            assistOffer => assistOffer.patientScore === self.offer
          )?.defaultOption || null;
        if (offerToUse > maxPayments || offerToUse > maxPaymentAssist) {
          //offer above limits, find highest available offer
          const offerByScore =
            self.assistOffersByScore?.reduce((acc, curr) => {
              const { defaultOption: score } = curr;
              if (
                score <= maxPayments &&
                score <= maxPaymentAssist &&
                score > acc
              ) {
                return score;
              }
              return acc;
            }, 0) || 0;
          const possibleOffer = Math.min(maxPayments, maxPaymentAssist);
          return offerByScore < 6 ? possibleOffer : offerByScore;
        }
        return offerToUse;
      },
      getPaymentOptions: (paymentType, amount) => {
        if (
          self.isPayzenPaymentPlan ||
          self.isFinancedOffer ||
          [INSIDE_PP, OUTSIDE_PP].includes(paymentType)
        ) {
          return buildPaymentOptions({
            paymentType,
            amount,
            hasPaymentPlan: self.hasPaymentPlan,
            nextStandingAmount: self.paymentPlan?.nextStandingAmount,
            planRemainingAmount: self.paymentPlan?.balanceRemaining,
            outOfPlanAmount:
              self.selectedBillAmountOutsideThePlan ||
              self.billsNotOnPaymentPlanAmount,
            fullOffers: getSnapshot(self.fullOffers)
          });
        }
        const specialAmountParam =
          paymentType === QUICK_ACTION && self.hasPaymentPlan
            ? self.billsNotOnPaymentPlanAmount
            : null;
        let limitedOffer;
        if (self.isPPv4 || self.isPayzenPaymentPlan) {
          if (
            paymentType === ADD_OUTSIDE_BALANCE ||
            paymentType === QUICK_ACTION
          ) {
            //re-setup payment plan, get limitedOffer ignoring self.hasPaymentPlan
            limitedOffer = self.getLimitedOffer(
              self.standingAmount,
              null,
              true
            );
          } else {
            limitedOffer = self.limitedOffer;
          }
          return self.paymentOptionsCalculator.getPaymentOptionsPPv4(
            paymentType,
            amount,
            limitedOffer,
            !!self.paymentPlan,
            self.getCareCreditMaxOption(amount),
            specialAmountParam
          );
        }
        limitedOffer = self.selectBills
          ? self.getLimitedOffer(self.selectableNextPayment, self.limits)
          : self.limitedOffer;

        if (paymentType === ADD_OUTSIDE_BALANCE) {
          //re-setup payment plan, get limitedOffer ignoring self.hasPaymentPlan
          limitedOffer = self.getLimitedOffer(
            self.standingAmount,
            self.limits,
            true
          );
        }
        const ppOffer =
          self.assistOffersByScore?.find(
            offer => offer.patientScore === limitedOffer
          ) || null;

        return self.paymentOptionsCalculator.getPaymentOptions(
          paymentType,
          amount,
          limitedOffer,
          ppOffer,
          !!self.paymentPlan,
          self.getCareCreditMaxOption(amount),
          specialAmountParam
        );
      },
      getSurveyMonkeyParams: userPaymentInformation => {
        return self.surveyMonkeyModel.getUserPaymentUrlParams(
          userPaymentInformation
        );
      },
      /**
       *
       * @param {Number} amountPerInstallment - amount in *cents* per installment
       * @param {Number} totalAmount - userState['paymentAmount'] - total amount selected in cents
       * @returns {{payments: number, lastPayment: *, error: {args: *, type: number}}}
       */
      getPaymentsForCustomPlan: (amountPerInstallment, totalAmount) => {
        const amountToUse = totalAmount || self.standingAmount;
        const {
          minPaymentAmount: minAmountPerPayment,
          lastPaymentMinAmount: minAmountLastPayment
        } = self.limits;
        const { OVER_MAX_AMOUNT, BELOW_MIN_AMOUNT } = customPlanErrors;
        let payments, lastPayment, error;
        const getLastPayment = totalPayments => {
          return amountToUse - amountPerInstallment * totalPayments;
        };

        if (amountPerInstallment < minAmountPerPayment) {
          error = {
            type: BELOW_MIN_AMOUNT,
            args: minAmountPerPayment
          };
        } else if (amountPerInstallment >= amountToUse - minAmountLastPayment) {
          error = {
            type: OVER_MAX_AMOUNT,
            args: amountToUse
          };
        } else {
          payments = Math.ceil(amountToUse / amountPerInstallment);
          if (payments * amountPerInstallment !== amountToUse) {
            lastPayment = getLastPayment(payments - 1);
            while (lastPayment < minAmountLastPayment) {
              payments--;
              lastPayment = getLastPayment(payments - 1);
            }
          }
        }

        return {
          payments,
          lastPayment,
          error
        };
      },
      getTermsForCustomPlanFromServer: flow(function* (
        amountPerInstallment,
        fullAmount
      ) {
        return self.paymentPlanTerms.getTermsForCustomPlanFromServer(
          amountPerInstallment,
          fullAmount
        );
      }),
      getPaymentPlanStartDate: installmentDate => {
        const dateFormat = 'MM/DD/YYYY';
        if (
          typeof installmentDate === 'string' &&
          installmentDate.includes('/')
        ) {
          //formatted date string
          return installmentDate;
        } else {
          //day of month, e.g 10;
          const today = moment().format('D');
          const delta = parseInt(installmentDate) - parseInt(today);
          if (delta >= 0) {
            return moment().add({ days: delta }).format(dateFormat);
          } else {
            return moment()
              .subtract({ days: Math.abs(delta) })
              .add({ months: 1 })
              .format(dateFormat);
          }
        }
      },
      getTotalAmountByPaymentType: userPaymentType => {
        const isAllBillsSelected =
          self.selectedBillsIds.length === 0 ||
          (self.selectBills && self.allBillsSelected);
        const amountToPay = self.selectBills
          ? self.selectableNextPayment
          : self.aNextPayment;
        const isPPv4lastInstallment =
          self.isPPv4 &&
          self.hasPaymentPlan &&
          self.paymentPlan.leftInstallmentsCount === 1;
        switch (userPaymentType) {
          case PAYMENT:
          case ONE_CLICK_PAYMENT:
            return amountToPay;
          case PP_CONVERT:
          case PP_UPDATE_PAYMENT_METHOD:
          case NOTIFICATION_BANNER:
          case ADD_OUTSIDE_BALANCE:
            return (
              self.standingAmount +
              self.selectedAppointmentsRemainingBalance +
              self.appointmentsPlanAmount
            );
          case PAY_FULL_PLAN:
            let amountToDisplay;
            if (isAllBillsSelected) {
              if (isPPv4lastInstallment) {
                const fee = self.paymentPlan?.nextInstallment?.serviceFee || 0;
                amountToDisplay = self.standingAmount + fee;
              } else {
                amountToDisplay = self.standingAmount;
              }
            } else {
              amountToDisplay = self.selectedBillsAmount;
            }
            return amountToDisplay + self.appointmentsTotalBalance;
          case ASSIST_PP:
          case FINANCING_PP:
            const selectedAppointmentsAmount =
              self.selectedAppointmentsRemainingBalance;
            return isAllBillsSelected
              ? self.standingAmount + selectedAppointmentsAmount
              : self.selectedBillsAmount + selectedAppointmentsAmount;
          case PP_BRING_CURRENT:
          case CATCH_UP_PROMPT:
          case NOTIFICATION_BANNER_CATCH_UP:
            return self.paymentPlan.standingAmount;
          case INSIDE_FINANCING_PP:
          case INSIDE_PP:
            return self.paymentPlan.balanceRemaining;
          case OUTSIDE_PP:
          case OUTSIDE_FINANCING_PP:
            return self.selectBills
              ? self.selectedBillAmountOutsideThePlan ||
                  self.paymentPlan.amountOutOfPlan
              : self.paymentPlan.amountOutOfPlan;
        }
      },
      getAutoPaymentOption: userPaymentType => {
        let paymentDate = dateInUTC();
        let amount = self.getTotalAmountByPaymentType(userPaymentType);
        let paymentOptionType = FULL_PAYMENT;

        if (
          [PP_CONVERT, PP_UPDATE_PAYMENT_METHOD, NOTIFICATION_BANNER].includes(
            userPaymentType
          )
        ) {
          return self.paymentPlan.getConvertPpPaymentOption();
        } else if (userPaymentType === CURAE) {
          paymentOptionType = CURAE;
          userPaymentType = self.hasPaymentPlan ? PAY_FULL_PLAN : PAYMENT;
          amount = self.getTotalAmountByPaymentType(userPaymentType);
        }
        return {
          amount: amount,
          paymentOptionType: paymentOptionType,
          paymentDate: paymentDate
        };
      },
      getCareCreditFilteredCCConfigs: amount => {
        const options = [];
        if (self.careCreditSetting) {
          const filterCareCreditConfig = (type, entityType) => {
            self.careCreditSetting[entityType].forEach(config => {
              let option = {
                type: type,
                months:
                  type === DEFERRED
                    ? config['deferred']
                    : config['fixed_interest_epp'],
                promo: config['promo_code'],
                interestRate: config['interest_rate'],
                monthlyPaymentPercent: config['monthly_payment_percent']
              };
              if (config['enabled'] && config['min_balance'] <= amount) {
                options.push(option);
              }
            });
          };
          filterCareCreditConfig(DEFERRED, 'care_credit_deferred_plan_options');
          filterCareCreditConfig(
            FIXED,
            'care_credit_fixed_interest_plan_options'
          );
        }
        return options;
      },
      getCareCreditMaxOption: amount => {
        const options = self.getCareCreditFilteredCCConfigs(amount);
        return (
          options.length &&
          options.reduce((prev, current) => {
            return prev.months > current.months ? prev : current;
          })
        );
      },
      applyFullOffers: fullOffersResult => {
        applySnapshot(self.fullOffers, fullOffersResult || null);
        applySnapshot(self.paymentOptionsCalculator, {
          ...getSnapshot(self.paymentOptionsCalculator),
          ...{
            fullOffers: fullOffersResult || null,
            isEligibleForPaymentPlan: fullOffersResult.validForPlan
          }
        });
      }
    };
  });

export default types.compose(ApiModel, Appointments, Payable);
