import { flow, types } from 'mobx-state-tree';
import ApiModel from './base/ApiModel';
import { GET_PAYZEN_OFFERS } from '../graphql/queries';

const OFFER_KINDS = ['short', 'long', 'recommended'];
const OFFER_KINDS_DICTIONARY = {
  short: 'shortOffer',
  long: 'longOffer',
  recommended: 'recommendedOffer'
};

const defaultMemberInfo = {
  firstName: null,
  lastName: null,
  addressLine: null,
  city: null,
  state: null,
  zipCode: null,
  mobileNumber: null,
  emailAddress: null,
  dateOfBirth: null
};

const MemberInfo = types.model('MemberInfo', {
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  addressLine: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  zipCode: types.maybeNull(types.string),
  mobileNumber: types.maybeNull(types.string),
  emailAddress: types.maybeNull(types.string),
  dateOfBirth: types.maybeNull(types.string)
});

const FullOffers = types
  .model('FullOffers', {
    recommendedOffer: types.maybeNull(types.number),
    recommendedOfferTotalFees: types.maybeNull(types.number),
    recommendedOfferAmountPerInstallment: types.maybeNull(types.number),
    recommendedOfferLastInstallmentAmount: types.maybeNull(types.number),
    recommendedOfferTotalAmountPerInstallment: types.maybeNull(types.number),
    recommendedOfferTotalBalanceIncludingFees: types.maybeNull(types.number),
    recommendedOfferLastInstallmentTotalAmount: types.maybeNull(types.number),
    recommendedOfferType: types.maybeNull(types.string),
    recommendedOfferPzId: types.maybeNull(types.string),

    shortOffer: types.maybeNull(types.number),
    shortOfferTotalFees: types.maybeNull(types.number),
    shortOfferAmountPerInstallment: types.maybeNull(types.number),
    shortOfferLastInstallmentAmount: types.maybeNull(types.number),
    shortOfferTotalAmountPerInstallment: types.maybeNull(types.number),
    shortOfferTotalBalanceIncludingFees: types.maybeNull(types.number),
    shortOfferLastInstallmentTotalAmount: types.maybeNull(types.number),
    shortOfferType: types.maybeNull(types.string),
    shortOfferPzId: types.maybeNull(types.string),

    longOffer: types.maybeNull(types.number),
    longOfferTotalFees: types.maybeNull(types.number),
    longOfferAmountPerInstallment: types.maybeNull(types.number),
    longOfferLastInstallmentAmount: types.maybeNull(types.number),
    longOfferTotalAmountPerInstallment: types.maybeNull(types.number),
    longOfferTotalBalanceIncludingFees: types.maybeNull(types.number),
    longOfferLastInstallmentTotalAmount: types.maybeNull(types.number),
    longOfferType: types.maybeNull(types.string),
    longOfferPzId: types.maybeNull(types.string),

    serviceFee: types.maybeNull(types.number),
    totalBalance: types.maybeNull(types.number),
    otherOptions: types.maybeNull(types.string),
    validForPlan: types.maybeNull(types.boolean),
    payzenOffers: types.maybeNull(types.frozen()),
    memberInfo: types.optional(MemberInfo, defaultMemberInfo),
    errors: types.maybeNull(types.array(types.string))
  })
  .actions(self => ({
    getPayzenOffers: flow(function* (
      payableId,
      billIds,
      memberDetails = {},
      isFinalOffer = false,
      overridePayzenOffers = false
    ) {
      console.log('get payzen offers...');
      try {
        const { financedOffers } = yield self.query(
          GET_PAYZEN_OFFERS,
          {
            payableId: payableId,
            billIds: billIds,
            isFinalOffer: isFinalOffer,
            overridePayzenOffers: overridePayzenOffers,
            ...(isFinalOffer ? memberDetails : self.memberInfo)
          },
          { isCritical: false }
        );
        self.errors = financedOffers.errors;
        if (self.errors?.length) return;
        self.payzenOffers = financedOffers.fullOffers;
        self.memberInfo = financedOffers.member;

        return Promise.resolve();
      } catch (e) {
        console.error('FullOffers Model error', e);
        return Promise.reject(e);
      }
    }),
    getOfferType: offer => {
      const offersTypes = {
        [self.recommendedOffer]: OFFER_KINDS_DICTIONARY.recommended,
        [self.shortOffer]: OFFER_KINDS_DICTIONARY.short,
        [self.longOffer]: OFFER_KINDS_DICTIONARY.long
      };
      return offersTypes[offer];
    },
    getFullOffer: offer => {
      const offerType = self.getOfferType(offer);
      if (!offerType) {
        console.error(`offer not found!!' offer: ${offer}`);
        return {};
      }
      return {
        offer: self[`${offerType}`],
        totalFees: self[`${offerType}TotalFees`],
        amountPerInstallment: self[`${offerType}AmountPerInstallment`],
        lastInstallmentAmount: self[`${offerType}LastInstallmentAmount`],
        totalAmountPerInstallment:
          self[`${offerType}TotalAmountPerInstallment`],
        totalBalanceIncludingFees:
          self[`${offerType}TotalBalanceIncludingFees`],
        lastInstallmentTotalAmount:
          self[`${offerType}LastInstallmentTotalAmount`],
        serviceFee: self.serviceFee,
        totalBalance: self.totalBalance,
        payzenOfferId: self[`${offerType}PzId`],
        type: self[`${offerType}Type`]
      };
    },
    removeLightOffers: () => {
      OFFER_KINDS.filter(kind => kind !== 'recommended').forEach(kind => {
        if (self[`${kind}OfferType`] === 'light') {
          for (const key in self) {
            if (key.startsWith(kind)) self[key] = null;
          }
        }
      });
    }
  }));

export default types.compose(ApiModel, FullOffers);
