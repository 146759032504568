import moment from 'moment/moment';

const languageCodesMap = {
  en: 'en-ca',
  es: 'es',
  pl: 'pl',
  pt: 'pt',
  ru: 'ru',
  vi: 'vi'
};

/**
 * Helper function that loads required locales on demand
 * and maps language code (e.g. 'en' to 'en-ca') required for loading locale module
 * @returns {moment}
 * @constructor
 */
const MomentHelper = () => {
  return moment;
};

MomentHelper.locale = code => {
  const languageCode = languageCodesMap[code] || languageCodesMap['en'];
  try {
    //TODO: this will cause the entire languages package (~300KB) to be included in build
    //need to make sure it's only loaded on-demand
    require(`moment/locale/${languageCode}`);
  } catch (e) {
    console.error(e);
  }

  moment.locale(code);
};

export default MomentHelper;
