import { flow, types } from 'mobx-state-tree';
import { detect } from 'detect-browser';

import { SEND_JS_ERROR_LOG } from '../graphql/mutations';
import ApiModel from './base/ApiModel';

const getBrowserName = () => {
  const browser = detect();
  return browser && browser.name
    ? `${browser.name} ${browser.version} / ${browser.os}`
    : 'Not found';
};

const clearMessage = message => {
  return message.replace('Uncaught TypeError: ', '');
};

const shouldSend = (message, stack) =>
  !sentErrors[message] || (stack && sentErrors[message] === 'noStack');

const sentErrors = {};

const JsErrorReporter = types.model('JsErrorReporter', {}).actions(self => ({
  sendReport: flow(function* ({ message, stack, clientActions }) {
    const clearedMessage = clearMessage(message);
    if (shouldSend(clearedMessage, stack)) {
      sentErrors[clearedMessage] = stack || 'noStack';

      try {
        yield self.mutate(
          SEND_JS_ERROR_LOG,
          {
            errorMessage: message,
            errorStack: stack,
            browser: getBrowserName(),
            clientUrl: window.location.href,
            clientActions
          },
          { isCritical: false }
        );
        return Promise.resolve();
      } catch (e) {
        console.error('JsErrorReporter Model error', e);
      }
    } else {
      console.log(
        'JsErrorReporter: error report message has already been sent'
      );
    }
  })
}));

export default types.compose(ApiModel, JsErrorReporter);
