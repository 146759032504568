import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const GET_FEE_PAYMENT_AMOUNT = gql`
  query feePaymentAmount(
    $accountNumber: ID!
    $claimNumbers: [ID!]!
    $amount: Int!
  ) {
    feePaymentAmount(
      feeAmountInput: {
        accountNumber: $accountNumber
        claimNumbers: $claimNumbers
        amount: $amount
      }
    ) ${CLIENT}
  }
`;

export const GET_LIMITS = gql`
  query getLimits($standingAmount: Int!, $offer: Int) {
    limits(standingAmount: $standingAmount, offer: $offer) ${CLIENT} {
      maxPaymentsNumber
      minStandingAmount
      lastPaymentMinAmount
      minPaymentAmount
    }
  }
`;

export const GET_PREVIOUS_PAYMENTS = gql`
  query previousPayments($payableIds: [ID!]!) {
    previousPayments(payableIds: $payableIds) ${CLIENT} {
      amount
      date
      paymentMethod {
        last4
        paymentSubType
        paymentType
      }
      status
    }
  }
`;

export const GET_SAVED_CREDIT_CARDS = gql`
  query creditCards($memberId: ID!, $isTowardsFinancedPlan: Boolean) {
    creditCards(memberId: $memberId, isTowardsFinancedPlan: $isTowardsFinancedPlan) ${CLIENT} {
      id
      expiredAt
      last4
      cardType
      financingCustomerToken
      financingProcessorToken
    }
  }
`;

export const GET_SAVED_ECHECKS = gql`
  query echecks($memberId: ID!) {
    echecks(memberId: $memberId) ${CLIENT} {
      accountNumber
      accountType
      billingName
      id
      routingNumber
      financingCustomerToken
      financingProcessorToken
    }
  }
`;

export const VALIDATE_CREDIT_CARD = gql`
  query validateCreditCard($cardId: Int, $creditCard: CreditCardInput) {
    validateCreditCard(cardId: $cardId, creditCard: $creditCard) ${CLIENT} {
      errors
      valid
    }
  }
`;

export const VALIDATE_ECHECK = gql`
  query validateEcheck($echeck: EcheckInput!) {
    validateEcheck(echeck: $echeck) ${CLIENT} {
      errors
      valid
    }
  }
`;
