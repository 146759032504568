import { flow, types } from 'mobx-state-tree';
import { loadScript } from '../utils/LoadExternalScript';

const pathToPage = {
  '/bills': 'Bills Page',
  // not implemented yet pages
  '/login': 'Login Page',
  '/confirmation': 'Confirmation Page',
  '/offer': 'Offer Page'
};
const IvyChatView = types
  .model('IvyChatView', {
    isLoaded: types.optional(types.boolean, false),
    ivy_key: types.maybeNull(types.string),
    pages_to_enable: types.maybeNull(types.array(types.string))
  })
  .actions(self => ({
    loadChatViewIfNeeded: flow(function* (pathName) {
      if (!self.isLoaded && self.pages_to_enable) {
        if (self.pages_to_enable.includes(pathToPage[pathName])) {
          try {
            yield loadScript(
              `https://bot.ivy.ai/bot/script/category/${self.ivy_key}`
            );
            self.isLoaded = true;
          } catch (e) {
            console.log(`error while trying to load Ivy Chat view, ${e}`);
          }
        }
      }
    })
  }));

export default IvyChatView;
