import { gql } from '@apollo/client';

export const VERIFY_CODE = gql`
  query verifyCode(
    $code: String!
    $identityValue: String!
    $provider: String!
  ) {
    verifyCode(
      code: $code
      identityValue: $identityValue
      provider: $provider
    ) {
      accountNumber
    }
  }
`;
