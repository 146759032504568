import IntlPolyfill from 'intl';
import 'intl/locale-data/jsonp/en-US';
import { camelCase } from 'lodash';

import { currencyToNumber } from './Number';
/**
 * converts number to currency format
 * if value is not a number, will attempt to convert to number
 * numberToCurrency(1) => "$1.00"
 * numberToCurrency(1.7654) => "$1.77"
 * numberToCurrency("1.7654") => "$1.77"
 * @param {Number} value
 * @param options formatting options
 * @param options.convertToCents
 * @param options.locales
 * @param options.currency

 */
export const numberToCurrency = (value, options = {}) => {
  const { convertToCents, locales, currency } = {
    ...{ convertToCents: true, locales: 'en-US', currency: 'USD' },
    ...options
  };
  if (isNaN(value)) {
    value = currencyToNumber(value);
  }

  if (convertToCents) {
    value = value / 100;
  }

  const IntlClass =
    window.Intl && window.Intl.NumberFormat ? window.Intl : IntlPolyfill;

  return new IntlClass.NumberFormat(locales, {
    ...(currency && {
      style: 'currency',
      currency: currency
    }),
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value);
};

/**
 * formats number as currency string with commas
 * looks for a multiple of 3 digits in a row and adds a comma
 * does not format number after '.' decimal point
 * formatCommas(1234567) => ""1,234,567"
 * formatCommas(1234567.55) => ""1,234,567.55"
 * @param {Number} val
 * @returns {String} {*}
 */
export const formatCommas = val => {
  const parts = val.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

/**
 * iterates over each word in string and converts first letter to uppercase
 * capitalize('hello world') => 'Hello Word'
 * capitalize('hello_world', '_') => 'Hello_Word'
 * @param {String} str
 * @param {String} delimiter
 * @returns {string}
 */
export const capitalize = (str, delimiter = ' ') =>
  str
    .toLowerCase()
    .split(delimiter)
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(delimiter);

/**
 * transforms object keys from snake_case to camelCase
 * objectKeysToCamelCase({myValue:1, myOtherValue:2}) => {my_value:1, my_other_value:2}
 * @param obj
 * @returns {obj}
 */
export const objectKeysToCamelCase = obj => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [camelCase(key), value])
  );
};

/**
 * stringifies a non-nested object into a url string
 * toQueryString({propA: 'my prop A', list:[1,2]}) => "propA=my prop A&list=[1,2]"
 * @param obj
 * @returns {string}
 */
export const toQueryString = obj =>
  Object.entries(obj)
    .map(
      ([key, val]) => `${key}=${Array.isArray(val) ? JSON.stringify(val) : val}`
    )
    .join('&');

/**
 * stringifies an object into a valid url string, in a format expected by old PE
 * toQueryStringOldPE({propA: 'my prop A', list:[1,2], nestedObject:{prop1, prop2}}) => encodeURIComponent(propA=my prop A&list[]=1&list[]=2&nestedObject[prop1]=one&nestedObject[prop2]=two)
 * @param obj
 * @returns {string}
 */
export const toQueryStringOldPE = obj => {
  //format obj as url params, in accordance with the way it's being formatted in old PE
  const entries = [];
  for (let key in obj) {
    if (Array.isArray(obj[key])) {
      //bill_ids[]=1,bill_ids[]=2
      obj[key].forEach(val => entries.push(`${key}[]=${val}`));
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      //mixpanel_data[bill_status]=past_due, mixpanel_data[remaining_balance]=12345
      const nestedObj = obj[key];
      const objKeys = Object.keys(nestedObj);
      for (const nestedKey of objKeys) {
        entries.push(`${key}[${nestedKey}]=${nestedObj[nestedKey]}`);
      }
    } else {
      entries.push(`${key}=${obj[key]}`);
    }
  }

  return encodeURI(entries.join('&'));
};

export const generateRandomString = (length = 5) => {
  return btoa(Math.random()).slice(0, length);
};

export const digitsOnly = value => /^\d+$/.test(value);
