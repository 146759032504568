import moment from 'moment-timezone';

const dateFormat = 'MM/DD/YYYY';

/**
 * Receives a Date object or a date string and return a moment-formatted date string in UTC
 * dateInUTC('2016-03-27') = "03/27/2016"
 * dateInUTC('2019-09-11T00:19:38-07:00') = "09/11/2019" //ignores timezone in string
 * @param {(Date/String)} date
 * @return {string}
 */
export const dateInUTC = date => moment.utc(date).format(dateFormat);

/**
 * Receives a Date object or a date string and returns a moment-formatted date string in UTC
 * dateToString(new Date()) = "10th of January"
 * dateToString("01/10/2014") = "10th of January"
 * @param {(Date/String)} date
 * @return {string}
 */
export const dateToDayOf = date => moment.utc(date).format('Do [of] MMMM');

/**
 * Receives a Date object or a date string and returns a moment-formatted date string in UTC
 * dateToString(new Date()) = "Jan 10th"
 * dateToString("01/10/2014") = "Jan 10th"
 * @param {(Date/String)} date
 * @return {string}
 */
export const dateToOrdinalDay = date => moment.utc(date).format('MMM Do');

/**
 * Receives a Date object or a date string and returns a moment date in UTC with month name
 * dateWithMonthName("01/10/2014") = "January 10, 2014"
 * @param {(Date/String)} date
 * @return {string}
 */
export const dateWithMonthName = date =>
  moment.utc(date).format('MMMM D, YYYY');

/**
 * Receives a date string and returns a boolean returns from moment.isAfter()
 * isFutureDate("01/10/2014") = false
 * isFutureDate("01/10/2025") = true
 * @param {(String)} dateStr
 * @return {boolean}
 */
export const isFutureDate = dateStr =>
  moment.utc(dateStr).isAfter(moment.utc(), 'day');

/**
 * Receives a date string and returns ordinal day of month in English (regardless of moment.locale)
 * ordinalInEng("11") = '11th';
 * ordinalInEng("22") = '22nd'
 * @param {Number} dateStr
 * @return {String}
 */
export const ordinalDayInEng = dateStr =>
  moment.localeData('en').ordinal(dateStr);

/**
 * Receives a Date object or a date string and return a moment-formatted date string in UTC starting with year
 * dateYMD('2016-03-27') = "2016/03/27"
 * dateYMD('2019-09-11T00:19:38-07:00') = "2019/09/11" //ignores timezone in string
 * @param {(Date/String)} date
 * @return {string}
 */
export const dateYMD = date => moment.utc(date).format('YYYY/MM/DD');
