import { applySnapshot, flow, getSnapshot, types } from 'mobx-state-tree';

import { GET_MIXPANEL_DATA } from '../graphql/queries';
import { UPDATE_MIXPANEL_DATA } from '../graphql/mutations';
import ApiModel from './base/ApiModel';
import { authSuccessProperties as authProps } from '../constants/mixpanel';
import { capitalize } from '../utils/String';
import { hasProp } from '../utils/Object';
import SessionParams from '../utils/SessionParams';

const MixpanelModel = types
  .model('MixpanelModel', {
    mixpanelData: types.frozen({
      Provider: types.maybeNull(types.string),
      Source: types.maybeNull(types.string),
      Platform: types.maybeNull(types.string),
      isBot: types.maybeNull(types.boolean),
      'isBot Name': types.maybeNull(types.string),
      'Authentication Fields': types.maybeNull(types.string),
      Facility: types.maybeNull(types.string),
      Language: types.maybeNull(types.string),
      distinctId: types.maybeNull(types.string)
    }),
    authFields: types.optional(types.frozen(), {})
  })
  .views(self => ({
    get distinctId() {
      return self.mixpanelData.distinctId;
    }
  }))
  .actions(self => ({
    setData: data => {
      if (!self.dataApplied) {
        self.setDefaultMixpanelDataValues();
      }
      const mixpanelData = { ...getSnapshot(self).mixpanelData, ...data };
      applySnapshot(self, {
        ...getSnapshot(self),
        dataApplied: true,
        ...{
          mixpanelData: mixpanelData
        }
      });
    },
    load: flow(function* () {
      try {
        self.dataApplied = false;
        const { mixpanelData } = yield self.query(GET_MIXPANEL_DATA);
        const data =
          typeof mixpanelData.data === 'string'
            ? JSON.parse(mixpanelData.data)
            : mixpanelData.data;

        const filteredObj = {};
        //filter response to only included props on self.mixpanelData
        Object.keys(self.mixpanelData).forEach(prop => {
          hasProp(data, prop) && (filteredObj[prop] = data[prop]);
        });
        //mixpanelData.Provider may be undefined
        //override with own provider name
        self.applyWithApiStatus({
          mixpanelData: { ...filteredObj, Provider: SessionParams.provider }
        });
      } catch (e) {
        console.error('MixpanelModel load error:', e);
      }
      return Promise.resolve();
    }),
    setAuthFields: (data, shouldUpdateServer = true) => {
      if (!self.dataApplied) return;
      let obj = {};
      if (Object.keys(data).length > 0) {
        obj = {
          [authProps.AUTHENTICATION_FIELDS]: self['Authentication Fields'],
          [authProps.PAYABLE_ID]: [data.id],
          [authProps.NUMBER_OF_BILLS_OPEN]: data.openBills,
          [authProps.REMAINING_BALANCE]: data.remainingBalance,
          [authProps.PAYMENT_PAST_DUE]: data.hasBillPastDue,
          [authProps.BILL_GOING_TO_COLLECTIONS]: data.hasBillBeforeCollection,
          [authProps.SELECT_BILLS]: data.selectBills,
          [authProps.HAS_AFFILIATES]: data.hasAffiliates,
          [authProps.ASSIST_OFFER]: data.offer,
          [authProps.GUARANTOR_SEGMENT]: data.guarantorSegment,
          [authProps.ASSIST_CAPACITY]: data.guarantorCapacity,
          [authProps.PAYMENT_PLAN_TYPE]: data.paymentPlanType
            ? capitalize(data.paymentPlanType)
            : 'None',
          [authProps.BILLS_ON_PAYMENT_PLAN]: data.billsOnPaymentPlan,
          //send payment plan props as null if no payment plan
          [authProps.PAYMENT_PLAN_STATUS]: data.paymentPlanType
            ? capitalize(data.paymentPlanStatus, '_').replace(/_/g, ' ')
            : null,
          [authProps.BALANCE_ON_PAYMENT_PLAN]:
            data.balanceOnPaymentPlan || null,
          [authProps.BALANCE_NOT_ON_PAYMENT_PLAN]:
            data.balanceNotOnPaymentPlan || null,
          [authProps.MISSED_PAYMENTS]: data.missedPayments || null,
          [authProps.DUNNING_LEVELS]: data.dunningCodes,
          [authProps.DISCOUNT_TOTAL_AMOUNT]: data.totalBillsDiscount,
          [authProps.PATIENT_TYPE]: data.isSelfPay ? 'Self-Pay' : 'Other',
          [authProps.RELIEF_DISCOUNT_AMOUNT]: data.totalReliefDiscount,
          [authProps.PAYZEN_ON]: data.payzenOn,
          [authProps.PAYMENT_PLAN_INSTALLMENT_DUE_DATE]:
            data.nextInstallmentDate
        };
      }
      const baseProperties = { ...self.mixpanelData };
      delete baseProperties.distinctId;
      self.authFields = { ...obj, ...baseProperties };
      if (shouldUpdateServer) {
        self.updateMixpanelData(self.authFields);
      }
    },
    updateMixpanelData: flow(function* (obj) {
      try {
        yield self.mutate(
          UPDATE_MIXPANEL_DATA,
          { mixpanelData: JSON.stringify(obj) },
          { isCritical: false }
        );
      } catch (e) {
        console.log('caught UPDATE_MIXPANEL_DATA error', e);
      }
    }),
    setDefaultMixpanelDataValues: () => {
      const obj = {};
      Object.keys(self.mixpanelData).forEach(key => (obj[key] = null));
      applySnapshot(self, {
        ...getSnapshot(self),
        ...{
          mixpanelData: obj
        }
      });
    }
  }));

export default types.compose(ApiModel, MixpanelModel);
