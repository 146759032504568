import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const CERNER_AUTHENTICATION = gql`
  mutation cernerAuthentication($bcsToken: String!) {
    cernerAuthentication(bcsToken: $bcsToken) ${CLIENT} {
      errors
      commandId
    }
  }
`;
