const SessionParams = () => {};

SessionParams.providerName = null; //"Saint Mary's Hospital"
SessionParams.provider = null; //"st_mary"
SessionParams.primaryEmailAddress = '';
SessionParams.memberId = 0;
SessionParams.accountNumber = 0;
SessionParams.accountId = 0;

export default SessionParams;
