import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const GET_SETTINGS = gql`
  query settings {
    settings ${CLIENT} {
      id
      disabled
      configurations
      settingName
    }
  }
`;

export const GET_PUBLIC_SETTINGS = gql`
  query publicSettings($provider: String!) {
    publicSettings(provider: $provider) ${CLIENT} {
      id
      disabled
      configurations
      settingName
    }
  }
`;
