import React from 'react';
import PropTypes from 'prop-types';
import StackTrace from 'stacktrace-js';

import { storeContext } from '../../context';
import UserActionsRecorder from './UserActions';

const errCallback = stackFrames => {
  return stackFrames.map(sf => sf.toString()).join('\n');
};

const ignoreMessage = message => {
  //Google Translate Extension error
  if (message.includes('a[b].target.className.indexOf is not a function')) {
    return true;
  }
  return false;
};

class UncaughtErrorsHandler extends React.Component {
  static contextType = storeContext;

  async reportError(error) {
    const {
      jsErrorReporter,
      userState: { userActions }
    } = this.context;
    if (error && error.message && !ignoreMessage(error.message)) {
      try {
        //try to generate stack trace with StackTrace package
        const parsedError = await StackTrace.fromError(error);
        const stack = errCallback(parsedError);

        await jsErrorReporter.sendReport({
          message: error.message,
          stack: stack || error.stack,
          clientActions: userActions.toString()
        });
      } catch (e) {
        console.log('caught error while trying to report', e);
      }
    }
  }

  /*componentDidMount() {
    window.onerror = (errorMsg, url, lineNumber, colNum, error) => {
      this.reportError(error);
    };
    window.addEventListener('unhandledrejection', promiseRejectionEvent => {
      const { reason } = promiseRejectionEvent;
      if (reason && reason.message) {
        this.reportError(reason);
      }
    });
  }*/

  componentDidCatch(error, errorInfo) {
    console.log('componentDidCatch!');
    this.reportError(error);
  }

  render() {
    return (
      <>
        <UserActionsRecorder>{this.props.children}</UserActionsRecorder>
      </>
    );
  }
}

UncaughtErrorsHandler.propTypes = {
  children: PropTypes.node.isRequired
};

export default UncaughtErrorsHandler;
