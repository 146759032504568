import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const GET_CURAE_INFO = gql`
  query getCuraeMonthlyPaymentInfo($standingAmount: Int!) {
    getCuraeMonthlyPaymentInfo(standingAmount: $standingAmount) ${CLIENT} {
      monthlyAmount
      monthDuration
      apr
    }
  }
`;

export const CHECK_CURAE_TRANSACTION_STATUS = gql`
  query checkCuraeTransactionStatus($transactionId: ID!) ${CLIENT} {
    checkCuraeTransactionStatus(transactionId: $transactionId)
  }
`;

export const GET_CURAE_ACCOUNT_STATUS = gql`
  query getCuraeAccountStatus($id: ID!) {
    getCuraeAccountStatus(id: $id) ${CLIENT} {
      status
      availableCredit
      externalAccountId
    }
  }
`;

export const GET_CURAE_URL_STATUS = gql`
  query getCuraeUrlStatus($id: ID!) {
    getCuraeUrlStatus(id: $id) ${CLIENT} {
      status
      url
    }
  }
`;
