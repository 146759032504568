import { types } from 'mobx-state-tree';
import ProviderModel from './Provider';

const Payee = types.model('Provider', {
  provider: ProviderModel,
  companyName: types.maybeNull(types.string),
  companyDisplayName: types.maybeNull(types.string),
  paypage: types.string,
  paypageId: types.maybeNull(types.string),
  creditCardProcessor: types.string,
  vaultUrl: types.string,
  paypalPermissions: types.boolean,
  echeckPermissions: types.boolean,
  creditCardPermissions: types.boolean,
  paypalClientToken: types.maybeNull(types.string),
  processorsGenericFields: types.array(types.string),
  paymentApplicationStrategy: types.maybeNull(types.string)
});

export default Payee;
