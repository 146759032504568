import { types } from 'mobx-state-tree';
import { t } from '../utils/i18next';

const CptCode = types.model('ChargesCptCode', {
  code: types.maybeNull(types.number),
  translation: types.maybeNull(types.string)
});

const appearance = types.model('SingleAppointmentAppearance', {
  id: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  appearanceDate: types.maybeNull(types.string),
  active: types.maybeNull(types.boolean)
});

export const Appointment = types
  .model('Appointment', {
    id: types.maybeNull(types.string),
    visitId: types.maybeNull(types.string),
    providerName: types.maybeNull(types.string),
    providerDisplayName: types.maybeNull(types.string),
    amountPaid: types.maybeNull(types.number),
    nextPayment: types.maybeNull(types.number),
    remainingBalance: types.maybeNull(types.number),
    originalBalance: types.maybeNull(types.number),
    cptCodes: types.maybeNull(types.array(CptCode)),
    hasPaymentPlan: types.maybeNull(types.boolean),
    patientName: types.maybeNull(types.string),
    appearances: types.optional(types.array(appearance), []),
    isSelected: types.optional(types.boolean, false),
    selectable: types.optional(types.boolean, false),
    type: types.optional(types.string, 'appointment')
  })
  .views(self => ({
    get futureAppearances() {
      return self.appearances
        .filter(({ status, active }) => active && status === 'Scheduled')
        .sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    get nextUpcomingAppearance() {
      const nextUpcomingDate = self.futureAppearances[0]?.appearanceDate;
      const lastAppearanceDate = self.appearances.at(-1).appearanceDate;
      return nextUpcomingDate || lastAppearanceDate;
    },
    get futureAppearancesDisplayed() {
      return self.futureAppearances.map(({ appearanceDate }) => {
        const date = new Date(appearanceDate);
        return {
          date: date.toLocaleDateString('en-US', {
            timeZone: 'UTC',
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }),
          time: date.toLocaleTimeString('en-US', {
            timeZone: 'UTC',
            hour: 'numeric',
            minute: '2-digit',
            hour12: 'true'
          })
        };
      });
    },
    get earliestDate() {
      return self.appearances?.[0]?.appearanceDate || null;
    },
    get displayName() {
      const translationBase = 'payables.appointments';
      return t(`${translationBase}.displayName`, {
        interpolation: { escapeValue: false },
        patient_name: self.patientName,
        provider_name: self.providerDisplayName
      });
    },
    get amountDue() {
      return self.remainingBalance;
    },
    get admissionDate() {
      return self.nextUpcomingAppearance;
    },
    get isBillPastDue() {
      return false;
    },
    get paymentPlanExists() {
      return self.hasPaymentPlan;
    }
  }))
  .actions(self => ({
    setSelected: isSelected => {
      self.isSelected = isSelected;
    }
  }));
