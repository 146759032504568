import React, { lazy } from 'react';
import { retryFunction } from '../../../utils/Function';

/**
 * React.lazy with retry - if loading chunk fails (production bug), an attempt to reload the chunk is invoked
 * @param {function} fn - import function (e.g import('../Component/Component'))
 */
const LazyLoadWithRetry = fn => lazy(() => retryFunction(fn));

export default LazyLoadWithRetry;
