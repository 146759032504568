import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const CC_GET_AUTHENTICATION_TOKEN = gql`
  query careCreditAuthenticationDetails($commandId: ID!) {
    careCreditAuthenticationDetails(id: $commandId) ${CLIENT} {
      careCreditCardHolder {
        address
        address2
        city
        externalAccountId
        firstName
        lastName
        state
        zipCode
      }
      careCreditAuthentication {
        childMid
        clientTransId
        merchantId
        pcgc
        tokenId
      }
      errors
    }
  }
`;

export const CC_GET_TRANSACTION_DETAILS = gql`
  query careCreditTransactionDetails($commandId: ID!) {
    careCreditTransactionDetails(id: $commandId) ${CLIENT} {
      errors
      payment {
        uniqueId
        amount
        createdAt
      }
    }
  }
`;
