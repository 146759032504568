//https://github.com/apollographql/apollo-client/issues/4843
import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const QUERY_TYPES = {
  QUERY: 'query',
  MUTATION: 'mutate',
  SUBSCRIPTION: 'subscribe'
};

export {
  CC_GET_AUTHENTICATION_TOKEN,
  CC_GET_TRANSACTION_DETAILS
} from './careCredit';

export { CERNER_GET_TOKEN } from './cernerPagelet';

export {
  GET_CURAE_INFO,
  GET_CURAE_ACCOUNT_STATUS,
  GET_CURAE_URL_STATUS,
  CHECK_CURAE_TRANSACTION_STATUS
} from './curae';

export {
  fullOffersFields,
  GET_FULL_OFFERS,
  GET_DYNAMIC_OFFER,
  GET_PAYZEN_OFFERS
} from './offers';

export {
  GET_FEE_PAYMENT_AMOUNT,
  GET_LIMITS,
  GET_PREVIOUS_PAYMENTS,
  VALIDATE_CREDIT_CARD,
  VALIDATE_ECHECK,
  GET_SAVED_CREDIT_CARDS,
  GET_SAVED_ECHECKS
} from './payment';

export {
  GET_MEMBERS_ADDRESS_INFO,
  GET_MEMBER_EMAIL_ADDRESSES,
  GET_PAST_STATEMENTS,
  GET_STATEMENT_FILE,
  GET_MEMBER_PHONE_NUMBER
} from './notifications';

export {
  GET_PROVIDER_DETAILS,
  GET_PROVIDER_NAME,
  GET_PROVIDER_LINKS
} from './provider';

export {
  GET_PAYABLES,
  GET_PAYABLE_STANDING_AMOUNT,
  GET_SINGLE_BILL
} from './payable';

export { GET_APPOINTMENTS } from './appointments';

export { GET_PUBLIC_SETTINGS, GET_SETTINGS } from './settings';

export { VERIFY_CODE } from './accountLookup';

export const GET_LOGIN_FIELDS = gql`
  query loginFields(
    $provider: String!
    $billId: ID
    $notification: String
    $notificationId: ID
  ) {
    loginFields(
      provider: $provider
      billId: $billId
      notification: $notification
      notificationId: $notificationId
    ) ${CLIENT} {
      loginField
      payableId
      error
    }
  }
`;

export const GET_TRANSLATION_FILES = gql`
  query translationInfo($provider: String) {
    translationInfo(provider: $provider) ${CLIENT} {
      currentLanguage
      currentCompany
      translationFiles {
        language
        links
      }
    }
  }
`;

export const GET_MIXPANEL_DATA = gql`
  query mixpanelData {
    mixpanelData ${CLIENT} {
      data
    }
  }
`;

export const GET_PAYMENT_PLAN_TERMS = gql`
  query terms(
    $serviceFee: Int!
    $amountPerInstallment: Int!
    $totalBalance: Int!
  ) {
    termsResult(
      termsInput: {
        serviceFee: $serviceFee
        amountPerInstallment: $amountPerInstallment
        totalBalance: $totalBalance
      }
    ) ${CLIENT} {
      errors
      terms {
        amountPerInstallment
        amountPerInstallmentTotal
        lastInstallmentAmount
        lastInstallmentAmountWarning
        lastInstallmentTotal
        serviceFee
        totalBalance
        totalBalanceIncludingFees
        totalFees
        totalNumberOfInstallments
      }
    }
  }
`;

export const PAYMENT_PLAN_COMMAND = gql`
  query PaymentPlanCommand($id: ID!) {
    paymentPlanCommand(id: $id) ${CLIENT} {
      errors
      paymentPlan {
        id
        fundedBy
      }
    }
  }
`;

export const GET_RESOURCES_FILES = gql`
  query resourceInfo($name: String!) {
    resourceInfo(name: $name) ${CLIENT} {
      link
    }
  }
`;

export const GET_PAYZEN_LEGAL_DOCS = gql`
  query payzenLegalDocs(
    $accountHolderName: String!
    $accountNumber: String!
    $company: String!
    $offerId: String!
    $paymentInformation: PaymentInformationInput!
    $paymentMethodData: PaymentMethodDataInput!
  ) {
    payzenLegalDocs(
      accountHolderName: $accountHolderName
      accountNumber: $accountNumber
      company: $company
      offerId: $offerId
      paymentInformation: $paymentInformation
      paymentMethodData: $paymentMethodData
    ) ${CLIENT} {
      links {
        url
        name
      }
    }
  }
`;

export const GET_COMMAND_RESULT = gql`
  query commandV2(
      $id: ID!
    ) {
      commandV2(
        id: $id
      )${CLIENT}{
          status
          result{
              isSuccess
              description
          }
      }
  }
`;
