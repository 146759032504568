import { flow, types } from 'mobx-state-tree';
import ApiModel from './base/ApiModel';

import {
  GET_CURAE_INFO,
  GET_CURAE_ACCOUNT_STATUS,
  GET_CURAE_URL_STATUS
} from '../graphql/queries';
import { CURAE_ACCOUNT_LOOKUP, CREATE_URL } from '../graphql/mutations';

const REQUEST_TIME_INTERVAL = 3000;
const MAX_ATTEMPTS = 30;
const SETTLED_PAYMENT = 4;
const IN_PROGRESS_STATUS = 'in_progress';
const URL_STATUSES = ['in_progress', 'pending'];

const Curae = types
  .model('Curae', {
    monthlyAmount: types.maybeNull(types.number),
    monthDuration: types.maybeNull(types.number),
    apr: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
    availableCredit: types.maybeNull(types.number),
    externalAccountId: types.maybeNull(types.number),
    accountId: types.maybeNull(types.string),
    memberId: types.maybeNull(types.number),
    patientId: types.maybeNull(types.string),
    sessionUrlId: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
    isFromCurae: types.optional(types.boolean, false),
    showCuraeModalError: types.optional(types.boolean, false)
  })
  .actions(self => {
    const pollCheckAccountStatus = flow(function* () {
      try {
        const { account } = yield self.poll(
          () => checkAccountStatus(self.accountId),
          validateCuraeAccountStatus,
          REQUEST_TIME_INTERVAL,
          MAX_ATTEMPTS
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    });

    const pollCheckUrlStatus = flow(function* () {
      try {
        const { session_url } = yield self.poll(
          () => checkUrlStatus(self.sessionUrlId),
          validateCuraeUrlStatus,
          REQUEST_TIME_INTERVAL,
          MAX_ATTEMPTS
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    });

    const validateCuraeAccountStatus = account => {
      return account.status != IN_PROGRESS_STATUS;
    };

    const validateCuraeUrlStatus = session_url => {
      return !URL_STATUSES.includes(session_url.status);
    };

    const checkAccountStatus = async function (id) {
      console.log('curae check account status...');
      try {
        const { getCuraeAccountStatus } = await self.query(
          GET_CURAE_ACCOUNT_STATUS,
          {
            id: id
          }
        );
        self.applyWithApiStatus(getCuraeAccountStatus);
        return getCuraeAccountStatus;
      } catch (e) {
        console.error('Curae Model error', e);
        return Promise.reject();
      }
    };

    const checkUrlStatus = async function (id) {
      console.log('curae check url status...');
      try {
        const { getCuraeUrlStatus } = await self.query(GET_CURAE_URL_STATUS, {
          id: id
        });
        self.applyWithApiStatus(getCuraeUrlStatus);
        return getCuraeUrlStatus;
      } catch (e) {
        console.error('Curae Model error', e);
        return Promise.reject();
      }
    };

    const createUrl = flow(function* (
      memberId,
      payableId,
      accountId,
      amount,
      accountNumber,
      ssn
    ) {
      console.log('curae create url...');
      try {
        const { curaeCreateUrl } = yield self.mutate(CREATE_URL, {
          memberId: memberId,
          payableId: payableId,
          accountId: accountId,
          amount: amount,
          accountNumber: accountNumber,
          ssn: ssn
        });
        self.sessionUrlId = curaeCreateUrl.id;
        yield pollCheckUrlStatus();
        return Promise.resolve();
      } catch (e) {
        console.error('Curae Model error', e);
        return Promise.reject(e);
      }
    });

    return {
      fetchData: flow(function* (standingAmount) {
        console.log('loading curae values...');
        try {
          const { getCuraeMonthlyPaymentInfo } = yield self.query(
            GET_CURAE_INFO,
            {
              standingAmount: standingAmount
            }
          );
          self.applyWithApiStatus(getCuraeMonthlyPaymentInfo);
          return Promise.resolve();
        } catch (e) {
          console.error('Curae Model error', e);
          return Promise.reject();
        }
      }),
      lookupAccount: flow(function* (ssn, memberId) {
        console.log('curae lookup account...');
        try {
          const { curaeAccountLookup } = yield self.mutate(
            CURAE_ACCOUNT_LOOKUP,
            {
              ssn: ssn,
              memberId: memberId,
              externalPatientId: self.patientId
            }
          );
          self.accountId = curaeAccountLookup.id;
          yield pollCheckAccountStatus();
          return Promise.resolve();
        } catch (e) {
          console.error('Curae Model error', e);
          return Promise.reject(e);
        }
      }),
      redirectToCurae: flow(function* (
        memberId,
        id,
        accountId,
        standingAmount,
        accountNumber,
        ssn = ''
      ) {
        try {
          yield createUrl(
            memberId,
            id,
            accountId,
            standingAmount,
            accountNumber,
            ssn
          );
        } catch (error) {
          console.log('Create url failed with error: ' + error);
        } finally {
          console.log('URL status ' + self.status);
          if (self.status === 'ready') {
            window.location.replace(self.url);
          } else {
            self.showCuraeModalError = true;
          }
        }
      }),
      abortPolling: flow(function* () {
        console.log('curae abort polling...');
        self.clearTimeOut();
      }),
      handleTransactionStatus: status => {
        if (status !== SETTLED_PAYMENT) {
          throw `invalid status for curae payment status received:  ${status}`;
        }
      },
      afterAttach: () => {
        const searchParams = new URLSearchParams(window.location.search);

        self.isFromCurae =
          window.location.pathname.includes('/app/login') &&
          searchParams.get('fromCurae') === 'true';
        if (self.isFromCurae) {
          self.status = searchParams.get('status').toLowerCase();
          self.patientId = searchParams.get('patient_id');
        }
      },
      resetCuraeParams: () => {
        self.patientId = '';
        self.isFromCurae = false;
      }
    };
  });
export default types.compose(ApiModel, Curae);
