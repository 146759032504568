export const billStatuses = {
  UNKNOWN: '0',
  REJECTED: '2',
  PROCESSED: '3',
  PENDING_PROVIDER: '4',
  PENDING_INSURANCE: '5',
  PENDING_SECONDARY_INSURANCE: '6',
  ESTIMATED: '7',
  IN_COLLECTION: '8',
  MOVED: '9',
  PRE_SERVICE: '10'
};

export const groupingBy = {
  RAW_VISIT_TYPE: 'rawVisitType',
  PARTNER_NAME: 'partnerName'
};
