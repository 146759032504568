import { types } from 'mobx-state-tree';
import { createBrowserHistory } from 'history';

let history; // = createBrowserHistory({ basename: '/app' });

const RouterStateModel = types
  .model('RouterStateModel', {
    location: types.optional(types.frozen(), {}),
    action: types.optional(types.string, '')
  })
  .actions(self => ({
    update: newState => {
      try {
        self.location = newState;
        self.action = history.action;
      } catch (e) {
        console.log('RouterStateModel error:', e);
      }
    }
  }));

/*
 * RouterModel exports unified history object,
 * registers actions and assures routing logic remains in MST and not in views
 */
export const RouterModel = types
  .model('RouterModel', {
    routerStateModel: types.optional(RouterStateModel, {}),
    initialUrlParams: types.maybeNull(types.string)
  })
  .actions(self => {
    const onLocationChange = location => {
      self.routerStateModel.update({ ...location });
    };

    return {
      setBaseName: path => {
        console.warn('setBaseName:', path);
        try {
          history = createBrowserHistory({ basename: path });
          //history patch - override push()
          self.historyPush = history.push;
          history.push = self.push;
          //
          history.listen(onLocationChange);
        } catch (e) {
          console.log('---RouterModel error:', e);
        }
        self.routerStateModel = RouterStateModel.create();
      },
      push: (newPath, newState) => {
        //do not update history and cause re-renders if not necessary
        const { pathname, state } = self.routerStateModel.location;
        if (
          newPath !== pathname ||
          JSON.stringify(newState) !== JSON.stringify(state)
        ) {
          self.historyPush(newPath, newState);
        }
      }
    };
  })
  .views(self => {
    return {
      get history() {
        return history;
      },
      get currentPage() {
        return self.routerStateModel.location;
      }
    };
  });

export default RouterModel;
