import { flow, types } from 'mobx-state-tree';

import { GET_PAYMENT_PLAN_TERMS } from '../graphql/queries';
import ApiModel from './base/ApiModel';
import {
  customPlanErrors,
  serverTermsErros,
  serverTermsErrorTitles
} from '../constants/paymentOptions';

const PaymentPlanTerms = types
  .model('PaymentPlanTerms', {
    serviceFee: types.optional(types.number, 0),
    amountPerInstallment: types.optional(types.number, 0),
    amountPerInstallmentTotal: types.optional(types.number, 0),
    lastInstallmentAmount: types.optional(types.number, 0),
    lastInstallmentAmountWarning: types.optional(types.boolean, false),
    lastInstallmentTotal: types.optional(types.number, 0),
    totalBalance: types.optional(types.number, 0),
    totalBalanceIncludingFees: types.optional(types.number, 0),
    totalFees: types.optional(types.number, 0),
    totalNumberOfInstallments: types.optional(types.number, 0),
    unsupportedError: types.optional(types.string, '')
  })
  .actions(self => {
    const {
      OVER_MAX_AMOUNT,
      BELOW_MIN_AMOUNT,
      GENERAL_ERROR
    } = customPlanErrors;

    const onGeneralError = error => {
      self.unsupportedError = JSON.stringify(error);
      return {
        error: {
          type: GENERAL_ERROR
        }
      };
    };
    return {
      getTermsForCustomPlanFromServer: flow(function* (
        amountPerInstallment,
        fullAmount
      ) {
        try {
          self.dataApplied = false;
          const { termsResult } = yield self.query(GET_PAYMENT_PLAN_TERMS, {
            amountPerInstallment,
            totalBalance: fullAmount,
            serviceFee: self.serviceFee
          });

          if (!termsResult.errors && termsResult.terms) {
            self.applyWithApiStatus(termsResult.terms);
          } else {
            const error = termsResult.errors[0];
            const {
              INVALID_PAYMENT_PLAN_MONTHLY_AMOUNT
            } = serverTermsErrorTitles;
            const { MIN_AMOUNT, MAX_AMOUNT } = serverTermsErros;
            if (error.title === INVALID_PAYMENT_PLAN_MONTHLY_AMOUNT) {
              if (amountPerInstallment < error.meta[MIN_AMOUNT]) {
                return {
                  error: {
                    type: BELOW_MIN_AMOUNT,
                    args: error.meta[MIN_AMOUNT]
                  }
                };
              } else if (amountPerInstallment >= error.meta[MAX_AMOUNT]) {
                return {
                  error: {
                    type: OVER_MAX_AMOUNT,
                    args: error.meta[MAX_AMOUNT]
                  }
                };
              }
            }
            //if we've gotten here with errors, the error was not supported. Add general error message
            return onGeneralError(error);
          }

          return Promise.resolve(termsResult);
        } catch (e) {
          console.error('PaymentPlanTerms Model error', e);
          return onGeneralError(e);
        }
      }),
      getCurrentTermsData: () => {
        return {
          offer: self.totalNumberOfInstallments,
          totalFees: self.totalFees,
          amountPerInstallment: self.amountPerInstallment,
          lastInstallmentAmount: self.lastInstallmentAmount,
          totalAmountPerInstallment: self.amountPerInstallmentTotal,
          totalBalanceIncludingFees: self.totalBalanceIncludingFees,
          lastInstallmentTotalAmount: self.lastInstallmentTotal,
          serviceFee: self.serviceFee,
          totalBalance: self.totalBalance
        };
      }
    };
  });

export default types.compose(ApiModel, PaymentPlanTerms);
