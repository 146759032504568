import { types, flow } from 'mobx-state-tree';

import { GET_PROVIDER_LINKS } from '../graphql/queries';
import LocalStorageUtil from '../utils/LocalStorage';
import ApiModel from './base/ApiModel';

const ProviderLinks = types
  .model('ProviderLinks', {
    helpLink: types.maybeNull(types.string),
    providerMessageLink: types.maybeNull(types.string),
    provider: types.maybeNull(types.string),
    currentLanguage: types.maybeNull(types.string)
  })
  .actions(self => ({
    setProvider: providerName => {
      self.provider = providerName;
      if (providerName) {
        LocalStorageUtil.set('providerName', providerName);
      } else {
        const savedProviderName = LocalStorageUtil.get('providerName');
        savedProviderName && (self.provider = savedProviderName);
      }
    },
    setLanguage: language => (self.currentLanguage = language),
    load: flow(function* () {
      self.dataApplied = false;
      try {
        const { providerLinks } = yield self.query(GET_PROVIDER_LINKS);
        self.applyWithApiStatus(providerLinks);
        return Promise.resolve();
      } catch (e) {
        //console.error('ProviderLinks error:', e);
        return Promise.reject();
      }
    })
  }))
  .views(self => ({
    get providerLink() {
      const { protocol, host } = window.location;
      let newHost = host;
      if (host === 'localhost:5000') {
        //app is running locally, use static address
        newHost = 'localhost:3001';
      }
      return self.provider
        ? `${protocol + '//' + newHost}/providers/${self.provider}`
        : null;
    },
    get logoutLink() {
      const { currentLanguage, provider, providerLink } = self;
      const languageParam = currentLanguage
        ? `?language=${currentLanguage}`
        : '';
      return provider ? `${providerLink}/bills/logout${languageParam}` : null;
    },
    get paymentLink() {
      return self.provider ? `${self.providerLink}/payments/new` : null;
    }
  }));

export default types.compose(ApiModel, ProviderLinks);
