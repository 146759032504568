import { flow, types } from 'mobx-state-tree';

import ApiModel from './base/ApiModel';
import { GET_MEMBERS_ADDRESS_INFO } from '../graphql/queries';
import { CHANGE_ADDRESS_INFORMATION } from '../graphql/mutations';
import CommonActions from './base/CommonActions';
import SessionParams from '../utils/SessionParams';

const PlanCoverages = types.model('PlanCoverages', {
  payer: types.maybeNull(types.string),
  insuranceId: types.maybeNull(types.string),
  insurancePlan: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  priority: types.integer,
  link: types.maybeNull(types.string)
});

const AddressDetails = types.model('AddressDetails', {
  address: types.maybeNull(types.string),
  address2: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  zipcode: types.maybeNull(types.string)
});

const AddressInformation = types
  .model('AddressInformation', {
    id: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    middleName: types.maybeNull(types.string),
    dateOfBirth: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    addressDetails: types.maybeNull(AddressDetails),
    planCoverages: types.optional(types.array(PlanCoverages), [])
  })
  .actions(self => ({
    load: flow(function* () {
      try {
        self.dataApplied = false;
        const { guarantorOfAccount } = yield self.query(
          GET_MEMBERS_ADDRESS_INFO,
          {
            accountId: SessionParams.accountId
          }
        );
        // temp patch for phone number as phone
        guarantorOfAccount['phone'] = guarantorOfAccount.phoneNumber?.number;
        delete guarantorOfAccount['phoneNumber'];
        self.applyWithApiStatus(guarantorOfAccount);
        self.changeNullToEmptyString();
        return Promise.resolve();
      } catch (e) {
        console.error('MembersAddress Model error', e);
        return Promise.reject();
      }
    }),
    updateDetails: flow(function* (args) {
      console.log('sending updateDetails...', args);

      //hasDataChanged() is inherited from CommonActions model
      if (!self.hasDataChanged(args).changed) {
        return Promise.resolve('noChange');
      }
      try {
        const { addressChange } = yield self.mutate(
          CHANGE_ADDRESS_INFORMATION,
          {
            payableId: SessionParams.payableId,
            address: args.address,
            address2: args.address2 || '',
            city: args.city,
            email: args.email || '',
            primaryPhoneNumber: args.primaryPhoneNumber,
            stateShortName: args.stateShortName,
            zipcode: args.zipcode
          }
        );

        const transformParams = params => {
          return {
            addressDetails: {
              ...params,
              state: params.stateShortName
            },
            phone: params.primaryPhoneNumber
          };
        };

        self.applyWithApiStatus(transformParams(addressChange.addressChange));
        self.changeNullToEmptyString();
        return Promise.resolve('success');
      } catch (e) {
        return Promise.reject('PersonalInformation updateDetails error: ' + e);
      }
    })
  }))
  .views(self => ({
    get address() {
      return self.addressDetails?.address;
    },
    get address2() {
      return self.addressDetails?.address2;
    },
    get city() {
      return self.addressDetails?.city;
    },
    get state() {
      return self.addressDetails?.state;
    },
    get zipcode() {
      return self.addressDetails?.zipcode;
    },
    get email() {
      return SessionParams?.primaryEmailAddress;
    },
    get highestPriorityPlanCoverage() {
      return self.planCoverages.reduce((a, b) => {
        return a && a.priority <= b.priority ? a : b;
      }, null);
    }
  }));

export default types.compose(CommonActions, ApiModel, AddressInformation);
