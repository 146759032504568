import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { storeContext } from '../../../context';
import BillsPageLoader from './BillsPageLoader';
import LoginPageLoader from './LoginPageLoader';

const AppLoaderHandler = observer(() => {
  const {
    isPageletMode,
    session: { isLoggedIn }
  } = useContext(storeContext);

  //only show loader if its not pagelet mode.
  const showLoadingState = !isPageletMode;

  const renderLoadingState = () => {
    if (showLoadingState) {
      if (isLoggedIn) {
        return <BillsPageLoader />;
      } else {
        return <LoginPageLoader />;
      }
    } else {
      return null;
    }
  };

  return renderLoadingState();
});

export default AppLoaderHandler;
