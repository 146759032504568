import { cloneDeep } from 'lodash';
import { types, flow, applySnapshot, getSnapshot } from 'mobx-state-tree';
import { getClient } from '../../graphql/client';
import { QUERY_TYPES } from '../../graphql/queries';

const getRandomId = () => {
  return `${
    Date.now().toString(36) + Math.random().toString(36).substring(2)
  }-pe-ui`;
};

const GraphQlModel = types
  .model('ApiModel', {
    //set graphQl fetchPolicy
    //cache-first, cache-and-network, network-only, no-cache, cache-only
    fetchPolicy: types.optional(types.string, 'network-only'),
    //automatically set to false when starting call, set to true when response is received
    isSendingRequest: types.optional(types.boolean, false),
    //manually set to true via applyWithApiStatus()
    dataApplied: types.optional(types.boolean, false),
    //automatically set to true if call fails
    hasApiError: types.optional(types.boolean, false),
    apiErrorType: types.optional(types.frozen(), null),
    apiRetriesCount: types.optional(types.number, 0),
    isCriticalError: types.optional(types.boolean, false),
    errorReport: types.frozen({})
  })
  .actions(self => {
    let _operation,
      _query,
      _variables,
      _url,
      _params,
      _isCritical,
      _skipRetry,
      _ignoreErrorLogs,
      _timeout;

    const getLoadingStatus = finished => ({
      isSendingRequest: !finished,
      hasApiError: false,
      apiErrorType: null,
      apiRetriesCount: finished ? 0 : self.apiRetriesCount
    });
    const setLoadingStatus = finished => {
      applySnapshot(self, {
        ...getSnapshot(self),
        ...getLoadingStatus(finished)
      });
    };

    const onError = flow(function* (e = {}, callType, query) {
      !_ignoreErrorLogs && console.error('API Error:', e);
      const errorReport = { query: '', errorMessage: e.message };
      if (!_skipRetry && self.apiRetriesCount === 0) {
        self.apiRetriesCount++;
        console.log('----retrying call....', errorReport.query);
        if (callType === 'graphQl') {
          return yield generateGraphQlOperation(
            _operation,
            _query,
            _variables,
            {
              isCritical: _isCritical,
              skipRetry: _skipRetry,
              ignoreErrorLogs: _ignoreErrorLogs
            }
          );
        } else {
          return yield fetchRequest(_url, _params, {
            isCritical: _isCritical,
            skipRetry: _skipRetry,
            ignoreErrorLogs: _ignoreErrorLogs
          });
        }
      }

      if (typeof query === 'string') {
        errorReport.query = query;
      } else if (query.definitions && query.definitions.length) {
        try {
          errorReport.query = query.definitions[0].name.value;
          !_ignoreErrorLogs &&
            console.error('query failed:', errorReport.query);
        } catch (e) {
          console.log(e);
        }
      }

      self.applyError(errorReport, e);
      return yield Promise.reject(e);
    });

    const generateGraphQlOperation = flow(function* (
      operation,
      query,
      variables,
      { isCritical, skipRetry, ignoreErrorLogs }
    ) {
      _operation = operation;
      _query = query;
      _variables = variables;
      _isCritical = isCritical;
      _skipRetry = skipRetry;
      _ignoreErrorLogs = ignoreErrorLogs;

      let res;

      setLoadingStatus(false);
      variables = { ...variables, ignoreErrorLogs };

      const client = getClient();

      switch (operation) {
        case 'query':
          try {
            res = yield client.query({
              query,
              variables,
              context: { headers: { 'X-Request-ID': getRandomId() } },
              fetchPolicy: self.fetchPolicy
            });
          } catch (e) {
            return onError(e, 'graphQl', query);
          }

          break;
        case 'mutate':
          try {
            res = yield client.mutate({
              mutation: query,
              context: { headers: { 'X-Request-ID': getRandomId() } },
              variables
            });
          } catch (e) {
            return onError(e, 'graphQl', query);
          }
          break;
        default:
          console.error('illegal operation');
      }

      setLoadingStatus(true);
      return Promise.resolve(cloneDeep(res.data));
    });

    const fetchRequest = flow(function* (
      url,
      params,
      { isCritical, skipRetry, ignoreErrorLogs }
    ) {
      _url = url;
      _params = params;
      _isCritical = isCritical;
      _skipRetry = skipRetry;
      _ignoreErrorLogs = ignoreErrorLogs;
      const defaultParams = {
        method: 'GET'
      };

      try {
        const response = yield fetch(url, {
          ...defaultParams,
          ...params
        });
        if (response.status === 200 || response.status === 304) {
          return response.json();
        }
        return onError(`response.status: ${response.status}`, 'fetch');
      } catch (e) {
        return onError(e, 'fetch', url);
      }
    });

    const defaultApiOptions = { isCritical: true, skipRetry: false };
    return {
      query: flow(function* (query, variables, options = {}) {
        return yield generateGraphQlOperation(
          QUERY_TYPES.QUERY,
          query,
          variables,
          { ...defaultApiOptions, ...options }
        );
      }),
      mutate: flow(function* (query, variables, options = {}) {
        return yield generateGraphQlOperation(
          QUERY_TYPES.MUTATION,
          query,
          variables,
          { ...defaultApiOptions, ...options }
        );
      }),
      subscribe: flow(function* (query, variables, options = {}) {
        return yield generateGraphQlOperation(
          QUERY_TYPES.SUBSCRIPTION,
          query,
          variables,
          { ...defaultApiOptions, ...options }
        );
      }),
      fetch: flow(function* (url, params, options = {}) {
        return yield fetchRequest(url, params, {
          ...defaultApiOptions,
          isCritical: false,
          ...options
        });
      }),
      poll: (fetchRequest, validate, interval = 1000, maxAttempts = 15) => {
        let attempts = 0;

        const executePoll = async (resolve, reject) => {
          try {
            const result = await fetchRequest();

            attempts++;

            if (validate(result)) {
              return resolve(result);
            } else if (attempts === maxAttempts) {
              return reject('max attempts exceeded');
            } else {
              _timeout = setTimeout(executePoll, interval, resolve, reject);
            }
          } catch (e) {
            return reject(e);
          }
        };

        return new Promise(executePoll);
      },
      applyWithApiStatus: snapshot => {
        applySnapshot(self, {
          ...getSnapshot(self),
          dataApplied: true,
          ...snapshot
        });
      },
      applyError: (errorReport, e = {}) => {
        applySnapshot(self, {
          ...getSnapshot(self),
          ...{
            hasApiError: true,
            apiErrorType: e,
            isCriticalError: _isCritical,
            errorReport
          }
        });
      },
      clearTimeOut: () => {
        clearTimeout(_timeout);
      }
    };
  });

export default GraphQlModel;
