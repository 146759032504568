import { types, flow } from 'mobx-state-tree';
import ApiModel from './base/ApiModel';
import { GET_TRANSLATION_FILES } from '../graphql/queries';
import { SET_LANGUAGE } from '../graphql/mutations';
import { translationsInterface } from '../utils/i18next';
import { getLanguageFromCode } from '../utils/LanguageCode';
import LocalStorageUtil from '../utils/LocalStorage';
import { merge } from 'lodash';
import { newRelicAgent } from '../logging/new-relic-agent';
import JsErrorReporter from './JsErrorReporter';

const getUserLanguage = () =>
  LocalStorageUtil.get('userLanguage') ||
  getLanguageFromCode(window.navigator.language.toLowerCase());

const TranslationFile = types.model('TranslationFile', {
  language: types.string,
  links: types.array(types.string)
});

const fallbackTranslations = {
  notFound: {
    text: 'Oops. Looks like something went wrong.<br/>We are working to resolve this.',
    cta: 'Return to Login'
  },
  account: {
    help: 'Help',
    sign_out: 'Log Out',
    feedback: 'Feedback'
  }
};

const TranslationsModel = types
  .model('TranslationsModel', {
    currentLanguage: types.maybeNull(types.string),
    currentProvider: types.maybeNull(types.string),
    currentCompany: types.maybeNull(types.string),
    translationFiles: types.optional(types.array(TranslationFile), []),
    translationsLoaded: types.optional(types.boolean, false),
    jsErrorReporter: types.optional(JsErrorReporter, {})
  })
  .views(self => {
    let _availableTranslations;
    return {
      get availableTranslations() {
        return _availableTranslations;
      },
      set availableTranslations(val) {
        _availableTranslations = val;
      },
      get translationsAsList() {
        return self.translationFiles.reduce((acc, curr) => {
          acc.push(curr.language);
          return acc;
        }, []);
      }
    };
  })
  .actions(self => ({
    afterCreate: () => {
      translationsInterface.init();
    },
    report_error: (message, err, clientActions) => {
      self.jsErrorReporter.sendReport({
        message: `${message} ${err}`,
        stack: '',
        clientActions: clientActions
      });
      newRelicAgent.noticeError(err);
      self.hasApiError = true;
      self.isCriticalError = true;
      self.apiErrorType = `${message} ${err}`;
    },

    loadInitialTranslation: flow(function* (provider, currentLanguage) {
      let lng;
      self.currentProvider = provider.internalName;
      self.currentCompany = provider.companyInternalName;
      //get available translations
      try {
        yield self.loadAvailableTranslations();
      } catch (e) {
        //no translations, load needed sources for error page
        console.error('loading translations has failed!', e);
        yield self.setFallbackTranslations();
        return;
      }

      //initial language priority: currentLanguage param > session > localStorage > browser language
      if (currentLanguage) {
        lng = currentLanguage;
      } else if (self.currentLanguage && self.currentLanguage !== '') {
        lng = self.currentLanguage;
      } else {
        lng = getUserLanguage();
      }

      //convert [{language: "en", links: ['https://www.path.com', 'https://www.path2.com']}] to {en:['https://www.path.com', 'https://www.path2.com']}
      self.availableTranslations = self.translationFiles.reduce((acc, curr) => {
        acc[curr.language] = curr.links;
        return acc;
      }, {});
      try {
        if (lng !== 'en') {
          //load English as fallback language if translation in missing
          yield self.loadTranslationFiles('en');
        }

        //load translation file from S3
        yield self.loadTranslationFiles(lng);
      } catch (e) {
        //no translations, load needed sources for error page
        console.error('loading translations has failed!', e);
        yield self.setFallbackTranslations();
        return;
      }
      self.setTranslationsLoaded();
      return Promise.resolve();
    }),
    getTranslationInfo: flow(function* () {
      const { translationInfo } = yield self.query(GET_TRANSLATION_FILES, {
        provider: self.currentProvider
      });
      if (!translationInfo.currentCompany) {
        // company is not available before a session is established
        translationInfo.currentCompany = self.currentCompany;
      }
      return Promise.resolve(translationInfo);
    }),
    loadAvailableTranslations: flow(function* () {
      self.dataApplied = false;

      const res = yield self.getTranslationInfo().catch(e => {
        console.error('---Translations error', e);
        return Promise.reject(e);
      });

      self.applyWithApiStatus(res);
      return Promise.resolve();
    }),
    loadTranslationFiles: flow(function* (language) {
      if (!translationsInterface.hasResource(language)) {
        //translations for this language does not exist, fetch it
        try {
          if (!self.availableTranslations[language]) {
            console.error(
              `language ${language} not found in translations!!! falling back to ${self.translationFiles[0].language}`
            );
            language = self.translationFiles[0].language;
          }

          return new Promise((resolve, reject) => {
            const urls = self.availableTranslations[language];
            const promises = urls.map(url =>
              self.fetch(url, null, true).then(res => res[language])
            );
            Promise.all(promises)
              .then(results => {
                console.log(self.currentCompany);
                translationsInterface.addResource({
                  id: language,
                  resources: merge(
                    results[0].resources,
                    results[1].resources[self.currentProvider],
                    results[1].resources[self.currentCompany]
                  )
                });
                resolve(self.changeLanguage(language));
              })
              .catch(err => {
                self.report_error(
                  'Failed in tranalsations',
                  err,
                  'loadTranslationFiles'
                );
                reject(err);
              });
          });
        } catch (e) {
          console.error('---Translations error', e);
          yield self.setFallbackTranslations();
          return Promise.reject();
        }
      } else {
        return yield self.changeLanguage(language);
      }
    }),
    changeLanguage: flow(function* (lng) {
      self.currentLanguage = yield translationsInterface.changeLanguage(lng);
      LocalStorageUtil.set('userLanguage', lng);
      self.translationsLoaded &&
        self.mutate(SET_LANGUAGE, {
          language: lng,
          provider: self.currentProvider
        }); //do not wait for response
      return Promise.resolve();
    }),
    setDefaultValues: values => {
      translationsInterface.setDefaultValues(values);
    },
    setTranslationsLoaded: () => (self.translationsLoaded = true),
    setFallbackTranslations: flow(function* () {
      translationsInterface.addResource({
        id: 'en',
        resources: fallbackTranslations
      });
    }),
    hasTranslation: key => translationsInterface.exists(key)
  }));

export default types.compose(ApiModel, TranslationsModel);
