import { types } from 'mobx-state-tree';
import mixpanel from 'mixpanel-browser';
import moment from 'moment/moment';
import { isEqual } from 'lodash';

import { eventNames } from '../constants/mixpanel';

const isDevEnv = process.env.NODE_ENV === 'development';
const prodToken = '5d49658fc0e0e107d198900d44aabdc8';
const devToken = 'f109addcf2426a27dd882a782f5f75c1';
const stagingToken = '10097729984681ddce66b83404cd11f7';

let _initialTime,
  _wasInitialized,
  lastEvent = { eventName: null, selectionName: null, data: null };

const getElapsedTime = () => {
  const duration = moment.duration(moment().diff(_initialTime));
  const format = duration.hours() > 0 ? 'HH:mm:ss' : 'mm:ss';
  return moment.utc(duration.as('milliseconds')).format(format);
};
const addToTrackProps = data => {
  data = data || {};
  _initialTime && (data['Time Spent ON SimpleePay Page'] = getElapsedTime());
  return data;
};
const isEventIdentical = (eventName, data) =>
  eventName === lastEvent.eventName && isEqual(data, lastEvent.data);

const TrackingModel = types
  .model('TrackingModel', {})
  .views(self => ({
    get eventNamesValues() {
      return Object.values(eventNames);
    },
    isEventNameListed(eventName) {
      return self.eventNamesValues.includes(eventName);
    },
    get wasInitialized() {
      return _wasInitialized;
    }
  }))
  .actions(self => {
    const _trackedEvents = {};
    return {
      initialize: buildType => {
        let token;
        switch (buildType) {
          case 'production_env':
            token = prodToken;
            break;
          case 'development_env':
            token = devToken;
            break;
          default:
            token = stagingToken;
        }
        console.log('---mixpanel buildType:', buildType);
        mixpanel.init(token);
        _wasInitialized = true;
      },
      identify: id => {
        mixpanel.identify(id);
        self.notifyDevAction('identify', id);
      },
      register: props => {
        const propsWithVersion = { ...props, 'Patient Version': 'client' };
        mixpanel.register(propsWithVersion);
        self.notifyDevAction('register', propsWithVersion);
      },
      alias: id => {
        mixpanel.alias(id);
        self.notifyDevAction('alias', id);
      },
      setInitialTimer: () => {
        _initialTime = moment();
      },
      track: (eventName, data) => {
        if (!_wasInitialized) {
          console.log('TrackingModel was not initialized ');
          return;
        }
        if (isEventIdentical(eventName, data)) {
          console.log('preventing tracking of identical previous event');
          return;
        }
        if (!self.isEventNameListed(eventName)) {
          console.error(`event name: ${eventName} is not listed`);
          return;
        }

        lastEvent = { eventName, data: { ...data } };
        const newData = addToTrackProps(data);
        mixpanel.track(eventName, newData);
        self.notifyDevAction(eventName, newData);
        //save tracking for later use
        data &&
          Object.keys(data).forEach(key => (_trackedEvents[key] = data[key]));
      },
      getExistingEntries: entries =>
        entries.reduce((acc, curr) => {
          if (_trackedEvents[curr]) {
            acc[curr] = _trackedEvents[curr];
          }
          return acc;
        }, {}),
      notifyDevAction: (name, props) => {
        isDevEnv &&
          console.log(
            '%clogging mixpanel event:',
            'color: green',
            name,
            `${props ? `with props:'${JSON.stringify(props)}` : ''}`
          );
      }
    };
  });

export default TrackingModel;
