import {
  applySnapshot,
  detach,
  flow,
  getSnapshot,
  types
} from 'mobx-state-tree';

import ApiModel from './base/ApiModel';
import Payable from './Payable';
import AffiliatePayable from './AffiliatePayable';
import { GET_PAYABLES } from '../graphql/queries';
import { SET_PAYABLE } from '../graphql/mutations';

const PayablesModel = types
  .model('PayablesModel', {
    name: 'PayablesModel',
    hasGrouping: types.maybeNull(types.boolean),
    filterZeroDollarPayables: types.optional(types.boolean, true),
    payables: types.optional(types.array(Payable), []),
    affiliatePayables: types.optional(types.array(AffiliatePayable), [])
  })
  .views(self => ({
    get haveMultiplePayablesToShow() {
      const moreThenOnePayable = self.hasGrouping && self.payables.length > 1;
      return moreThenOnePayable || self.hasAffiliates;
    },
    get hasAffiliates() {
      return !!self.affiliatePayables.length;
    }
  }))
  .actions(self => {
    let _initialState = {};
    const _getPayable = id => self.payables.find(payable => payable.id === id);
    return {
      afterCreate: () => {
        _initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self, _initialState);
        self.currentPayable = null;
      },
      load: flow(function* (
        filterClosedBills = true,
        withAppointments = false
      ) {
        try {
          self.dataApplied = false;
          let { payables } = yield self.query(GET_PAYABLES, {
            filterClosedBills,
            withAppointments
          });
          self.filterZeroDollarPayables = payables.filterZeroDollarPayables;
          //load() called for second time
          self.currentPayable && detach(self.currentPayable);

          //filter payables with standingAmount of 0;
          //allow multiple payables only if hasGrouping = true
          //do not filter currentPayable - if user has payed amount in this session - let him see his balance is 0
          const filterPayables = () => {
            let filteredPayables = payables.payables;

            if (self.filterZeroDollarPayables) {
              filteredPayables = filteredPayables.filter(
                payable =>
                  payable.standingAmount > 0 ||
                  (payable.standingAmount === 0 &&
                    self.currentPayable?.id === payable.id)
              );
            }
            const outArr = filteredPayables.length
              ? filteredPayables
              : payables.payables;
            // allow multiple payables only if hasGrouping = true
            return payables.hasGrouping ? outArr : [outArr[0]];
          };

          const filterAffiliatePayables = () => {
            const filtered = payables.affiliatePayables?.filter(
              afPayable => afPayable.standingAmount > 0
            );
            //allow multiple payables only if hasGrouping = true
            return filtered;
          };

          const payablesToShow = filterPayables();
          const affiliatePayablesToShow = filterAffiliatePayables();
          self.applyWithApiStatus({
            hasGrouping: payables.hasGrouping,
            payables: payablesToShow,
            affiliatePayables: affiliatePayablesToShow
          });
        } catch (e) {
          console.log('Payable load error:', e);
          return Promise.reject();
        }
        return Promise.resolve();
      }),
      reload: flow(function* (
        payableCb,
        filterClosedBills = true,
        withAppointments = false
      ) {
        yield self.load(filterClosedBills, withAppointments);
        const payable = _getPayable(self.currentPayable.id);
        payableCb(payable);
        self.currentPayable = payable;
        self.currentPayable.setSelectAllBills(true);
      }),
      setPayable: flow(function* (id) {
        const payableId = id || self.payables[0].id;
        const payable = _getPayable(payableId);
        //set payable within payables list and notify server
        const notifyServerOnSelctedPayablesFromList =
          id || self.hasGrouping || self.hasAffiliates;
        if (notifyServerOnSelctedPayablesFromList) {
          try {
            const { setSessionPayable } = yield self.mutate(SET_PAYABLE, {
              payableId: payableId,
              provider: payable.payee.provider.internalName
            });
            if (setSessionPayable) {
              self.currentPayable = payable;
              return Promise.resolve(true);
            } else {
              //setSessionPayable is not true, simulate API error
              self.applyError(
                {
                  query: 'setSessionPayable',
                  errorMessage: 'setSessionPayable attempt returned false'
                },
                { message: 'Mutation error' }
              );
              return Promise.reject();
            }
          } catch (e) {
            console.log('Payable mutation error:', e);
            return Promise.reject();
          }
        } else {
          //default to first (and only) payable, no need to notify server
          self.currentPayable = payable;
          return Promise.resolve(false);
        }
      })
    };
  })
  .volatile(self => ({
    currentPayable: null
  }));

export default types.compose(ApiModel, PayablesModel);
