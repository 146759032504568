import { flow, types } from 'mobx-state-tree';

import ApiModel from './base/ApiModel';
import { GET_PAST_STATEMENTS, GET_STATEMENT_FILE } from '../graphql/queries';
import SessionParams from '../utils/SessionParams';

const PastStatementInfo = types.model('PastStatementInfo', {
  id: types.string,
  createdAt: types.string,
  balanceSnapshot: types.number,
  statementUniqueId: types.maybeNull(types.string),
  paperUploadedAt: types.maybeNull(types.string)
});

const PastStatementContent = types.model('PastStatementContent', {
  imageFormat: types.maybeNull(types.string),
  preview: types.string
});

const PastStatements = types
  .model('CommunicationLogs', {
    pastStatements: types.maybe(types.array(PastStatementInfo))
  })
  .actions(self => ({
    load: flow(function* () {
      console.log('loading past statements...');
      try {
        self.dataApplied = false;
        const { pastStatementsInfo } = yield self.query(GET_PAST_STATEMENTS, {
          payableIds: [SessionParams.payableId],
          accountNumber: SessionParams.accountNumber
        });
        self.applyWithApiStatus({ pastStatements: pastStatementsInfo });
        return Promise.resolve();
      } catch (e) {
        console.error('PastStatements Model error', e);
      }
    }),
    getStatementFile: flow(function* (statementId) {
      console.log('loading getStatementFile...', statementId);
      try {
        const res = yield self.query(
          GET_STATEMENT_FILE,
          {
            statementId: statementId
          },
          { isCritical: false }
        );

        if (res && res.pastStatementsContent) {
          const statementContent = PastStatementContent.create(
            res.pastStatementsContent
          );
          return Promise.resolve(statementContent);
        }
        return Promise.reject(
          'getStatementFile: pastStatementsContent is null'
        );
      } catch (e) {
        console.error('PastStatements Model error', e);
        return Promise.reject(e);
      }
    })
  }))
  .views(self => ({
    //filter statements which have statementUniqueId and paperUploadedAt
    get uploadedStatements() {
      return !self.pastStatements
        ? null
        : self.pastStatements.filter(
            ({ statementUniqueId, paperUploadedAt }) =>
              statementUniqueId && paperUploadedAt
          );
    }
  }));

export default types.compose(ApiModel, PastStatements);
