import { types } from 'mobx-state-tree';
import { Appointment } from './Appointment';
import ApiModel from './base/ApiModel';
import moment from 'moment';

export const Appointments = types
  .model('Appointments', {
    appointments: types.optional(types.array(Appointment), []),
    isAppointmentsSelectable: types.optional(types.boolean, false)
  })
  .views(self => ({
    get appointmentsGroup() {
      const bills = self.appointments.map(
        ({
          visitId,
          remainingBalance,
          nextUpcomingAppearance,
          displayName,
          hasPaymentPlan,
          selectable,
          setSelected,
          ...args
        }) => ({
          ...args,
          id: visitId,
          displayName,
          amountDue: remainingBalance,
          admissionDate: nextUpcomingAppearance,
          isBillPastDue: false,
          paymentPlan: hasPaymentPlan,
          selectable: self.isAppointmentsSelectable && selectable,
          setSelected,
          type: 'appointment'
        })
      );
      return self.hasAppointments
        ? [{ type: 'Appointments', bills: self.appointments }]
        : [];
    },
    get hasAppointments() {
      return !!self.appointments.length;
    },
    get totalAppointments() {
      return self.appointments.length;
    },
    get selectedAppointments() {
      return self.appointments.filter(appointment => appointment.isSelected);
    },
    get appointmentsOnPlan() {
      return self.appointments.filter(
        appointment => appointment.hasPaymentPlan
      );
    },
    get appointmentsTotalBalance() {
      return self.appointments.reduce(
        (totalBalance, { remainingBalance }) => totalBalance + remainingBalance,
        0
      );
    },
    get appointmentsTotalNextPayment() {
      return self.appointmentsOnPlan.reduce(
        (amount, { nextPayment }) => amount + nextPayment,
        0
      );
    },
    get appointmentsAmountOutOfPlan() {
      return self.appointments.reduce(
        (totalBalance, { remainingBalance, hasPaymentPlan }) =>
          hasPaymentPlan ? totalBalance : totalBalance + remainingBalance,
        0
      );
    },
    get appointmentsEstimatedAmountDue() {
      return (
        self.appointmentsTotalNextPayment + self.appointmentsAmountOutOfPlan
      );
    },
    get appointmentsPlanAmount() {
      return self.appointmentsOnPlan.reduce(
        (totalBalance, { remainingBalance }) => totalBalance + remainingBalance,
        0
      );
    },
    get selectedAppointmentsRemainingBalance() {
      return self.selectedAppointments.reduce(
        (selectedRemainingBalance, { remainingBalance }) =>
          selectedRemainingBalance + remainingBalance,
        0
      );
    },
    get selectedAppointmentsIds() {
      return self.selectedAppointments.map(appointment => appointment.id);
    },
    get selectedAppointmentsVisitIds() {
      return self.selectedAppointments.map(appointment => appointment.visitId);
    },
    get appointmentsVisitIdsOnPlan() {
      return self.appointmentsOnPlan.map(appointment => appointment.visitId);
    },
    get singleAppointment() {
      return id => self.appointments.find(appointment => appointment.id === id);
    },
    get appointmentsPaymentParams() {
      return (fullAmount, selectedAmount) => {
        const amountToPayTowardsAppointments = Math.max(
          0,
          selectedAmount +
            self.selectedAppointmentsRemainingBalance -
            fullAmount
        );
        const appointmentsToPayFor = [
          ...(amountToPayTowardsAppointments ? self.selectedAppointments : []),
          ...self.appointmentsOnPlan
        ];
        if (!appointmentsToPayFor.length) return {};
        const mappedAppointmentsToPayFor = appointmentsToPayFor.map(
          appointment => ({
            id: appointment.id,
            balance: appointment.remainingBalance,
            date: moment(appointment.earliestDate).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            hasPaymentPlan: appointment.hasPaymentPlan
          })
        );
        return {
          totalPaymentAmount: selectedAmount,
          appointmentsPaymentsAttributes: {
            amount: amountToPayTowardsAppointments,
            applyToAppointments: mappedAppointmentsToPayFor
          }
        };
      };
    },
    get appointmentsPlanParams() {
      return planBalance => {
        const totalAppointmentsBalance =
          self.selectedAppointmentsRemainingBalance +
          self.appointmentsPlanAmount;
        return {
          visitIds: [
            ...self.appointmentsVisitIdsOnPlan,
            ...self.selectedAppointmentsVisitIds
          ],
          totalBillsBalance: planBalance - totalAppointmentsBalance,
          totalAppointmentsBalance
        };
      };
    }
  }))
  .actions(self => ({
    initAppointments: isSelectable => {
      self.isAppointmentsSelectable = isSelectable;
      self.appointments.forEach(appointment => {
        appointment.selectable = isSelectable && !appointment.hasPaymentPlan;
        appointment.isSelected = appointment.selectable;
        appointment.providerDisplayName = self.payee.companyDisplayName;
      });
    },
    selectAllAppointments: isSelected => {
      return self.appointments.forEach(
        appointment =>
          (appointment.isSelected = appointment.selectable && isSelected)
      );
    }
  }));

export default Appointments;
