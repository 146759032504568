import { paymentOptionTypes } from '../constants/paymentOptions';
import { paymentTypes } from '../constants/mixpanel';
import { otherPaymentOptions } from '../constants/fullOffers';

const {
  PAYMENT,
  ADD_OUTSIDE_BALANCE,
  INSIDE_PP,
  OUTSIDE_PP,
  FINANCING_PP,
  INSIDE_FINANCING_PP,
  OUTSIDE_FINANCING_PP
} = paymentTypes;

const { CUSTOM_PAYMENT_PLAN } = otherPaymentOptions;

const {
  FULL_PAYMENT,
  PARTIAL_PAYMENT,
  PAYMENT_PLAN,
  QUICK_ACTION,
  NEXT_INSTALLMENT
} = paymentOptionTypes;

const isOfferValid = (ppOffers, offerName) => {
  return !!ppOffers[`${offerName}Offer`] && ppOffers[`${offerName}Offer`] >= 3;
};

const getPPOption = (ppOffers, offerName) => ({
  fullAmount:
    ppOffers[`${offerName}OfferFullAmount`] ||
    ppOffers[`${offerName}OfferTotalBalanceIncludingFees`],
  paymentType: PAYMENT_PLAN,
  payments: ppOffers[`${offerName}Offer`],
  amountPerInstallment:
    ppOffers[`${offerName}OfferAmountPerPayment`] ||
    ppOffers[`${offerName}OfferTotalAmountPerInstallment`],
  offerType: ppOffers[`${offerName}OfferType`],
  ...(ppOffers[`${offerName}OfferPzId`] && {
    payzenId: ppOffers[`${offerName}OfferPzId`]
  }),
  defaultSelection: ppOffers.defaultOption
    ? ppOffers[`${offerName}Offer`] === ppOffers.defaultOption
    : offerName === 'recommended'
});
const getPPOfferOptions = ppOffers =>
  ['short', 'recommended', 'long']
    .filter(offerName => isOfferValid(ppOffers, offerName))
    .map(offerName => getPPOption(ppOffers, offerName));

const getPaymentOption = (
  paymentType,
  fullAmount,
  defaultSelection = false
) => ({
  paymentType,
  fullAmount,
  defaultSelection
});

const buildPaymentOptions = ({
  paymentType,
  amount,
  hasPaymentPlan,
  fullOffers,
  planRemainingAmount,
  nextStandingAmount,
  outOfPlanAmount
}) => {
  const allOptions = {
    [QUICK_ACTION]: () => [
      [
        ...getPPOfferOptions(fullOffers),
        getPaymentOption(
          FULL_PAYMENT,
          hasPaymentPlan ? outOfPlanAmount : amount,
          getPPOfferOptions(fullOffers).length === 0
        ),
        getPaymentOption(
          PARTIAL_PAYMENT,
          hasPaymentPlan ? outOfPlanAmount : amount
        )
      ]
    ],
    [INSIDE_PP]: () => [
      [
        getPaymentOption(NEXT_INSTALLMENT, nextStandingAmount, true),
        getPaymentOption(PARTIAL_PAYMENT, planRemainingAmount)
      ]
    ],
    [OUTSIDE_PP]: () => [
      [
        getPaymentOption(FULL_PAYMENT, outOfPlanAmount, true),
        getPaymentOption(PARTIAL_PAYMENT, outOfPlanAmount)
      ]
    ],
    [FINANCING_PP]: () => [getPPOfferOptions(fullOffers)],
    [INSIDE_FINANCING_PP]: () => [
      [
        getPaymentOption(NEXT_INSTALLMENT, nextStandingAmount, true),
        getPaymentOption(PARTIAL_PAYMENT, planRemainingAmount)
      ]
    ],
    [OUTSIDE_FINANCING_PP]: () => [
      [
        getPaymentOption(FULL_PAYMENT, outOfPlanAmount, true),
        getPaymentOption(PARTIAL_PAYMENT, outOfPlanAmount)
      ]
    ],
    [ADD_OUTSIDE_BALANCE]: () => [getPPOfferOptions(fullOffers)],
    [PAYMENT]: () => [
      [
        getPaymentOption(FULL_PAYMENT, amount, true),
        getPaymentOption(PARTIAL_PAYMENT, amount)
      ]
    ]
  };
  return allOptions[paymentType]();
};

export { buildPaymentOptions };
