export const eventNames = Object.freeze({
  LOGIN: 'Log in',
  LOGIN_SUCCESS: 'Log in Success',
  AUTHENTICATION_SUCCESS: 'Authentication Success',
  FAILED_LOGIN: 'Failed Login',
  LOGIN_BOUNCE: 'Login Bounce',
  TERMS_SELECTION: 'Terms Selection',
  PAYMENT_START: 'Payment Start',
  VIEW_BILL: 'View Bill',
  RIGHT_RAIL_SELECTION: 'Right Rail Selection',
  HEADER_SELECTION: 'Header Selection',
  ERROR: 'Error',
  PAYMENT_SELECTION: 'Selection',
  PAYMENT_INFO_SUCCESS: 'Payment Info Success',
  PAYMENT_SUCCESS: 'Payment',
  PAYMENT_PLAN_SUCCESS: 'Payment Plan Confirmation',
  PAYMENT_FAILURE: 'Payment Failure',
  LAUNCH_DIGITAL_BUY: 'Launch Digital Buy',
  CLOSE_DIGITAL_BUY: 'Close Digital Buy',
  QUICK_ACTION_PAGE: 'Quick Action Page',
  MYCHART_LOGIN_KNOWN_ERRORS: [
    'wrong_sign_in_params',
    'request_timeout',
    'Hash is invalid'
  ],
  PAYABLE_PICKER: 'Payable Picker',
  STATEMENT_HELP_CLICK: 'Statement Help Click',
  STATEMENT_HELP_HOVER: 'Statement Help Hover',
  CURAE_APPLICATION: 'Curae Application',
  LOOKUP_ACCOUNT: 'Lookup Account',
  CURAE_URL: 'Redirected to Curae',
  CURAE_LOOKUP_STATUS: 'Lookup Status',
  NEED_HELP_LOGIN_CLICK: 'Need help clicked',
  ACCOUNT_LOOKUP_START: 'Account Lookup Start',
  ACCOUNT_LOOKUP_SEARCH: 'Account Lookup Search',
  ACCOUNT_LOOKUP_VERIFICATION_SUCCESS: 'Account Lookup Verification Success',
  ACCOUNT_LOOKUP_VERIFICATION_FAILED: 'Account Lookup Verification Failed',
  ACCOUNT_LOOKUP_RESEND_VERIFICATION_CODE:
    'Account Lookup Resend Verification Code',
  ACCOUNT_LOOKUP_ALTERNATIVE_VERIFICATION_METHOD:
    'Account Lookup Alternative Verification Code',
  ACCOUNT_LOOKUP_ABANDON_FIND_ACCOUNT: 'Account Lookup Abandon Find Account',
  ACCOUNT_LOOKUP_ABANDON_VERIFY_CODE: 'Account Lookup Abandon Verify Code',
  UNDERWRITING_START: 'Underwriting Start',
  UNDERWRITING_COMPLETE: 'Underwriting Complete',
  UNDERWRITING_FAIL: 'Underwriting Fail',
  REMEMBER_ME_SELECTION: 'Remember Me Selection',
  BILLS_PAGE_VIEW_CLICK: 'Bills Page View Clicked'
});

export const baseProperties = Object.freeze({
  IS_BOT: 'isBot',
  IS_BOT_NAME: 'isBot Name',
  PLATFORM: 'Platform',
  SOURCE: 'Source',
  PROVIDER: 'Provider',
  FACILITY: 'Facility',
  LANGUAGE: 'Language',
  REVENUE_MODEL: 'Revenue Model'
});

export const loginNeedHelpProperties = Object.freeze({
  LOGIN_FAILED: 'Failed'
});

export const authSuccessProperties = Object.freeze({
  AUTHENTICATION_FIELDS: 'Authentication Fields',
  PAYABLE_ID: 'Payable ID',
  NUMBER_OF_BILLS_OPEN: 'Number of Bills Open',
  REMAINING_BALANCE: 'Remaining Balance',
  PAYMENT_PAST_DUE: 'Payment Past Due',
  BILL_GOING_TO_COLLECTIONS: 'Bill Going to Collections',
  SELECT_BILLS: 'Select Bills',
  HAS_AFFILIATES: 'Has Affiliates',
  PAYMENT_PLAN_TYPE: 'Payment Plan Type',
  PAYMENT_PLAN_STATUS: 'Payment Plan Status',
  BALANCE_ON_PAYMENT_PLAN: 'Balance on Payment Plan',
  BALANCE_NOT_ON_PAYMENT_PLAN: 'Balance not on Payment Plan',
  BILLS_ON_PAYMENT_PLAN: 'Number of Bills on Payment Plan',
  MISSED_PAYMENTS: 'Number of Missed Payments',
  ASSIST_OFFER: '[Assist] Offer',
  GUARANTOR_SEGMENT: '[Assist] Guarantor segment',
  ASSIST_CAPACITY: '[Assist] Guarantor capacity',
  DUNNING_LEVELS: 'Dunning Levels',
  DISCOUNT_TOTAL_AMOUNT: 'Discount - Total Amount',
  PATIENT_TYPE: 'Patient Type',
  PROMPT_PAY_DISCOUNT_PRECENTAGE: 'Discount - Prompt Pay',
  RELIEF_DISCOUNT_AMOUNT: 'Discounts Applied - Relief',
  PAYZEN_ON: 'PayZen On',
  PAYMENT_PLAN_INSTALLMENT_DUE_DATE: 'Installment Due Date'
});

export const propertyNames = Object.freeze({
  //Right Rail Selection
  SELECTION_NAME: 'Selection Name',
  //View Bill
  BILL_VIEW_FORMAT: 'Bill View Format', // LIST/TILE
  SELECTED_BILL_AMOUNT: 'Selected Bill Amount',
  BILL_AMOUNT_RANK: 'Bill Amount Rank',
  DATE_OF_SERVICE_RANK: 'Date Of Service Rank'
});

export const loginPropertyNames = Object.freeze({
  REMEMBER_ME: 'Remember me',
  IS_ACCOUNT_LOOKUP: 'Is AccountLookup',
  NEED_LAST_NAME: 'need_last_name',
  WRONG_LAST_NAME: 'wrong_last_name'
});

export const headerPropertyValues = Object.freeze({
  MENU_MOBILE: 'Menu (Mobile)',
  LOGO_CLICK: 'Logo',
  MESSAGE_CENTER: 'Message Center',
  HELP: 'Help',
  LOG_OUT: 'Logout'
});

export const rightRailPropertyValues = Object.freeze({
  MANAGE_MY_NOTIFICATIONS: 'Manage My Notification',
  REQUEST_STATEMENT: 'Request Detailed Statement',
  UPDATE_INFORMATION: 'Update Contact Information',
  PAYMENT_SUMMARY: 'View Payment Summary',
  PAYMENT_PLAN_SUMMARY: 'View Payment Plan Summary',
  MANAGE_PAYMENT_PLAN: 'Manage Payment Plan',
  VIEW_STATEMENTS: 'View Statements',
  //Right rail sections
  CONTACT_US: 'Contact Us',
  FEEDBACK: 'Feedback',
  FINANCIAL_ASSISTANCE: 'Learn More - Financial Assistance',
  CUSTOM_MESSAGE: 'Learn More - Custom Right Rail Language',
  COMPANY_LINK: 'Company Link'
});
export const propertyValues = Object.freeze({
  LANGUAGE_SELECT: 'Language (open dropdown)',
  //view bill
  BILL_FORMAT_TILE: 'Tile',
  BILL_FORMAT_LIST: 'List',
  //error
  QUERY: 'query',
  ERROR_MESSAGE: 'error message'
});

//these values are translated in the Payment Angular App. Once this app covers the payment flow as well, use final string value and not translation keys;
export const paymentTypesRedirect = Object.freeze({
  PAYMENT: 'payment_button',
  ONE_CLICK_PAYMENT: 'one_click_payment',
  PAY_FULL_PLAN: 'payment_full_plan',
  ASSIST_PP: 'assist_pp',
  PP_CONVERT: 'convert_pp',
  PP_BRING_CURRENT: 'bring_pp_good_standing',
  ADD_OUTSIDE_BALANCE: 'add_outside_balance'
});

export const billStatusTypesRedirect = Object.freeze({
  BEFORE_COLLECTION: 'before_collection',
  PAST_DUE: 'past_due',
  MISSED_PAYMENT: 'missed_payment',
  BALANCE_OUTSIDE_PP: 'balance_outside_pp'
});

export const paymentStartPropertyNamesRedirect = {
  //Payment Start
  NUMBER_OF_BILLS_OPEN: 'number_of_bills_open',
  REMAINING_BALANCE: 'remaining_balance',
  PAYMENT_PAST_DUE: 'payment_past_due',
  BILL_GOING_TO_COLLECTIONS: 'bill_going_to_collections',
  SELECT_BILLS: 'select_bills',
  PAYMENT_PLAN_TYPE: 'payment_plan_type',
  PAYMENT_PLAN_STATUS: 'payment_plan_status',
  BALANCE_ON_PAYMENT_PLAN: 'amount_on_plan',
  BALANCE_NOT_ON_PAYMENT_PLAN: 'amount_not_on_plan',
  BILLS_ON_PAYMENT_PLAN: 'number_of_bills_on_payment_plan',
  MISSED_PAYMENTS: 'no_of_missed_payments',
  BILL_DETAILS_VIEWED: 'no_of_bills_viewed',
  ASSIST_OFFER: 'Assist Offer',
  GUARANTOR_SEGMENT: 'Assist Guarantor Segment',
  ASSIST_CAPACITY: 'Assist Capacity',
  PAYMENT_ENTRANCE: 'btn_source',
  MESSAGE_CENTER: 'message_center',
  MESSAGE_CENTER_BILL_STATUS: 'bill_status',

  //These props do not appear in Mixpanel spread sheet, but are being sent in payment link
  SELECTED_BILL_IDS: 'selected_bills',
  SELECTED_BILLS_AMOUNT: 'selected_amount',
  CONVERT_PLAN: 'convert_plan',
  BILL_IDS: 'bill_ids',
  PAYABLE_IDS: 'payable_ids',
  LANGUAGE: 'language'
};

export const termsSelectionPropertyNames = Object.freeze({
  DOCUMENT_SELECTION: 'Document Selection'
});

export const termsSelectionPropertyTypes = Object.freeze({
  TERMS_OF_SERVICE: 'Terms of Service',
  PRIVACY_POLICY: 'Privacy Policy',
  CONFIDENTIALITY_NOTICE: 'Confidentiality Notice',
  ELECTRONIC_CONSENT: 'Electronic Consent',
  COOKIES_POLICY: 'Cookies Consent'
});

/////////////////////////////////////////////////////////////////////
export const paymentStartPropertyNames = Object.freeze({
  //Payment Start
  BILLS_ON_PAYMENT_PLAN: 'Number of Bills on Payment Plan',
  BILL_DETAILS_VIEWED: 'Number of Bill Details Viewed',
  PAYMENT_ENTRANCE: 'Payment Entrance',
  MESSAGE_CENTER: 'Message Center',
  MESSAGE_CENTER_BILL_STATUS: 'Message Center Bill Status',
  CONVERT_PLAN: 'Convert Plan',
  UPDATE_PAYMENT_PLAN: 'Update Payment Plan'
});

export const paymentSelectionPropertyNames = Object.freeze({
  AMOUNT: 'Amount',
  AMOUNT_SELECTION: 'Amount Selection',
  PAYMENT_PLAN_LENGTH: 'Payment Plan Length Selection',
  PAYMENT_PLAN_AMOUNT: 'Payment Plan Amount Selection',
  ASSIST_CUSTOM_SELECTION: 'Assist Custom Selection',
  ASSIST_CUSTOM_FREQUENCY: 'Assist Custom Frequency'
});

export const amountSelectionTypes = Object.freeze({
  FULL_PAYMENT: 'Pay amount due now',
  PARTIAL_PAYMENT: 'Pay Another Amount',
  PAYMENT_PLAN: 'Create PP',
  CARE_CREDIT: 'Care Credit'
});

export const assistCustomFrequencyTypes = Object.freeze({
  WEEKLY: 'Weekly',
  BIMONTHLY: 'Twice a Month',
  MONTHLY: 'Monthly'
});

export const paymentInfoPropertyNames = Object.freeze({
  PAYMENT_TYPE: 'Payment Type', //Card, Account, Paypal
  CARD_SAVED: 'Card Saved', //true/false
  ECHECK_SAVED: 'Echeck Saved', //true/false
  SAVED_ECHECK_PAYMENT: 'Saved Echeck Payment' //true/false
});

export const paymentInfoType = Object.freeze({
  TYPE_CARD: 'Credit Card',
  TYPE_E_CHECK: 'Account',
  TYPE_PAYPAL: 'Paypal',
  TYPE_CURAE: 'Curae'
});

export const paymentTypes = Object.freeze({
  PAYMENT: 'Make a payment',
  ONE_CLICK_PAYMENT: '1 click payment',
  PAY_FULL_PLAN: 'Pay in Full',
  ASSIST_PP: 'Payment plan',
  PP_CONVERT: 'Convert payment plan',
  PP_UPDATE_PAYMENT_METHOD: 'Payment plan update payment method',
  NOTIFICATION_BANNER: 'Notification Banner',
  PP_BRING_CURRENT: 'Bring Plan Current',
  CATCH_UP_PROMPT: 'Catch-Up Prompt',
  NOTIFICATION_BANNER_CATCH_UP: 'Notification Banner - Catch Up',
  ADD_OUTSIDE_BALANCE: 'Add balance to payment plan',
  CARE_CREDIT: 'CareCredit',
  QUICK_ACTION_PAGE: 'Quick Action Page',
  FINANCING_PP: 'Financing Payment Plan',
  INSIDE_PP: 'Inside Payment Plan',
  OUTSIDE_PP: 'Outside Payment Plan',
  INSIDE_FINANCING_PP: 'Inside Financing Payment Plan',
  OUTSIDE_FINANCING_PP: 'Outside Financing Payment Plan'
});

export const paymentFailurePropertyNames = Object.freeze({
  FAILURE_REASON: 'Failure Reason'
});

export const paymentFailureTypes = Object.freeze({
  CARD_SERVER_ERROR: 'Card Server Error',
  CHECKING_SERVER_ERROR: 'Checking Server Error',
  PAYPAL_SERVER_ERROR: 'PayPal Server Error',
  BLANK: 'Blank',
  INVALID: 'Invalid'
});
export const paymentFailureReasonFields = Object.freeze({
  CARD_NUMBER: 'Card Number',
  CARD_YEAR: 'Card Year',
  CARD_CVV: 'Card CVV',
  CARD_NAME: 'Card Name',
  ZIP_CODE: 'Zip Code',
  ECHECK_NAME: 'eCheck Name',
  ECHECK_ACCOUNT_SELECTION: 'eCheck Account Type',
  ECHECK_ROUTING_NUMBER: 'eCheck Routing Number',
  ECHECK_ACCOUNT_NUMBER: 'eCheck Account Number'
});

export const billStatusTypes = Object.freeze({
  BEFORE_COLLECTION: 'Collections',
  PAST_DUE: 'Balance Overdue',
  MISSED_PAYMENT: 'Missed Payment',
  BALANCE_OUTSIDE_PP: 'Balance Outside Plan'
});

export const launchDigitalBuyPropertyNames = Object.freeze({
  ...paymentSelectionPropertyNames,
  SELECTED_CC_PROMO: 'CC Promo',
  CC_CARDHOLDER: 'CC Cardholder'
});
export const closeDigitalBuyPropertyNames = Object.freeze({
  ...launchDigitalBuyPropertyNames,
  CC_PAYMENT_OUTCOME: 'CC Payment Outcome'
});

export const quickActionPagePropertyNames = Object.freeze({
  QA_PAGE_MESSAGE: 'QA Page Message'
});
export const quickActionPageMessages = Object.freeze({
  BALANCE_DUE: 'Balance Due',
  VISIT_OUTSIDE_OF_PLAN: 'Visit Outside of Plan'
});
export const payzenPlanProperties = Object.freeze({
  PLAN_BALANCE: 'Plan Balance',
  ADDING_VISIT: 'Adding Visit'
});
