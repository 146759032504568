import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const GET_APPOINTMENTS = gql`
  query appointments($accountNumber: String!, $provider: String!) {
    appointments(accountNumber: $accountNumber, provider: $provider) ${CLIENT} {
      id
      visitId
      providerName
      amountPaid
      nextPayment
      remainingBalance
      originalBalance
      cptCodes
      hasPaymentPlan
      patientName
      appearances {
        id
        status
        appearanceDate
        active
      }
    }
  }
`;
