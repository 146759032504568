import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const IS_LOGGED_IN = gql`
  mutation verifyOrClearSession($provider: String!, $billId: ID) {
    verifyOrClearSession(provider: $provider, billId: $billId) ${CLIENT} {
      hasSession
      boUserEmail
    }
  }
`;

export const LOGIN_WITH_JWT = gql`
  mutation loginWithJwt($provider: String!, $token: String!) {
    loginWithJwt(loginInput: { provider: $provider, token: $token }) ${CLIENT}
  }
`;

export const LOGIN_FROM_MY_CHART = gql`
  mutation loginFromMyChart(
    $provider: String!
    $accountNumber: String
    $timestamp: String
    $hash: String
  ) {
    loginFromMyChart(
      provider: $provider
      accountNumber: $accountNumber
      timestamp: $timestamp
      hash: $hash
    ) ${CLIENT}
  }
`;

export const LOGIN = gql`
  mutation login(
    $provider: String!
    $acceptedTerms: Boolean!
    $dateOfBirth: String!
    $payableIds: [String!]
    $accountNumber: String
    $captchaKey: String
    $zip: String
    $ssn: String
    $lastName: String
  ) {
    login(
      loginInput: {
        provider: $provider
        acceptedTerms: $acceptedTerms
        dateOfBirth: $dateOfBirth
        payableIds: $payableIds
        accountNumber: $accountNumber
        captchaKey: $captchaKey
        zip: $zip
        ssn: $ssn
        lastName: $lastName
      }
    ) ${CLIENT}
  }
`;

export const LOGIN_DURATION_MONITOR = gql`
  mutation loginDurationMonitor(
    $provider: String!
    $company: String!
    $payableIds: [String!]!
    $browser: String
    $device: String
  ) {
    loginDurationMonitor(
      durationMonitorInput: {
        provider: $provider
        company: $company
        payableIds: $payableIds
        browser: $browser
        device: $device
      }
    ) ${CLIENT}
  }
`;

export const LOGOUT = gql`
  mutation logout{
    logout ${CLIENT}
  }
`;
