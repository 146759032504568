import { types } from 'mobx-state-tree';
import moment from 'moment';
import { paymentOptionTypes } from '../constants/paymentOptions';
import { paymentTypes } from '../constants/mixpanel';

const statuses = {
  GOOD_STANDING: 'good_standing',
  DELINQUENT: 'delinquent',
  CLOSED: 'closed',
  PENDING: 'pending',
  FROZEN: 'frozen',
  OFFBOARDING: 'offboarding'
};

const PaymentMethod = types.model('PaymentMethod', {
  expiredAt: types.maybeNull(types.string),
  id: types.string,
  cardType: types.maybeNull(types.string),
  last4: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string)
});

const Installment = types.model('Installment', {
  installmentDate: types.maybeNull(types.string),
  amountToPay: types.maybeNull(types.number),
  serviceFee: types.maybeNull(types.number),
  amountToPayWithFee: types.maybeNull(types.number)
});

const PaymentPlan = types
  .model('PaymentPlan', {
    id: types.string,
    balanceRemaining: types.number,
    planType: types.string,
    expectedAmount: types.number,
    standingAmount: types.number,
    amountOutOfPlan: types.number,
    nextStandingAmount: types.number,
    missedInstallmentsCount: types.number,
    missedInstallmentsAmount: types.number,
    billIds: types.maybeNull(types.array(types.string)),
    claimNumbers: types.maybeNull(types.array(types.string)),
    startDate: types.string,
    lastInstallmentDate: types.string,
    leftInstallmentsCount: types.number,
    fundedBy: types.maybeNull(types.string),
    genericIdentifier: types.maybeNull(types.string),
    nextInstallment: types.maybeNull(Installment),
    installmentsNumber: types.number,
    remainingInstallmentsTotal: types.number,
    amountPerInstallment: types.number,
    lastInstallmentAmount: types.number,
    totalPaidBalance: types.maybeNull(types.number),
    payableId: types.string,
    serviceFeeAmount: types.maybeNull(types.number),
    //
    status: types.refinement(types.string, value =>
      Object.values(statuses).includes(value)
    ),
    statuses: types.frozen(statuses),
    paymentMethod: types.maybeNull(PaymentMethod)
  })
  .views(self => ({
    get isDelinquent() {
      return self.status === statuses.DELINQUENT;
    },
    get isGoodStanding() {
      return self.status === statuses.GOOD_STANDING;
    },
    get isActive() {
      return [statuses.GOOD_STANDING, statuses.DELINQUENT].includes(
        self.status
      );
    }
  }))
  .actions(self => {
    const { PAYMENT_PLAN } = paymentOptionTypes;
    const { PP_CONVERT } = paymentTypes;
    return {
      getConvertPpPaymentOption: () => {
        const serverDateFormat = 'YYYY-MM-DD';
        const clientDateFormat = 'MM/DD/YYYY';
        return {
          amount: self.amountPerInstallment,
          fullAmount: self.balanceRemaining,
          paymentDateStart: moment(self.startDate).format(clientDateFormat),
          nextInstallmentDate: self.nextInstallment?.installmentDate
            ? moment(
                self.nextInstallment?.installmentDate,
                serverDateFormat
              ).format(clientDateFormat)
            : null,
          payments: self.installmentsNumber,
          lastPayment: self.lastInstallmentAmount,
          paymentOptionType: PAYMENT_PLAN,
          paymentPlanType: PP_CONVERT,
          serviceFee: self.serviceFeeAmount
        };
      }
    };
  });

export default PaymentPlan;
