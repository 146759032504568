import { types, flow } from 'mobx-state-tree';

import ApiModel from './base/ApiModel';
import { GET_PUBLIC_SETTINGS, GET_SETTINGS } from '../graphql/queries';
import { hasProp } from '../utils/Object';

const ppUpdateFromPatient = types.model('pp_update_from_patient', {
  disabled: types.boolean,
  configurations: types.frozen({
    update_payment_plan_method: types.boolean,
    update_payment_plan_email: types.boolean
  })
});

const defaultBillsSorting = {
  disabled: false,
  configurations: {
    admissionDate: { order: 'desc' }
  }
};

const GroupingHeader = types.model('GroupingHeader', {
  bill: types.string,
  type: types.string
});
const Grouping = types.model('Grouping', {
  disabled: types.boolean,
  configurations: types.frozen({
    type: types.string,
    group_field: types.string,
    group_static_headers: types.array(GroupingHeader),
    partner_group_headers: types.array(GroupingHeader)
  })
});

const DynamicStyle = types.model('DynamicStyle', {
  disabled: types.boolean,
  configurations: types.frozen({
    header_line_color: types.string
  })
});

const Logo = types.model('LogoSetting', {
  disabled: types.boolean,
  configurations: types.frozen({
    image: types.string
  })
});

const LanguagesSelect = types.model('LanguagesSelectSetting', {
  disabled: types.boolean,
  configurations: types.frozen({
    languages: types.optional(types.array(types.string), [])
  })
});

const PaymentMethodPermission = types.model('paymentMethodPermission', {
  disabled: types.boolean,
  configurations: types.frozen({
    payment_methods: types.optional(types.array(types.string), [])
  })
});

const Survey = types.model('Survey', {
  disabled: types.boolean,
  configurations: types.frozen({
    url: types.string
  })
});

const IvyChatView = types.model('IvyChatViewSetting', {
  disabled: types.boolean,
  configurations: types.frozen({
    ivy_key: types.string,
    pages_to_enable: types.array(types.string)
  })
});

const GenericSetting = types.model('GenericSetting', {
  disabled: types.boolean,
  configurations: types.frozen({})
});

const CustomMessage = types.model('CustomMessage', {
  disabled: types.boolean,
  configurations: types.frozen({
    translation_key: types.maybeNull(types.string),
    link: types.maybeNull(types.string)
  })
});

const EnablePaperless = types.model('EnablePaperlessSetting', {
  disabled: types.boolean,
  configurations: types.frozen({
    paperless_subscription: types.boolean
  })
});

const ChangeRequest = types.model('ChangeRequestSetting', {
  disabled: types.boolean,
  configurations: types.frozen({
    insurance_change: types.boolean,
    address_change: types.boolean
  })
});

const PaymentConfirmationImage = types.model(
  'PaymentConfirmationImageSetting',
  {
    disabled: types.boolean,
    configurations: types.frozen({
      image: types.maybeNull(types.string),
      image_alt_text: types.maybeNull(types.string),
      image_url: types.maybeNull(types.string)
    })
  }
);

const PaymentBillsAndHistory = types.model('PaymentBillsAndHistory', {
  disabled: types.boolean,
  configurations: types.frozen({
    show_payment_history: types.boolean,
    show_payment_history_link: types.boolean,
    show_past_bills: types.boolean
  })
});

const ScheduledPayment = types.model('ScheduledPayment', {
  disabled: types.boolean,
  configurations: types.frozen({
    type: types.array(types.string)
  })
});

const PaymentPlan = types.model('PaymentPlan', {
  disabled: types.boolean,
  configurations: types.frozen({
    echeck: types.maybeNull(types.boolean),
    verify_payment_plan_terms: types.maybeNull(types.boolean)
  })
});

const BillingQuestions = types.model('BillingQuestions', {
  disabled: types.boolean,
  configurations: types.frozen({
    hide_provider_name: types.boolean
  })
});

//These following settings are not actual settings from server and are temporary
const BillsSorting = types.model('BillsSorting', {
  disabled: types.boolean,
  configurations: types.frozen({
    admissionDate: types.frozen({ order: 'desc' })
  })
  //add other possible sorting keys here
});
//////////////////////////////////////////////////////////////////////

const ccPaymentOption = {
  enabled: types.boolean,
  promo_code: types.string,
  min_balance: types.number,
  interest_rate: types.number,
  monthly_payment_percent: types.number
};
const CCDeferedPaymentOption = types.model('CCPaymentOption', {
  deferred: types.number,
  ...ccPaymentOption
});
const CCFixedInterestPaymentOption = types.model('CCPaymentOption', {
  fixed_interest_epp: types.number,
  ...ccPaymentOption
});

const careCreditDigitalBuy = types.model('careCreditDigitalBuy', {
  disabled: types.boolean,
  configurations: types.frozen({
    less_than_200_dollar_payment_promo: types.string,
    care_credit_deferred_plan_options: types.optional(
      types.array(CCDeferedPaymentOption),
      []
    ),
    care_credit_fixed_interest_plan_options: types.optional(
      types.array(CCFixedInterestPaymentOption),
      []
    )
  })
});

const StatementImage = types.model('StatementImage', {
  disabled: types.boolean,
  configurations: types.frozen({
    image: types.string
  })
});
const SSOLoginRedirect = types.model('sso_login_redirection', {
  disabled: types.boolean,
  configurations: types.frozen({
    redirection_error_link: types.string
  })
});

const LoginFooterNotification = types.model('LoginFooterNotification', {
  disabled: types.boolean,
  configurations: types.frozen({
    header: types.string,
    content: types.string
  })
});

const CustomProviderHeader = types.model('CustomProviderHeader', {
  disabled: types.boolean,
  configurations: types.frozen({
    providers_display_name: types.string
  })
});

const RevenueModelType = types.model('RevenueModelType', {
  disabled: types.boolean,
  configurations: types.frozen({
    revenue_model_type: types.optional(types.array(types.string), [])
  })
});

const ThemeProperties = types.model('cerner_portal_theme_properties', {
  disabled: types.boolean,
  configurations: types.frozen({
    primary_font: types.string,
    button_color: types.string,
    header_font_size: types.string,
    button_font_size: types.string,
    font_weight: types.string,
    primary_font_color: types.string,
    button_hover_color: types.string,
    button_font_color: types.string
  })
});

const CareCreditLinks = types.model('care_credit_links', {
  disabled: types.boolean,
  configurations: types.frozen({
    jquery_ersion_url: types.string,
    care_credit_url: types.string
  })
});

const BeautifulBillChargeConfig = types.model('beautiful_bill_charge_config', {
  disabled: types.boolean,
  configurations: types.frozen({
    hide_charges_amount: types.boolean,
    hide_all_charges: types.boolean,
    show_solo_item_in_grouped_charges: types.boolean
  })
});

const AccountIdentifierToDisplay = types.model(
  'account_identifier_to_display',
  {
    disabled: types.boolean,
    configurations: types.frozen({
      identifier_to_display: types.string
    })
  }
);

const ShowInsuranceInfoInBillPage = types.model(
  'show_insurance_info_in_bill_page',
  {
    disabled: types.boolean,
    configurations: types.frozen({
      insurance_field: types.string
    })
  }
);

export const SettingsModel = types
  .model('SettingsModel', {
    fetchPolicy: types.optional(types.string, 'no-cache'),
    logo: types.maybeNull(Logo),
    languages_select: types.maybeNull(LanguagesSelect),
    assist_feature: types.maybeNull(GenericSetting),
    enable_billing_statements: types.maybeNull(GenericSetting),
    enable_paperless: types.maybeNull(EnablePaperless),
    change_requests: types.maybeNull(ChangeRequest),
    payments_and_bills_history: types.maybeNull(PaymentBillsAndHistory),
    bill_grouping: types.maybeNull(Grouping),
    dynamic_style: types.maybeNull(DynamicStyle),
    select_a_bill: types.maybeNull(GenericSetting),
    custom_right_rail_link: types.maybeNull(CustomMessage),
    save_credit_cards: types.maybeNull(GenericSetting),
    ivy_chat_view: types.maybeNull(IvyChatView),
    delta_payment_button: types.maybeNull(GenericSetting),
    new_patient_login_delta: types.maybeNull(GenericSetting),
    statement_image_desktop: types.maybeNull(StatementImage),
    statement_image_mobile: types.maybeNull(StatementImage),
    scheduled_payment: types.maybeNull(ScheduledPayment),
    payment_plan: types.maybeNull(PaymentPlan),
    payment_method_permission: types.maybeNull(PaymentMethodPermission),
    survey_monkey: types.maybeNull(Survey),
    delta_login_survey: types.maybeNull(Survey),
    custom_login_page_heading_text: types.maybeNull(CustomProviderHeader),
    payment_confirmation_page_image_and_url: types.maybeNull(
      PaymentConfirmationImage
    ),
    payment_plan_v4: types.maybeNull(GenericSetting),
    enable_digital_wallets: types.maybeNull(GenericSetting),
    //unsupported setting (yet), will always fallback to default
    bills_list_sort: types.optional(BillsSorting, defaultBillsSorting),
    sso_login_redirection: types.maybeNull(SSOLoginRedirect),
    care_credit_digital_buy: types.maybeNull(careCreditDigitalBuy),
    login_footer_notification: types.maybeNull(LoginFooterNotification),
    revenue_model_type: types.maybeNull(RevenueModelType),
    quick_action_page_experience: types.maybeNull(GenericSetting),
    cerner_portal_theme_properties: types.maybeNull(ThemeProperties),
    care_credit_links: types.maybeNull(CareCreditLinks),
    cookies_consent: types.maybeNull(GenericSetting),
    beautiful_bill_charge_config: types.maybeNull(BeautifulBillChargeConfig),
    maryland_medical_debt_protection_act: types.maybeNull(GenericSetting),
    account_identifier_to_display: types.maybeNull(AccountIdentifierToDisplay),
    curae: types.maybeNull(GenericSetting),
    pp_update_from_patient: types.maybeNull(ppUpdateFromPatient),
    legal_terms: types.maybeNull(GenericSetting),
    login_need_help: types.maybeNull(GenericSetting),
    login_new_auth_form: types.maybeNull(GenericSetting),
    account_lookup_identity_options: types.maybeNull(GenericSetting),
    remember_me_default_value: types.maybeNull(GenericSetting),
    insurance_form_dropdown: types.maybeNull(GenericSetting),
    payzen: types.maybeNull(GenericSetting),
    financed_plan_funder_information: types.maybeNull(GenericSetting),
    SUS_1612_save_cc_for_pp: types.maybeNull(GenericSetting),
    SUS_1617_save_echeck_for_pp: types.maybeNull(GenericSetting),
    enable_alphanumeric_zip: types.maybeNull(GenericSetting),
    limit_pp_offers: types.maybeNull(GenericSetting),
    ps_3297_save_echeck_on_regular_payment: types.maybeNull(GenericSetting),
    SUS_1645_logout: types.maybeNull(GenericSetting),
    HSUS_217_payment_method_enhancements: types.maybeNull(GenericSetting),
    show_insurance_info_in_bill_page: types.maybeNull(GenericSetting),
    pe_sms_subscriptions: types.maybeNull(GenericSetting),
    supported_credit_cards_types: types.maybeNull(GenericSetting),
    pre_service: types.maybeNull(GenericSetting),
    billing_questions: types.maybeNull(BillingQuestions),
    UNI_163_pe_show_pending_insurance: types.maybeNull(GenericSetting),
    DEF_562_PE_separate_one_time_payment_user_activities:
      types.maybeNull(GenericSetting),
    allow_pre_populate_insurance_details: types.maybeNull(GenericSetting),
    display_SB490_verbiage_in_PE: types.maybeNull(GenericSetting),
    international_payment_disable_zipcode: types.maybeNull(GenericSetting),
    HSUS_847_display_expected_completion_date: types.maybeNull(GenericSetting),
    default_bills_page_view: types.maybeNull(GenericSetting)
  })
  .actions(self => ({
    load: flow(function* () {
      // requires a session!
      return self.getSettingsWithQuery(GET_SETTINGS);
    }),
    loadWithoutSession: flow(function* (provider) {
      // get only settings that does not require a session here
      return self.getSettingsWithQuery(GET_PUBLIC_SETTINGS, {
        provider: provider
      });
    }),
    getSettingsWithQuery: flow(function* (settings_query, payload = {}) {
      self.dataApplied = false;
      try {
        const result = yield self.query(settings_query, payload);
        const settings = Object.values(result)[0];
        const obj = {};
        settings.forEach(setting => {
          if (hasProp(self, setting.settingName)) {
            obj[setting.settingName] = {
              disabled: !!setting.disabled,
              configurations: setting.configurations
            };
          }
        });
        self.applyWithApiStatus(obj);
        return Promise.resolve();
      } catch (e) {
        console.error('SettingsModel fetch error', e);
        return Promise.reject();
      }
    }),
    get: settingsName => {
      if (
        self[settingsName] &&
        hasProp(self, settingsName) &&
        hasProp(self[settingsName], 'disabled')
      ) {
        const { configurations, disabled } = self[settingsName];
        if (disabled) {
          return false;
        }
        if (Object.entries(configurations).length === 0) {
          //configurations are empty, rely on 'disabled'
          return !disabled;
        }
        return configurations;
      } else {
        //console.log(`%c setting not found: ${settingsName}`, 'color: #c91804');
        return false;
      }
    },
    getSettingsByList: list => {
      const getterDic = {
        billsGroups: self.getBillsGroups(),
        defaultSorting: self.getDefaultBillsSorting(),
        showPastBills: self.getShowPastBills()
      };
      return list.reduce((acc, curr) => {
        acc[curr] = getterDic[curr] || self.get(curr);
        return acc;
      }, {});
    },
    getBillsGroups: () => {
      const grouping = self.get('bill_grouping');
      //currently only 'static' type is supported. possible values: static, conditional, dynamic
      //see apps/patient/app/helpers/payables_helper.rb in get_group_header function
      if (grouping && grouping.type === 'static') {
        return {
          groupField: grouping.group_field,
          headers: self.getHeaders(grouping)
        };
      }
      return null;
    },
    getHeaders: grouping => {
      switch (grouping.group_field) {
        case 'partnerName':
          return grouping.partner_group_headers.map(({ partner, ...rest }) => ({
            bill: partner,
            ...rest
          }));
        case 'rawVisitType':
          return grouping.group_static_headers;
      }
    },
    getDefaultBillsSorting: () => {
      const { bills_list_sort } = self;
      if (bills_list_sort) {
        if (bills_list_sort.configurations.admissionDate) {
          return {
            key: 'admissionDate',
            order: bills_list_sort.configurations.admissionDate.order
          };
        }
      }
      return null;
    },
    getShowPastBills: () => {
      const billsHistory = self.get('payments_and_bills_history');
      if (billsHistory) return !!billsHistory.show_past_bills;
      return false;
    },
    hasPaymentMethodPermission: paymentType => {
      //"credit_card", "echeck", "partial_payment", "paypal"
      return (
        self
          .get('payment_method_permission')
          ?.payment_methods?.includes(paymentType) || false
      );
    },
    hasServiceFeeRevenueModel: () => {
      return (
        self.get('revenue_model_type')?.revenue_model_type === 'Service Fee'
      );
    },
    setFallbackLanguages: list => {
      //if no languages_select setting language is found with provider, fallback to languages in translation files list
      if (!self.get('languages_select')) {
        self.languages_select = {
          disabled: false,
          configurations: {
            languages: list
          }
        };
      }
    },
    getThemeProperties: () => {
      return self.cerner_portal_theme_properties;
    }
  }));

export default types.compose(ApiModel, SettingsModel);
