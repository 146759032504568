import { types, flow } from 'mobx-state-tree';
import ApiModel from './base/ApiModel';
import { GET_RESOURCES_FILES } from '../graphql/queries';

const ResourcesModel = types
  .model('ResourcesModel', {
    link: types.optional(types.string, '')
  })
  .actions(self => ({
    getResourceInfo: flow(function* (name) {
      const { resourceInfo } = yield self.query(GET_RESOURCES_FILES, {
        name
      });
      return Promise.resolve(resourceInfo);
    }),
    loadResourceFiles: flow(function* (name) {
      const res = yield self.getResourceInfo(name).catch(e => {
        console.error('---Resource fetch error', e);
        return Promise.reject(e);
      });

      try {
        return new Promise(resolve => {
          const promise = self.fetch(res.link, null, true).then(res => res);
          return resolve(promise);
        });
      } catch (e) {
        console.error('---Resources load error', e);
        return Promise.reject();
      }
    })
  }));

export default types.compose(ApiModel, ResourcesModel);
