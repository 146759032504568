import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const GET_PAST_STATEMENTS = gql`
  query pastStatementsInfo($payableIds: [ID!]!, $accountNumber: String!) {
    pastStatementsInfo(payableIds: $payableIds, accountNumber: $accountNumber) ${CLIENT} {
      id
      createdAt
      balanceSnapshot
      statementUniqueId
      paperUploadedAt
    }
  }
`;

export const GET_STATEMENT_FILE = gql`
  query pastStatementsContent($statementId: ID!) {
    pastStatementsContent(id: $statementId) ${CLIENT} {
      imageFormat
      preview
    }
  }
`;

export const GET_MEMBER_EMAIL_ADDRESSES = gql`
  query memberEmailAddress($accountNumber: String!) {
    guarantorOfAccount(accountNumber: $accountNumber) ${CLIENT} {
      id
      emailAddresses {
        id
        email
        isOptedIn
        isPaperlessOptedIn
        isPaperlessPending
        isPaperlessSubscribed
        createdAt
        primary
      }
    }
  }
`;

export const GET_MEMBERS_ADDRESS_INFO = gql`
  query memberAddressDetails($accountId: ID!) {
    guarantorOfAccount(accountId: $accountId) ${CLIENT} {
      phoneNumber {
        number
      }
      id
      firstName
      middleName
      lastName
      dateOfBirth
      addressDetails {
        address
        address2
        state
        city
        zipcode
      }
      planCoverages {
        payer
        link
        priority
        insuranceId
        insurancePlan
        name
      }
    }
  }
`;

export const GET_INSURANCE_INFO = gql`
  query insuranceDetails($accountNumber: String) {
    insuranceOfAccount(accountNumber: $accountNumber) ${CLIENT} {
      accountNumber,
      effectiveDate,
      insuranceName,
      insuranceNumber,
      groupNumber,
      phoneNumber,
      insuredEmployer,
      address,
      city,
      stateShortName,
      zipcode,
      insuredName,
      insuredsDob,
      patientRelation
    }
  }
`;

export const GET_MEMBER_PHONE_NUMBER = gql`
  query memberPhoneNumber($accountNumber: String) {
    guarantorOfAccount(accountNumber: $accountNumber) ${CLIENT} {
      id
      phoneNumber {
        allowSubscriptionChange
        createdAt
        displayValue
        id
        isOptedIn
        isPending
        number
        smsState
        lineType
      }
    }
  }
`;

export const GET_PHONE_NUMBER = gql`
  query phoneNumber($id: ID!) {
    phoneNumber(id: $id) ${CLIENT} {
      allowSubscriptionChange
      createdAt
      displayValue
      id
      isOptedIn
      isPending
      number
      smsState
      lineType
    }
  }
`;
