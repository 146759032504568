import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const CURAE_ACCOUNT_LOOKUP = gql`
  mutation curaeAccountLookup(
    $ssn: String
    $memberId: ID!
    $externalPatientId: ID
  ) {
    curaeAccountLookup(
      ssn: $ssn
      memberId: $memberId
      externalPatientId: $externalPatientId
    ) ${CLIENT} {
      id
    }
  }
`;

export const CREATE_URL = gql`
  mutation curaeCreateUrl(
    $memberId: ID!
    $payableId: ID!
    $accountId: ID!
    $amount: Int!
    $accountNumber: String!
    $ssn: String
  ) {
    curaeCreateUrl(
      createUrlParams: {
        memberId: $memberId
        payableId: $payableId
        accountId: $accountId
        amount: $amount
        accountNumber: $accountNumber
        ssn: $ssn
      }
    ) ${CLIENT}  {
      id
    }
  }
`;
