import i18n from 'i18next';
import moment from './MomentHelper';
import { initReactI18next } from 'react-i18next';

export let t = (key, params) => key;

export const translationsInterface = {
  init: () => {
    i18n.use(initReactI18next).init(
      {
        resources: {},
        lng: 'en',
        fallbackLng: 'en',

        interpolation: {
          escapeValue: true,
          defaultVariables: {},
          prefix: '%{',
          suffix: '}'
        }
      },
      () => {
        window.i18n = i18n; //simulation
        t = i18n.t.bind(i18n);

        i18n.store.on('added', function (lng, ns) {
          console.log('on language added!!!!!!', lng, ns);
        });
        i18n.on('languageChanged', function (lng) {
          console.log('changing moment locale', lng);
          moment.locale(lng);
        });
      }
    );
  },
  hasResource: lng => !!i18n.getResourceBundle(lng, 'translation'),
  addResource: ({ id, resources }) => {
    i18n.addResourceBundle(id, 'translation', resources);
  },
  changeLanguage: async lng => {
    console.log('changing language to', lng);
    await i18n.changeLanguage(lng);
    return i18n.language;
  },
  setDefaultValues: values => {
    i18n.options.interpolation.defaultVariables = values;
  },
  exists: key => i18n.exists(key)
};
