import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const CHANGE_NOTIFICATION_SETTINGS = gql`
  mutation subscribeEmail($id: ID!, $optIn: Boolean, $optInPaperless: Boolean) {
    subscribeEmail(id: $id, optIn: $optIn, optInPaperless: $optInPaperless)
    ${CLIENT} {
      emailAddress {
        id
        email
        isOptedIn
        isPaperlessOptedIn
        isPaperlessPending
        isPaperlessSubscribed
        createdAt
        primary
      }
    }
  }
`;

export const REMOVE_EMAIL_ADDRESS = gql`
  mutation removeEmailAddress($id: ID!, $guarantorId: ID!) {
    deleteEmail(id: $id, guarantorId: $guarantorId) ${CLIENT} {
      id
    }
  }
`;

export const RESEND_PAPERLESS_EMAIL = gql`
  mutation resendPaperlessEmail(
    $id: ID!
    $guarantorId: ID!
  ) {
    resendPaperlessEmail(
      id: $id
      guarantorId: $guarantorId
    ) ${CLIENT} {
      id
    }
  }
`;

export const ADD_EMAIL_ADDRESS = gql`
  mutation addEmail(
    $guarantorId: ID!
    $emailAddress: String!
    $optInPaperless: Boolean
  ) {
    addEmail(
      guarantorId: $guarantorId
      emailAddress: $emailAddress
      optInPaperless: $optInPaperless
    ) ${CLIENT} {
      emailAddress {
        id
        email
        isOptedIn
        isPaperlessOptedIn
        isPaperlessPending
        isPaperlessSubscribed
        createdAt
        primary
      }
    }
  }
`;

export const CHANGE_ADDRESS_INFORMATION = gql`
  mutation addressChange(
    $payableId: ID!
    $address: String
    $address2: String
    $city: String
    $email: String
    $stateShortName: String
    $zipcode: String
    $primaryPhoneNumber: String
  ) {
    addressChange(
      addressChange: {
        payableId: $payableId
        address: $address
        address2: $address2
        city: $city
        email: $email
        stateShortName: $stateShortName
        zipcode: $zipcode
        primaryPhoneNumber: $primaryPhoneNumber
      }
    ) ${CLIENT} {
      addressChange {
        accountNumber
        address
        address2
        city
        email
        primaryPhoneNumber
        stateShortName
        zipcode
      }
    }
  }
`;

export const REMOVE_PHONE_NUMBER = gql`
  mutation removePhoneNumber {
  updatePhoneNumber(
    number: ""
)${CLIENT}
}
`;

export const UPDATE_PHONE_NUMBER = gql`
  mutation updatePhoneNumber(
    $number: String!
  ) {
    updatePhoneNumber(
      number: $number
    )${CLIENT}
}
`;

export const RESEND_SMS = gql`
mutation resendSms(
    $phoneNumberId: ID!
    $accountNumber: String!
  ) {
    resendSms(
      phoneNumberId: $phoneNumberId
      accountNumber: $accountNumber
    )${CLIENT}
}
`;

export const CHANGE_PHONE_SUBSCRIPTION_SETTINGS = gql`
 mutation updateSmsSubscription(
    $phoneNumberId: ID!
    $action: SubscriptionActionEnum!
  ) {
    updateSmsSubscription(
      phoneNumberId: $phoneNumberId
      action: $action
    )${CLIENT}
  }
`;
