import { flow, types } from 'mobx-state-tree';
import { GET_PROVIDER_NAME } from '../graphql/queries';
import ApiModel from './base/ApiModel';
import LocalStorageUtil from '../utils/LocalStorage';

export const ProviderName = types
  .model('ProviderName', {
    name: types.maybeNull(types.string)
  })
  .actions(self => ({
    load: flow(function* () {
      try {
        self.setProviderNameFromUrl();
        if (!self.name) {
          const { providerName } = yield self.query(GET_PROVIDER_NAME);
          console.log('name:', providerName.name);
          if (providerName.name) {
            self.applyWithApiStatus({ name: providerName.name });
          }
        }
        return Promise.resolve(self.name);
      } catch (e) {
        console.error('ProviderName error:', e);
      }
      return Promise.resolve(self.name);
    }),
    /**
     * three possible url forms which contain provider name:
     * 1) www.domain.com/path/providers/provider_name/bills/
     * 2) www.domain.com/path/login/provider_name/
     * 3) provider_name.simpleepay.com/bills/
     */
    setProviderNameFromUrl: () => {
      let providersSearch, isLoginFromMyChart, isConsentPage;
      const { host, pathname, search } = window.location;
      const location = `${host}${pathname}`;
      try {
        // login from my chart will match /login but will have to get provider from 3rd url example
        isConsentPage = location.match('/consents');
        isLoginFromMyChart = location.match('/login/payer');
        providersSearch = location.match('/login/(.*)');
        if (isConsentPage) {
          self.setNameForConsentUrl(search);
        } else if (
          providersSearch &&
          providersSearch.length &&
          !isLoginFromMyChart
        ) {
          self.setNameForLoginUrl(providersSearch);
        } else {
          self.setNameForProvidersUrl(location);
        }

        if (!self.name) {
          self.setNameFromLocalStorage();
        } else {
          LocalStorageUtil.set('providerName', self.name);
        }
      } catch (e) {
        console.log('providerNameFromUrl error:', e);
      }
    },
    setNameFromLocalStorage: () => {
      const providerFromLocalStorage = LocalStorageUtil.get('providerName');
      if (providerFromLocalStorage) {
        self.name = providerFromLocalStorage;
      }
    },
    setNameForConsentUrl: search => {
      const urlParams = new URLSearchParams(search);
      self.name = urlParams.get('provider');
      console.log('Set provider name from /consents: ', self.name);
    },
    setNameForLoginUrl: providersSearch => {
      // \/login/\ found, get name within next '/'
      self.name = providersSearch[1].match('[^/]*')[0];
      console.log('Set provider name from /login: ', self.name);
    },
    setNameForProvidersUrl: location => {
      let providersSearch = location.match('/providers/(.*)');
      if (providersSearch && providersSearch.length) {
        // \/providers/\ found, get name within next '/'
        self.name = providersSearch[1].match('[^/]*')[0];
        console.log('Set provider name from /provider: ', self.name);
      } else {
        // \/providers/\ OR \/login/\ not found, try provider_name.simpleepay.com/
        providersSearch = location.match('^(.*?).simpleepay.com');
        if (
          providersSearch &&
          providersSearch.length &&
          providersSearch[1] !== 'www'
        ) {
          self.setNameFromLocalStorage();
          if (!self.name) {
            self.name = providersSearch[1];
          }
          console.log('Set provider name from sub-domain: ', self.name);
        }
      }
    }
  }));

export default types.compose(ApiModel, ProviderName);
