/**
 * Convert currency string ("$1,123.45") into number (1123.45)
 * @param {String} currencyStr
 */
export const currencyToNumber = currencyStr => {
  if (typeof currencyStr !== 'string') {
    return currencyStr;
  }
  return currencyStr.replace(/[^0-9.]+/g, '');
};

/**
 * determines whether the passed value is an integer
 * if Number.isInteger is not supported, uses polyfill
 * isNumber(0) = true, isNumber(null) = false
 * @param value
 * @return boolean
 */
export const isNumber = value =>
  Number.isInteger
    ? Number.isInteger(value)
    : typeof value === 'number' &&
      isFinite(value) &&
      Math.floor(value) === value;

export const formatPhoneNumber = value => {
  if (!value) return value;
  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  if (phoneNumber.length < 4) return phoneNumber;
  if (phoneNumber.length < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const formatSsn = value => {
  if (!value) return value;
  // clean the input for any non-digit values.
  const ssn = value.replace(/[^\d]/g, '');

  if (ssn.length < 4) return ssn;
  if (ssn.length < 6) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
  }
  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
};
