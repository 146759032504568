import React from 'react';
import styled from 'styled-components';
import { media } from '../../../../theme/styledMedia';
import LoginNotificationIcon from '../../../../assets/icons/icon-login-notification.png';
import NotificationExpandIcon from '../../../../assets/icon-notification-expand.svg';
import { spacingInRems } from '../../../../theme/themeUtils';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';

const StyledExpansionPanel = styled(Accordion)`
  width: 100vw;
  && {
    margin-bottom: 0;
    background: #e4f2ff;
    .notificationIcon {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }
    .notificationLabel {
      font-weight: 500;
      color: ${({ theme }) => theme.palette.black};
    }
    .notificationSummary {
      min-height: 0;
      padding: 5px 24px;
    }
    .notificationSummaryContent {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
    }
    .notificationDetails {
      padding-top: 0;
      padding-bottom: ${spacingInRems()};
    }
    ${media.xs`
      .notificationSummary {
        margin: 0 ${spacingInRems()};
        padding: 0;
      }
    `};
  }
`;

const NotificationPanel = props => {
  const { header, content, isFixed } = props;
  const style = isFixed ? { position: 'fixed', zIndex: 1101 } : {};
  return (
    <>
      <StyledExpansionPanel style={style} data-testid={'notification-panel'}>
        <AccordionSummary
          expandIcon={
            content && (
              <img
                src={NotificationExpandIcon}
                className={'notificationExpandIcon'}
                alt={'icon'}
              />
            )
          }
          classes={{
            root: 'notificationSummary',
            content: 'notificationSummaryContent'
          }}
        >
          <img
            src={LoginNotificationIcon}
            className={'notificationIcon'}
            alt={'icon'}
          />
          <Typography variant={'subtitle2'} className={'notificationLabel'}>
            {header}
          </Typography>
        </AccordionSummary>
        {content && (
          <AccordionDetails
            className={'notificationDetails'}
            classes={{ root: 'notificationDetails' }}
          >
            <Typography>{content}</Typography>
          </AccordionDetails>
        )}
      </StyledExpansionPanel>
    </>
  );
};

export default NotificationPanel;
