import { newRelicAgent } from './new-relic-agent';

const stepsCalled = [];

const logTimingStep = (stepName, logText, contextParams = {}) => {
  if (!window.performance?.getEntriesByType || stepsCalled.includes(stepName)) return;
  stepsCalled.push(stepName);
  const navigationEntry = window.performance.getEntriesByType('navigation')[0];
  const time = window.performance.now() - navigationEntry.fetchStart;
  newRelicAgent.addPageAction(logText, { time, ...contextParams });
};

export const TimingLogger = {
  logStart() {
    logTimingStep('logStart', 'Scripts start running')
  },
  logFirstPagePresented(pageName) {
    logTimingStep('logFirstPagePresented', 'First page content fully loaded', { pageName })
  }
};
