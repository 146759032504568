import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useCallback } from 'react';
import { storeContext } from '../../context';

const _history = [];

const UserActionsRecorder = observer(({ children }) => {
  const {
    router: { currentPage },
    userState
  } = useContext(storeContext);

  const { userActions } = userState;

  const addToActionsList = (action, isHistory) => {
    isHistory && _history.push(action);
    userState.set('userActions', userActions.concat([action]));
  };

  if (
    currentPage.pathname !== userActions[userActions.length - 1] &&
    _history[_history.length - 1] !== currentPage.pathname
  ) {
    addToActionsList(currentPage.pathname, true);
  }

  const onClickCapture = e => {
    if (e.target && e.target.id) {
      const { id } = e.target;
      if (id === 'download-button') {
        addToActionsList('download');
      } else if (id === 'print-button') {
        addToActionsList('print');
      } else if (id.includes('view-statement-button')) {
        addToActionsList('view past statement');
      }
    }
  };

  return <div onClickCapture={onClickCapture}> {children}</div>;
});

export default UserActionsRecorder;
