//Polyfill for IE 11
import URLSearchParams from '@ungap/url-search-params';

/**
 * returns property from window.location.search
 * window.location.href = http://www.something.com/?search=1&some_prop=2
 * getPropInUrlParams('search') => "1"
 * getPropInUrlParams('some_prop') => "2"
 * @param {string} propertyToSearch
 * @returns {string}
 */
export const getPropInUrlParams = propertyToSearch => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(propertyToSearch);
};

/**
 * returns boolean if property exists on window.location.search
 * window.location.href = http://www.something.com/?search=1&some_prop=2
 * hasPropInUrlParams('search') => true
 * getPropInUrlParams('prop_does_not_exist') => false
 * @param {string} prop
 * @returns {boolean}
 */
export const hasPropInUrlParams = prop => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(prop);
};
