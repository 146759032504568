import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const memberFields = `
  member {
    firstName
    lastName
    addressLine
    city
    state
    zipCode
    mobileNumber
    emailAddress
    dateOfBirth
  }
`;

export const fullOffersFields = `
  fullOffers {
    recommendedOffer
    recommendedOfferTotalFees
    recommendedOfferAmountPerInstallment
    recommendedOfferLastInstallmentAmount
    recommendedOfferTotalAmountPerInstallment
    recommendedOfferTotalBalanceIncludingFees
    recommendedOfferLastInstallmentTotalAmount
    recommendedOfferType
    recommendedOfferPzId

    shortOffer
    shortOfferTotalFees
    shortOfferAmountPerInstallment
    shortOfferLastInstallmentAmount
    shortOfferTotalAmountPerInstallment
    shortOfferTotalBalanceIncludingFees
    shortOfferLastInstallmentTotalAmount
    shortOfferType
    shortOfferPzId

    longOffer
    longOfferTotalFees
    longOfferAmountPerInstallment
    longOfferLastInstallmentAmount
    longOfferTotalAmountPerInstallment
    longOfferTotalBalanceIncludingFees
    longOfferLastInstallmentTotalAmount
    longOfferType
    longOfferPzId

    otherOptions
    serviceFee
    totalBalance
    validForPlan
  }
`;

export const GET_FULL_OFFERS = gql`
  query fullOffersResult($totalBalance: Int!, $payableAccountNumber: String!) {
    fullOffersResult(
      totalBalance: $totalBalance
      payableAccountNumber: $payableAccountNumber
    ) ${CLIENT} {
      errors
      ${fullOffersFields}
    }
  }
`;

export const GET_DYNAMIC_OFFER = gql`
  query getOffer($standingAmount: Int!) {
    getOffer(standingAmount: $standingAmount) ${CLIENT} {
      offer
    }
  }
`;

export const GET_PAYZEN_OFFERS = gql`
  query financedOffers(
    $payableId: ID!
    $billIds: [ID!]!
    $isFinalOffer: Boolean!
    $firstName: String
    $lastName: String
    $addressLine: String
    $city: String
    $state: String
    $zipCode: String
    $mobileNumber: String
    $emailAddress: String
    $dateOfBirth: String
    $overridePayzenOffers: Boolean
  ) {
    financedOffers(
      payableId: $payableId
      billIds: $billIds
      isFinalOffer: $isFinalOffer
      firstName: $firstName
      lastName: $lastName
      addressLine: $addressLine
      city: $city
      state: $state
      zipCode: $zipCode
      mobileNumber: $mobileNumber
      emailAddress: $emailAddress
      dateOfBirth: $dateOfBirth
      overridePayzenOffers: $overridePayzenOffers
    ) ${CLIENT} {
      ${fullOffersFields}
      ${memberFields}
      errors
    }
  }
`;
