import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const StyledDiv = styled.div`
  .loadingSpinnerContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -2em;
    margin-top: -2em;
  }
`;

const LoginPageLoader = () => {
  return (
    <StyledDiv>
      <div className={'loadingSpinnerContainer'} id={'login-page-spinner'}>
        <CircularProgress style={{ color: '#0070aa' }} />
      </div>
    </StyledDiv>
  );
};

export default LoginPageLoader;
