/**
 * aggregate language subtypes into a single language translation
 * e.g 'en-ca' or 'en-au' to 'en', 'es-mx' or 'es-es' to 'es'
 * @param {string} lng
 */
export const getLanguageFromCode = lng => {
  const index = lng.indexOf('-');
  if (index > -1) {
    return lng.substring(0, index);
  }
  return lng;
};
