import { BroadcastChannel } from 'broadcast-channel';

const loginChannel = new BroadcastChannel('login');

export const postOnLoginChangeMessage = () => {
  loginChannel.postMessage('OnLoginChange');
};

export const changeLoginStateInAllTabs = () => {
  loginChannel.onmessage = () => {
    window.location.reload();
    loginChannel.close();
  };
};
