import { ValidatorForm } from 'react-material-ui-form-validator';

ValidatorForm.addValidationRule('decimalValidation', value => {
  const rx = new RegExp(/^\d+(?:\.\d{1,2})?$/);
  return rx.test(value);
});

ValidatorForm.addValidationRule('dateValidation', value => {
  return !isNaN(new Date(value).getTime());
});

ValidatorForm.addValidationRule('dayOfMonthValidation', value => {
  const rx = new RegExp(/^([1-9]|0[1-9]|[12]\d|3[01])$/);
  return rx.test(value);
});

ValidatorForm.addValidationRule('yearValidation', value => {
  const rx = new RegExp(/^(19|20)\d{2}$/);
  return rx.test(value);
});

ValidatorForm.addValidationRule('minLengthIfNotEmpty', (value, length) => {
  return value.length === 0 || value.length >= length;
});

ValidatorForm.addValidationRule('postalCode', value => {
  const rx = new RegExp(/^\d{5}(-?\d{4})?$/);
  return rx.test(value);
});

ValidatorForm.addValidationRule('noWhiteSpace', value => {
  const rx = new RegExp(/^\S*$/);
  return rx.test(value);
});

export default {
  DECIMAL: 'decimalValidation',
  DATE: 'dateValidation'
};
