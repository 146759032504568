import { types } from 'mobx-state-tree';
import { detect } from 'detect-browser';
import { isFutureDate } from '../utils/Date';
import { toQueryString } from '../utils/String';
import { paymentOptionTypes } from '../constants/paymentOptions';
import { paymentTypes, URL_PARAMS } from '../constants/surveyMonkey';

const {
  FULL_PAYMENT,
  PARTIAL_PAYMENT,
  PAYMENT_PLAN,
  CARE_CREDIT,
  CUSTOM_PLAN
} = paymentOptionTypes;

const {
  SCHEDULED_PAYMENT,
  FULL,
  PARTIAL,
  PLAN_PAYMENT,
  CARE_CREDIT_TYPE
} = paymentTypes;

const {
  PAYMENT_TYPE,
  PAYMENT_AMOUNT,
  ACCOUNT_BALANCE,
  PROVIDER,
  COMPANY,
  PAYABLE_IDS,
  BROWSER,
  DEVICE
} = URL_PARAMS;

const paymentOptionToPaymentType = {
  [FULL_PAYMENT]: FULL,
  [PARTIAL_PAYMENT]: PARTIAL,
  [PAYMENT_PLAN]: PLAN_PAYMENT,
  [CUSTOM_PLAN]: PLAN_PAYMENT,
  [CARE_CREDIT]: CARE_CREDIT_TYPE
};

const SurveyMonkey = types
  .model('SurveyMonkey', {
    payableId: types.maybeNull(types.string),
    providerName: types.string,
    companyName: types.string,
    standingAmount: types.maybeNull(types.number)
  })
  .actions(self => {
    const getBrowserName = () => {
      const browser = detect();
      if (browser?.name) {
        //capitalize first letter
        return browser.name.charAt(0).toUpperCase() + browser.name.slice(1);
      }
      return 'undefined';
    };

    const getPaymentType = paymentInformation => {
      const isScheduledPayment = isFutureDate(paymentInformation.date);
      if (isScheduledPayment) return SCHEDULED_PAYMENT;
      return paymentOptionToPaymentType[paymentInformation.paymentOptionType];
    };

    const getBaseDetails = () => {
      return {
        [PROVIDER]: self.providerName,
        [COMPANY]: self.companyName,
        [BROWSER]: getBrowserName(),
        [DEVICE]: navigator?.platform || undefined
      };
    };

    return {
      getBaseUrlParams: () => {
        return toQueryString({
          ...getBaseDetails()
        });
      },
      getUserPaymentUrlParams: paymentInformation => {
        return toQueryString({
          ...getBaseDetails(),
          [PAYMENT_TYPE]: getPaymentType(paymentInformation),
          [PAYMENT_AMOUNT]: paymentInformation.amount,
          [ACCOUNT_BALANCE]: [self.standingAmount],
          [PAYABLE_IDS]: [parseInt(self.payableId)]
        });
      }
    };
  });

export default SurveyMonkey;
