import 'cross-fetch/polyfill';
//https://github.com/apollographql/apollo-client/issues/4843
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { resolvers } from './resolvers';
import { newRelicAgent } from '../logging/new-relic-agent';
//import defaults from './client_defaults';

let client;

export const getRandomId = () => {
  return `${
    Date.now().toString(36) + Math.random().toString(36).substring(2)
  }-pe-ui`;
};

export const createClient = async (uri, requestHeaders) => {
  let cache = new InMemoryCache();
  let cacheResolvers;

  if (
    (typeof process.env.REACT_APP_ENV_VAR === 'string' &&
      process.env.REACT_APP_ENV_VAR.trim() === 'simulated') ||
    process.env.NODE_ENV === 'test'
  ) {
    //load mock data
    const module = await import(`./client_defaults`);
    const result = {};
    let values = module.default;
    for (const key of Object.keys(values)) {
      result[key] = {
        read() {
          return values[key];
        }
      };
    }
    cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            ...result
          }
        }
      }
    });
    cacheResolvers = resolvers;
    //mock window.fetch
    await import(`../__mocks__/fetchMock`);
  }

  client = new ApolloClient({
    cache,
    uri,
    resolvers: cacheResolvers,
    credentials: 'include',
    headers: {
      ...requestHeaders,
      source: 'patient-client'
    },
    onError: ({
      graphQLErrors,
      networkError,
      response,
      operation,
      forward
    }) => {
      if (!operation.variables.ignoreErrorLogs) {
        if (graphQLErrors) {
          graphQLErrors.forEach(err => newRelicAgent.noticeError(err));
          return console.error('GraphQL Errors:', graphQLErrors);
        }
        if (networkError) {
          newRelicAgent.noticeError(networkError);
          return console.error('Network Error: ', networkError);
        }
      }
    }
  });
};

export const getClient = () => client;
