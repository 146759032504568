import React from 'react';
import styled from 'styled-components';

import { media } from '../../../theme/styledMedia';

const StyledDiv = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  background: #fafafa;
  z-index: 99999;
  .square {
    background: #d7d7d7;
    border-radius: 10px;
  }
  .header {
    width: 99%;
    height: 80px;
    top: 0;
    margin-top: 18px;
  }
  .billsAndMenu {
    display: flex;
    overflow: hidden;
    margin-top: 83px;
    background: none;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% + 24px);
    & .billsContainer {
      max-width: 50%;
      width: 70%;
    }
    & .menu {
      margin-left: 12px;
      width: 30%;
      height: 900px;
      background: purple;
      max-width: 350px;
      box-sizing: border-box;
      background: url(${process.env.PUBLIC_URL}/images/preloader_right_rail.png)
        no-repeat;
      background-size: 100% 100%;
    }
    .billsContainer {
      .billsSelector {
        width: 100%;
        height: 47px;
      }
      .billsAndCategoryWrapper {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .category {
          width: 98%;
          height: 20px;
          border-radius: 5px;
        }
        .billsGrid {
          display: flex;
          margin-top: 25px;
          width: 100%;
          .billBox {
            position: relative;
            max-width: 50%;
            flex-basis: 50%;
            min-height: 220px;
            margin-right: 12px;
            background: none;
            .image {
              object-fit: fill;
              position: absolute;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  ${media.md`
     && {
        .billsAndMenu {
          overflow: auto;
          .menu {
            display: none;
          }
          .billsContainer {
            max-width: 100%;
            width: 97%;
        }
      }
    }
  `}
  ${media.sm`
    && {
      .billsAndMenu {
      .billsContainer{
        width: 93%;
        .billsAndCategoryWrapper {
          .billsGrid {
            flex-direction: column;
              .billBox {
                max-width:100%;
                flex-basis: 100%;
                margin-right: 0;
                &:not(:first-child){
                  margin-top: 12px;
                }
              }
            }
          }
        }
      }
    }
   `}
   ${media.xs`
     && {
       .header {
         margin-top: 8px;
         height: 54px;
       }
       .billsAndMenu {
          margin-top: 18px;
          .billsContainer{
             width: 88%;
            .billsGrid {
              .billBox {
                min-height: 250px;
              }
            }
            .billsAndCategoryWrapper:nth-child(2) {
              display: none;
            }
          }

       }
     }
  `}
`;

const BillsPageLoader = () => {
  const getBillBox = () => (
    <div className={'billBox square'}>
      <img
        src={`${process.env.PUBLIC_URL}/images/billBox_desktop.svg`}
        className={'image'}
      />
    </div>
  );
  return (
    <StyledDiv>
      <div className={'header square'} />
      <div className={'billsAndMenu'}>
        <div className={'billsContainer'}>
          <div className={'billsSelector square'} />
          <div className={'billsAndCategoryWrapper'}>
            <div className={'category square'} />
            <div className={'billsGrid'}>
              {getBillBox()}
              {getBillBox()}
            </div>
          </div>
          <div className={'billsAndCategoryWrapper'}>
            <div className={'category square'} />
            <div className={'billsGrid'}>
              {getBillBox()}
              {getBillBox()}
            </div>
          </div>
        </div>

        <div className={'menu square'} />
      </div>
    </StyledDiv>
  );
};
export default BillsPageLoader;
