/**
 * simple localStorage util
 */

const isLocalStorageAvailable = key => {
  if (!key) {
    console.error('localStorage error, a key must be provided');
    return false;
  }
  try {
    if (!window || !window.localStorage || !window.JSON) {
      console.error("localStorage error, can't continue");
      return false;
    }
  } catch (e) {
    return false;
  }

  return true;
};

/**
 * set item in localStorage
 * @param {string} key
 * @param {object} data
 */
const set = (key, data) => {
  if (!isLocalStorageAvailable(key)) return;

  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.error('localStorage error', e);
  }
};

/**
 * get item from localStorage by key
 * @param {string} key
 */
const get = key => {
  if (!isLocalStorageAvailable(key)) return;

  const item = localStorage.getItem(key);

  if (!item) {
    return null;
  }

  try {
    return JSON.parse(item);
  } catch (e) {
    console.error('localStorage error', e);
  }
};

/**
 * remove item from localStorage by key
 * @param {string} key
 */
const remove = key => {
  if (!isLocalStorageAvailable(key)) return;

  localStorage.removeItem(key);
};

/**
 * clear all localStorage
 */
const clearAll = key => {
  if (!isLocalStorageAvailable(key)) return;

  localStorage.clear();
};

export default {
  set,
  get,
  remove,
  clearAll
};
