import { applySnapshot, getSnapshot, types } from 'mobx-state-tree';
import { hasProp } from '../utils/Object';

//persistent user state across App
//used to store UI state which may be shared across components
export const UserState = types
  .model('UserState', {
    billsPageDisplayType: types.maybeNull(types.string),
    updateInfoDisplayType: types.maybeNull(types.number),
    changeAddressState: types.frozen({}),
    changeInsuranceState: types.frozen({}),
    paymentItemPopoverType: types.maybeNull(types.number),
    paymentItemPopoverIsOpen: types.optional(types.boolean, false),
    footerHeight: types.optional(types.number, 0),
    userActions: types.frozen([]),
    hasShownQuickActionPage: types.optional(types.boolean, false),
    selectedPaymentType: types.maybeNull(types.string),
    paymentAmount: types.maybeNull(types.number),
    paymentInformation: types.frozen({}), // amount, type, date, ccData, eCheckData
    headerHeight: types.optional(types.number, 0),
    feeAmount: types.optional(types.number, 0),
    selectedBillIdsQuickAction: types.optional(types.array(types.string), [])
  })
  .actions(self => {
    let _initialState = {};
    return {
      afterCreate: () => {
        _initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self, _initialState);
      },
      set: (prop, val) => {
        if (hasProp(self, prop) && self[prop] !== val) {
          self[prop] = val;
          console.log('---userState prop:', prop, 'value:', self[prop]);
        } else if (!hasProp(self, prop)) {
          console.warn(
            `userState prop ${prop} has not been declared in model and will not be saved`
          );
        }
      },
      setNested: (prop, nestedProp, val) => {
        if (
          hasProp(self, prop) &&
          hasProp(self[prop], nestedProp) &&
          self[prop][nestedProp] !== val
        ) {
          const newProp = { ...self[prop] };
          newProp[nestedProp] = val;
          self[prop] = newProp;
        } else {
          console.error('did not save nested prop', self[prop]);
          console.log(hasProp(self, prop));
          console.log(hasProp(self[prop], nestedProp));
        }
      }
    };
  });

export default UserState;
