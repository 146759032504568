import { flow, types, onPatch } from 'mobx-state-tree';

import ApiModel from './base/ApiModel';
import CommonActions from './base/CommonActions';
import { GET_MEMBER_EMAIL_ADDRESSES } from '../graphql/queries';
import {
  ADD_EMAIL_ADDRESS,
  CHANGE_NOTIFICATION_SETTINGS,
  REMOVE_EMAIL_ADDRESS,
  RESEND_PAPERLESS_EMAIL
} from '../graphql/mutations';
import { find } from 'lodash';
import SessionParams from '../utils/SessionParams';

const emailErrors = {
  EMAIL_ALREADY_EXISTS: 'emailAlreadyExists',
  RESEND_EMAIL_FAILED: 'resendEmailFailed'
};

const EmailAddress = types.model('EmailAddress', {
  id: types.optional(types.string, ''),
  email: types.optional(types.string, ''),
  isOptedIn: types.maybeNull(types.boolean),
  isPaperlessOptedIn: types.maybeNull(types.boolean),
  isPaperlessPending: types.maybeNull(types.boolean),
  isPaperlessSubscribed: types.maybeNull(types.boolean),
  createdAt: types.optional(types.string, ''),
  primary: types.maybeNull(types.boolean)
});

const MembersEmailAddresses = types
  .model('MembersEmailAddresses', {
    emailAddresses: types.optional(types.array(EmailAddress), []),
    errors: types.frozen(emailErrors),
    initialLoadingComplete: types.optional(types.boolean, false)
  })
  .actions(self => ({
    afterCreate: () => {
      onPatch(self, patch => {
        if (patch.path.includes('emailAddresses')) {
          self.updatePrimaryEmailAddress();
        }
      });
    },
    load: flow(function* () {
      try {
        self.dataApplied = false;
        const { guarantorOfAccount } = yield self.query(
          GET_MEMBER_EMAIL_ADDRESSES,
          {
            accountNumber: SessionParams.accountNumber
          }
        );

        self.applyWithApiStatus({
          emailAddresses: guarantorOfAccount.emailAddresses,
          initialLoadingComplete: true
        });

        self.updatePrimaryEmailAddress();
        return Promise.resolve(guarantorOfAccount.id);
      } catch (e) {
        console.error('MembersEmailAddresses load error:', e);
      }
    }),
    updateExistingEmail: flow(function* ({
      id,
      isOptedIn,
      isPaperlessOptedIn
    }) {
      if (!id) {
        throw new Error('updateExistingEmail: must provide id');
      }

      //changing isOptedIn to false should also set isPaperlessOptedIn false
      //changing isPaperlessOptedIn to true should set isOptedIn to true
      if (isOptedIn === false) {
        isPaperlessOptedIn = false;
      }
      if (isPaperlessOptedIn) {
        isOptedIn = true;
      }

      try {
        const { subscribeEmail } = yield self.mutate(
          CHANGE_NOTIFICATION_SETTINGS,
          {
            id: id,
            //guarantorId: SessionParams.memberId,
            optIn: isOptedIn,
            optInPaperless: isPaperlessOptedIn
          }
        );

        self.emailAddresses = self.emailAddresses.map(emailAddress => {
          if (emailAddress.id === id) {
            return { ...emailAddress, ...subscribeEmail.emailAddress };
          }
          return emailAddress;
        });
        return Promise.resolve();
      } catch (e) {
        console.error('NotificationsSettings Model error', e);
        return Promise.reject(e);
      }
    }),
    removeEmail: flow(function* (emailId) {
      try {
        console.log(
          '----about to remove emailId:',
          emailId,
          'guarantorId:',
          SessionParams.memberId
        );
        const res = yield self.mutate(REMOVE_EMAIL_ADDRESS, {
          id: emailId,
          guarantorId: SessionParams.memberId
        });

        console.log('res', res);

        if (res && res.deleteEmail && res.deleteEmail.id === emailId) {
          self.emailAddresses = self.emailAddresses.filter(
            email => email.id !== emailId
          );
        }
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    }),
    addEmail: flow(function* (emailAddress, isPrimary = false) {
      const existingMail = find(self.emailAddresses, ['email', emailAddress]);
      if (existingMail) {
        return yield Promise.reject({
          error: self.errors.EMAIL_ALREADY_EXISTS
        });
      }

      const newMail = {
        guarantorId: SessionParams.memberId,
        emailAddress,
        optInPaperless: false
      };

      const { addEmail } = yield self.mutate(ADD_EMAIL_ADDRESS, {
        ...newMail
      });

      if (isPrimary) {
        self.emailAddresses.forEach(email => (email.primary = false));
        addEmail.emailAddress.primary = isPrimary;
      }
      self.emailAddresses.push(addEmail.emailAddress);
      return Promise.resolve();
    }),
    resendEmail: flow(function* (emailId) {
      console.log('resendEmail, email is:', emailId);

      try {
        const { resendPaperlessEmail } = yield self.mutate(
          RESEND_PAPERLESS_EMAIL,
          {
            id: emailId,
            guarantorId: SessionParams.memberId
          }
        );

        console.log('-----resendPaperlessEmail:', resendPaperlessEmail);
        if (resendPaperlessEmail && resendPaperlessEmail.id) {
          return Promise.resolve(emailId);
        } else {
          return Promise.reject({
            error: self.errors.RESEND_EMAIL_FAILED
          });
        }
      } catch (e) {
        return Promise.reject({
          error: self.errors.RESEND_EMAIL_FAILED
        });
      }
    }),
    updatePrimaryEmailAddress: () => {
      const primaryEmail = self.emailAddresses.length
        ? self.emailAddresses.find(email => email.primary) ||
          self.emailAddresses[0]
        : null;

      const newPrimaryEmail = primaryEmail ? primaryEmail.email : '';

      if (newPrimaryEmail !== SessionParams.primaryEmailAddress) {
        console.log(
          'changing primary email from',
          SessionParams.primaryEmailAddress,
          'to',
          newPrimaryEmail
        );
        SessionParams.primaryEmailAddress = newPrimaryEmail;
      }
    }
  }))
  .views(self => ({
    get hasOptedIn() {
      //has at least one email address that isOptedIn
      return self.emailAddresses.some(email => email.isOptedIn);
    },
    get primaryEmailAddress() {
      return self.emailAddresses.length
        ? self.emailAddresses.find(email => email.primary) ||
            self.emailAddresses[0]
        : null;
    }
  }));

export default types.compose(CommonActions, ApiModel, MembersEmailAddresses);
