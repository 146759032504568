let queue = [];
export const newRelicAgent = {
  noticeError(error) {
    const newrelic = global.newrelic;
    if (newrelic) {
      newrelic.noticeError(error);
    } else {
      queue.push(['noticeError', error])
    }
  },
  addPageAction(messageText, context) {
    const newrelic = global.newrelic;
    if (newrelic) {
      newrelic.addPageAction(messageText, context);
    } else {
      queue.push(['addPageAction', messageText, context])
    }
  }
};

const interval = setInterval(() => {
  const newrelic = global.newrelic;
  if (newrelic) {
    clearInterval(interval);
    queue.forEach(call => {
      console.log('calling', call);
      newrelic[call[0]].apply(call.slice(1));
    });
    queue = null;
  }
}, 500);
