import { flow, types } from 'mobx-state-tree';

import ApiModel from './base/ApiModel';
import CommonActions from './base/CommonActions';
import { CHANGE_INSURANCE_INFORMATION } from '../graphql/mutations';
import { GET_INSURANCE_INFO } from '../graphql/queries/notifications';
import SessionParams from '../utils/SessionParams';

const InsuranceInformation = types
  .model('InsuranceInformation', {
    address: types.optional(types.string, ''),
    city: types.optional(types.string, ''),
    effectiveDate: types.optional(types.string, ''),
    groupNumber: types.optional(types.string, ''),
    id: types.optional(types.string, ''),
    insuranceName: types.optional(types.string, ''),
    insuranceNumber: types.optional(types.string, ''),
    insuredEmployer: types.optional(types.string, ''),
    insuredName: types.optional(types.string, ''),
    insuredsDob: types.optional(types.string, ''),
    patientRelation: types.optional(types.string, ''),
    phoneNumber: types.optional(types.string, ''),
    stateShortName: types.optional(types.string, ''),
    zipcode: types.optional(types.string, '')
  })
  .actions(self => ({
    load: flow(function* () {
      try {
        self.dataApplied = false;

        const { insuranceOfAccount } = yield self.query(GET_INSURANCE_INFO, {
          accountNumber: SessionParams.accountNumber
        });
        self.applyWithApiStatus(insuranceOfAccount);
        return Promise.resolve();
      } catch (e) {
        console.error('Payments Model error', e);
        return Promise.reject();
      }
    }),
    updateDetails: flow(function* (params) {
      console.log('sending updateDetails...', params);

      //hasDataChanged() in inherited from CommonActions model
      if (!self.hasDataChanged(params).changed) {
        return Promise.resolve('noChange');
      }

      try {
        const { insuranceChange } = yield self.mutate(
          CHANGE_INSURANCE_INFORMATION,
          {
            accountNumber: SessionParams.accountNumber,
            ...params
          }
        );

        self.applyWithApiStatus(insuranceChange);
        return Promise.resolve('success');
      } catch (e) {
        return Promise.reject('InsuranceInformation updateDetails error: ' + e);
      }
    }),

    overrideInsurance: (newInsurance, oldInsurance) => {
      let newInsuranceChange = {
        ...oldInsurance,
        ...newInsurance
      };
      self.applyWithApiStatus(newInsuranceChange);
      return Promise.resolve('success');
    },

    updateInsuranceName: (newInsuranceName, insuranceChange) => {
      let newInsuranceChange = {
        ...insuranceChange,
        insuranceName: newInsuranceName
      };
      self.applyWithApiStatus(newInsuranceChange);

      return Promise.resolve('success');
    },

    updateInsuranceNumber: (newInsurancePlan, insuranceChange) => {
      let newInsuranceChange = {
        ...insuranceChange,
        insuranceNumber: newInsurancePlan
      };
      self.applyWithApiStatus(newInsuranceChange);

      return Promise.resolve('success');
    },

    updateAddress: (newAddress, insuranceChange) => {
      let newInsuranceChange = { ...insuranceChange, address: newAddress };
      self.applyWithApiStatus(newInsuranceChange);

      return Promise.resolve('success');
    },

    updateState: (newStateShortName, insuranceChange) => {
      let newInsuranceChange = {
        ...insuranceChange,
        stateShortName: newStateShortName
      };
      self.applyWithApiStatus(newInsuranceChange);

      return Promise.resolve('success');
    },

    updateCity: (newCity, insuranceChange) => {
      let newInsuranceChange = { ...insuranceChange, city: newCity };
      self.applyWithApiStatus(newInsuranceChange);

      return Promise.resolve('success');
    },

    updateZipcode: (newZipCode, insuranceChange) => {
      let newInsuranceChange = { ...insuranceChange, zipcode: newZipCode };
      self.applyWithApiStatus(newInsuranceChange);

      return Promise.resolve('success');
    },

    updateInsuredDob: (newInsuredDob, insuranceChange) => {
      let newInsuranceChange = {
        ...insuranceChange,
        insuredsDob: newInsuredDob
      };
      self.applyWithApiStatus(newInsuranceChange);

      return Promise.resolve('success');
    },

    updateGuarantorName: (newName, insuranceChange) => {
      let newInsuranceChange = { ...insuranceChange, insuredName: newName };
      self.applyWithApiStatus(newInsuranceChange);

      return Promise.resolve('success');
    }
  }))
  .views(self => ({}));

export default types.compose(CommonActions, ApiModel, InsuranceInformation);
