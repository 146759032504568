import { flow, types } from 'mobx-state-tree';
import { CERNER_GET_TOKEN } from '../graphql/queries';
import { CERNER_AUTHENTICATION } from '../graphql/mutations';
import ApiModel from './base/ApiModel';
import { getPropInUrlParams, hasPropInUrlParams } from '../utils/UrlParams';
import { loadScriptsList } from '../utils/LoadExternalScript';

const EmbeddedModel = types
  .model('EmbeddedModel', {
    bcsToken: types.maybeNull(types.string),
    locale: types.maybeNull(types.string),
    sessionId: types.maybeNull(types.string),
    providerInternalName: types.maybeNull(types.string),
    errors: types.maybeNull(types.array(types.string)),
    isEmbedded: types.maybeNull(types.boolean)
  })
  .actions(self => {
    const pollToken = async commandId => {
      console.log('polling token...', commandId);
      return await self.query(
        CERNER_GET_TOKEN,
        {
          commandId: commandId
        },
        { isCritical: false }
      );
    };

    const pollValidation = pollingRes => {
      console.log('Cerner pollValidation', pollingRes);
      // stop only when response is not null
      return !!(
        pollingRes &&
        (pollingRes.cernerAuthenticationDetails.sessionId ||
          !!pollingRes.cernerAuthenticationDetails.errors)
      );
    };

    const scriptsURLs = [
      'https://healthelife.healtheintent.com/healthelife_sdk.js'
    ];

    const embeddedUrl = window.location.href.includes('embedded');

    const isDevOrTest =
      (typeof process.env.REACT_APP_ENV_VAR === 'string' &&
        process.env.REACT_APP_ENV_VAR.trim() === 'simulated') ||
      process.env.NODE_ENV === 'test' ||
      process.env.NODE_ENV === 'development';

    return {
      afterCreate: () => {
        if (hasPropInUrlParams('bcs_token')) {
          if (embeddedUrl) {
            self.isEmbedded = true;
          }
          //extract tenant (provider) as well?
          self.bcsToken = getPropInUrlParams('bcs_token');
          self.locale = getPropInUrlParams('locale');
        }
      },
      getTokenAndProviderName: flow(function* () {
        //polling with command Id until result is received;
        try {
          const { cernerAuthentication } = yield self.mutate(
            CERNER_AUTHENTICATION,
            {
              bcsToken: self.bcsToken
            },
            { isCritical: false }
          );

          const { commandId, errors: authErrors } = cernerAuthentication;

          console.log('----commandId;', commandId);
          console.log('----errors;', authErrors);
          //handle errors here
          if (authErrors && authErrors.length) return authErrors;

          //begin polling
          const pollingRes = yield self.poll(
            () => pollToken(commandId),
            pollValidation
          );

          const { cernerAuthenticationDetails } = pollingRes;

          const {
            errors,
            sessionId,
            providerInternalName,
            issuer
          } = cernerAuthenticationDetails;

          if (!isDevOrTest) {
            yield self.loadCernerSdk(issuer);
          }

          if (errors?.length) {
            self.errors = errors;
            console.log('error!', errors);
            return Promise.reject();
          }
          if (sessionId) {
            self.sessionId = sessionId;
            self.providerInternalName = providerInternalName;
            return Promise.resolve();
          }
        } catch (e) {
          console.error('EmbeddedModel fetch error', e);
          return Promise.reject();
        }
      }),
      loadCernerSdk: flow(function* (issuer = null, options = {}) {
        yield loadScriptsList(scriptsURLs);
        const acls = issuer || '*';
        window.$HL.App.init({
          acls: [acls],
          ...options
        });
      }),
      redirectToEmbedded: () => {
        if (isDevOrTest) {
          console.log('moving to OFFLINE embedded');
          window.location = `app/embedded?bcs_token=${self.bcsToken}&locale=en`;
        } else {
          console.log('moving to embedded');
          window.$HL.App.routeTo({
            path: '/pages/flywire'
          });
        }
      }
    };
  })
  .views(self => ({
    get isPageletMode() {
      return !!self.bcsToken;
    },
    get isEmbeddedPage() {
      return !!self.isEmbedded;
    },
    get pageletErrors() {
      return self.errors;
    },
    get pageletProviderName() {
      return self.providerInternalName;
    },
    get pageletLocale() {
      if (self.locale) {
        return self.locale.split('-', 1)[0];
      } else {
        return 'en';
      }
    }
  }));

export default types.compose(ApiModel, EmbeddedModel);
