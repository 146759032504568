import { flow, types } from 'mobx-state-tree';

import ApiModel from './base/ApiModel';
import CommonActions from './base/CommonActions';
import { GET_MEMBER_PHONE_NUMBER } from '../graphql/queries';
import SessionParams from '../utils/SessionParams';
import {
  RESEND_SMS,
  UPDATE_PHONE_NUMBER,
  REMOVE_PHONE_NUMBER,
  CHANGE_PHONE_SUBSCRIPTION_SETTINGS
} from '../graphql/mutations/notifications';
import { GET_PHONE_NUMBER } from '../graphql/queries/notifications';

const REQUEST_TIME_INTERVAL = 2000;
const MAX_ATTEMPTS = 5;

const PhoneNumber = types.model('PhoneNumber', {
  id: types.string,
  displayValue: types.string,
  number: types.string,
  lineType: types.maybeNull(types.number),
  isOptedIn: types.maybeNull(types.boolean),
  isPending: types.maybeNull(types.boolean),
  smsState: types.maybeNull(types.string),
  allowSubscriptionChange: types.maybeNull(types.boolean),
  createdAt: types.string
});

const MembersPhoneNumber = types
  .model('MembersPhoneNumber', {
    phoneNumber: types.maybeNull(PhoneNumber)
  })
  .actions(self => ({
    load: flow(function* () {
      try {
        const { guarantorOfAccount } = yield self.query(
          GET_MEMBER_PHONE_NUMBER,
          {
            accountNumber: SessionParams.accountNumber
          }
        );
        self.applyWithApiStatus({
          phoneNumber: guarantorOfAccount.phoneNumber
        });
        return Promise.resolve();
      } catch (e) {
        console.error('MembersPhoneNumber load error:', e);
      }
    }),
    updatePhone: flow(function* (number) {
      try {
        console.log(
          '----about to edit phone for guarantorId:',
          SessionParams.memberId
        );
        const res = yield self.mutate(
          UPDATE_PHONE_NUMBER,
          {
            number: number
          },
          { isCritical: false }
        );
        if (res) {
          yield self.load();
        }
        return Promise.resolve();
      } catch (e) {
        console.log('error updating phone number', e);
        return Promise.reject(e);
      }
    }),
    removePhone: flow(function* () {
      try {
        console.log(
          '----about to remove phone for guarantorId:',
          SessionParams.memberId
        );
        const res = yield self.mutate(
          REMOVE_PHONE_NUMBER,
          {},
          { isCritical: false }
        );

        console.log('res', res);
        self.applyWithApiStatus({
          phoneNumber: null
        });
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    }),
    changeSubscriptionState: flow(function* ({ id, isOptedIn }) {
      if (!id) {
        return Promise.reject('updateExistingPhone: must provide id');
      }
      try {
        const { updateSmsSubscription } = yield self.mutate(
          CHANGE_PHONE_SUBSCRIPTION_SETTINGS,
          {
            phoneNumberId: id,
            action: isOptedIn ? 'SUBSCRIBE' : 'UNSUBSCRIBE'
          },
          { isCritical: false }
        );

        if (updateSmsSubscription) {
          self.phoneNumber.isOptedIn = isOptedIn;
          return Promise.resolve();
        }
        return Promise.reject('update sms subscription failed');
      } catch (e) {
        console.error('MemberPhoneNumber Model error', e);
        return Promise.reject(e);
      }
    }),
    getPhoneNumber: flow(function* () {
      console.log('get phone number');
      try {
        const { phoneNumber } = yield self.query(GET_PHONE_NUMBER, {
          id: self.phoneNumber.id
        });
        self.applyWithApiStatus({
          phoneNumber: phoneNumber
        });
        return Promise.resolve();
      } catch (error) {
        console.log('Error getting phone number', error);
        return Promise.reject(error);
      }
    }),
    resendSms: flow(function* () {
      console.log('resendSms...');
      try {
        const res = yield self.mutate(
          RESEND_SMS,
          {
            phoneNumberId: self.phoneNumber.id,
            accountNumber: SessionParams.accountNumber
          },
          { isCritical: false }
        );
        return res.resendSms;
      } catch (error) {
        console.log('Error resending sms', error);
        throw error;
      }
    })
  }));

export default types.compose(CommonActions, ApiModel, MembersPhoneNumber);
