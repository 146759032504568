import { flow, onPatch, types } from 'mobx-state-tree';
import moment from 'moment/moment';
import { omit } from 'lodash';

import { objectKeysToCamelCase } from '../utils/String';
import { isFutureDate, ordinalDayInEng } from '../utils/Date';
import SessionParams from '../utils/SessionParams';
import {
  GET_SAVED_CREDIT_CARDS,
  GET_SAVED_ECHECKS,
  VALIDATE_CREDIT_CARD,
  VALIDATE_ECHECK,
  PAYMENT_PLAN_COMMAND,
  CHECK_CURAE_TRANSACTION_STATUS,
  GET_PAYZEN_LEGAL_DOCS
} from '../graphql/queries';
import {
  CREDIT_CARD_PAYMENT,
  ECHECK_PAYMENT,
  CREATE_PAYMENT_PLAN,
  CONVERT_PAYMENT_PLAN,
  UPDATE_PAYMENT_PLAN,
  PAYPAL_PAYMENT,
  CURAE_PAYMENT,
  PAY_TOWARD_FINANCED_PLAN,
  CREATE_CREDIT_CARD,
  CREATE_ECHECK,
  DIGITAL_WALLET_CREATE_SESSION,
  DIGITAL_WALLET_PAYMENT
} from '../graphql/mutations';
import ApiModel from './base/ApiModel';
import CareCredit from './CareCredit';
import { PAYMENT_STATUS_CHECK } from '../graphql/mutations/payment';
import { sleep } from '../utils/Function';

const CreditCard = types.model('CreditCard', {
  id: types.string,
  expiredAt: types.string,
  last4: types.string,
  cardType: types.string,
  financingCustomerToken: types.maybeNull(types.string, ''),
  financingProcessorToken: types.maybeNull(types.string, ''),
  billingName: types.optional(types.string, '')
});

const Echeck = types.model('Echeck', {
  accountNumber: types.string,
  accountType: types.string,
  billingName: types.string,
  id: types.string,
  routingNumber: types.string,
  financingCustomerToken: types.maybeNull(types.string, ''),
  financingProcessorToken: types.maybeNull(types.string, '')
});

const MONTHLY_FREQUENCY = 'monthly';

const PaymentModel = types
  .model('PaymentModel', {
    isPPv4: types.optional(types.boolean, false),
    supportAppointments: types.optional(types.boolean, false),
    creditCard: types.maybeNull(CreditCard),
    creditCards: types.optional(types.array(CreditCard), []),
    echeck: types.maybeNull(Echeck),
    echecks: types.optional(types.array(Echeck), []),
    gotSavedCreditCards: types.optional(types.boolean, false),
    gotSavedEchecks: types.optional(types.boolean, false),
    hasSavedEcheck: types.optional(types.boolean, false),
    memberId: types.optional(types.string, ''),
    careCreditModel: types.optional(CareCredit, {}),
    payzenPlanTermsLinks: types.optional(types.frozen(), {})
  })
  .actions(self => {
    const PAYMENT_IN_PROGRESS = 0;
    let _ccDetails, _eCheckDetails, _paypalDetails, _getPlanSelectedDataCb;
    const formatScheduledDate = dateStr => moment(dateStr).format('YYYY-MM-DD');

    //return _eCheckDetails without accountNumber, which is a different property
    const getEcheckDetails = (_eCheckDetails, savedEcheckId = null) => {
      const echeckDetails = { echeck: _eCheckDetails };
      const savedEcheckDetails = { savedEcheck: { echeckId: savedEcheckId } };

      return {
        ...(savedEcheckId && savedEcheckDetails),
        ...(_eCheckDetails && echeckDetails)
      };
    };

    const getCreditCardDetails = (ccData = null, isWalletPayment = false) => {
      let ccDetails;
      if (isWalletPayment) {
        ccDetails = ccData;
      } else {
        ccDetails = omit(_ccDetails, 'billingNameCc');
      }
      const creditCardId = ccDetails?.id || self.creditCard?.id;
      return {
        ...(creditCardId && {
          savedCreditCard: { creditCardId: creditCardId }
        }),
        ...((ccDetails?.credit_card_token ||
          ccDetails?.authorization_token) && {
          creditCard: { ...objectKeysToCamelCase(ccDetails) }
        })
      };
    };

    const getEmailParams = emailData => {
      const { isOptedIn, isPaperlessOptedIn, email, isNew } = emailData;
      return {
        emailAddress: {
          email: email || '',
          isNew: isNew || false,
          memberId: parseInt(self.memberId),
          optIn: isOptedIn || false,
          optInPaperless: isPaperlessOptedIn || false
        },
        email,
        changeEmail: !isNew
      };
    };

    const getDiscountParams = discount => {
      const discountToReturn = discount && {
        discount: {
          uniqueIdentifier: discount.uniqueIdentifier,
          amount: discount.amount,
          billsDiscounts: discount.billsDiscounts
        }
      };

      return discountToReturn;
    };

    const fetchPaymentPlan = async commandId => {
      return await self.query(
        PAYMENT_PLAN_COMMAND,
        {
          id: commandId
        },
        { isCritical: false }
      );
    };
    const fetchPpValidation = ({ paymentPlanCommand }) => {
      return !!paymentPlanCommand;
    };
    const curaePollValidation = ({ checkCuraeTransactionStatus }) => {
      return checkCuraeTransactionStatus != PAYMENT_IN_PROGRESS;
    };
    const getPaymentPlanParams = ppData => {
      const {
        amount,
        installmentDate,
        installments,
        fullAmount,
        installmentDateStart,
        lastInstallment,
        billIds,
        claimNumbers,
        isCustomPlan,
        genericIdentifier,
        totalPaidBalance,
        isOverridingFinancedPlan,
        appointmentsPlanParams
      } = ppData;

      const fullOffer = self.isPPv4
        ? _getPlanSelectedDataCb(installments, isCustomPlan)
        : null;

      const formattedInstallmentDateStart = moment(
        installmentDateStart,
        'MM/DD/YYYY'
      );

      const updatedInstallmentDate =
        formattedInstallmentDateStart.date() <= moment().date() &&
        formattedInstallmentDateStart.month() === moment().month() &&
        !fullOffer?.payzenOfferId
          ? moment(installmentDateStart).add(1, 'month').format('MM/DD/YYYY')
          : installmentDateStart;

      return {
        paymentPlan: {
          amountPerInstallment: fullOffer?.amountPerInstallment || amount,
          installmentsNumber: installments,
          payableId: SessionParams.payableId,
          frequency: MONTHLY_FREQUENCY, //static!
          frequencyDetails: {
            dateOfMonth: ordinalDayInEng(parseInt(installmentDate))
          },
          paymentAuthSignedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
          ...(billIds?.length && {
            billIds: billIds.toJSON()
          }),
          ...((self.isPPv4 || billIds?.length) && {
            totalAmount: fullOffer?.totalBalance || fullAmount
          }),
          startDate: formatScheduledDate(updatedInstallmentDate),
          lastInstallmentAmount:
            fullOffer?.lastInstallmentAmount || lastInstallment,
          accountNumber: SessionParams.accountNumber,
          ...(self.isPPv4 && { claimNumbers: claimNumbers.toJSON() }),
          ...(fullOffer?.serviceFee && {
            serviceFeeAmount: fullOffer?.serviceFee
          }),
          payzenOfferId: fullOffer?.payzenOfferId,
          genericIdentifier: genericIdentifier,
          totalPaidBalance: totalPaidBalance,
          isOverridingFinancedPlan: isOverridingFinancedPlan,
          ...(self.supportAppointments ? appointmentsPlanParams : {})
        }
      };
    };

    const getPaymentMethodError = code => {
      switch (code) {
        case 22:
          return 'failure';
        case 23:
          return 'cvv_invalid';
        case 24:
          return 'avs';
        case 25:
          return 'avs_and_cvv';
        case 26:
          return 'invalid_bank_routing_number';
        default:
          return 'failure';
      }
    };

    const handleCreditCardFailureError = rawError => {
      let failureReason = rawError.split('Failure: ')[1];
      let error = JSON.parse(failureReason)[0].error;
      return Promise.reject(getPaymentMethodError(error.code));
    };

    const fetchTransactionStatus = async transactionId => {
      console.log('fetchTransactionStatus', transactionId);
      return await self.query(
        CHECK_CURAE_TRANSACTION_STATUS,
        {
          transactionId: transactionId
        },
        { isCritical: false }
      );
    };

    const pollPaymentPlanCommandId = flow(function* (commandId) {
      try {
        const { paymentPlanCommand } = yield self.poll(
          () => fetchPaymentPlan(commandId),
          fetchPpValidation
        );
        if (paymentPlanCommand.errors?.length) {
          const rawError = paymentPlanCommand.errors[0];
          const isCreditCardError = rawError.startsWith('Failure: ');
          if (isCreditCardError) {
            return handleCreditCardFailureError(rawError);
          }
          //TODO:handle more errors here
          return Promise.reject();
        }
        return Promise.resolve(paymentPlanCommand);
      } catch (error) {
        return Promise.reject(error);
      }
    });

    const pollCuraeTransactionId = flow(function* (transactionId) {
      try {
        const transactionStatus = yield self.poll(
          () => fetchTransactionStatus(transactionId),
          curaePollValidation
        );
        return Promise.resolve(transactionStatus);
      } catch (error) {
        return Promise.reject(error);
      }
    });

    const getCommonPaymentParams = (
      amount,
      billIds,
      discount,
      appointmentsAmount = 0,
      billIdsOnPaymentPlan = []
    ) => {
      const billsAmount = amount - appointmentsAmount;
      return {
        paymentAttributes: {
          payableId: SessionParams.payableId,
          ...(billIds?.length && { applyToBills: billIds }),
          amount: billsAmount,
          paymentAuthSignedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
          ...(self.supportAppointments && {
            billsOnPaymentPlan: billIdsOnPaymentPlan
          }),
          ...getDiscountParams(discount)
        }
      };
    };

    return {
      resetCreditCard: () => {
        self.creditCard = null;
      },
      resetEcheck: () => {
        self.echeck = null;
      },
      setIsPPv4: val => {
        self.isPPv4 = val;
      },
      setSupportAppointments: val => {
        self.supportAppointments = val;
      },
      setGetPlanSelectedData: cb => {
        _getPlanSelectedDataCb = cb;
      },
      onPaymentComplete: () => {
        self.gotSavedCreditCards = false;
        self.gotSavedEchecks = false;
      },
      getSavedCreditCards: flow(function* (isTowardsFinancedPlan = false) {
        if (self.gotSavedCreditCards) return Promise.resolve();
        if (!self.memberId) {
          console.log('memberId not set, waiting....');
          yield self.listenToMemberIdChange(self.getSavedCreditCards);
        }
        try {
          self.dataApplied = false;
          const { creditCards } = yield self.query(GET_SAVED_CREDIT_CARDS, {
            memberId: self.memberId,
            isTowardsFinancedPlan
          });
          self.applyWithApiStatus({ creditCards });
          self.gotSavedCreditCards = true;
          return Promise.resolve();
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject();
        }
      }),
      getSavedEchecks: flow(function* () {
        if (self.gotSavedEchecks) return Promise.resolve();
        if (!self.memberId) {
          console.log('memberId not set, waiting....');
          yield self.listenToMemberIdChange(self.getSavedEchecks);
        }
        try {
          self.dataApplied = false;
          const { echecks } = yield self.query(GET_SAVED_ECHECKS, {
            memberId: self.memberId
          });
          self.applyWithApiStatus({ echecks });
          self.gotSavedEchecks = true;
          self.hasSavedEcheck = echecks.length > 0;
          return Promise.resolve();
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject();
        }
      }),
      createCreditCard: flow(function* (ccData, memberId) {
        let transformedObj =
          ccData.id != null ? null : objectKeysToCamelCase(ccData);
        try {
          const { creditCard } = yield self.mutate(CREATE_CREDIT_CARD, {
            ...(ccData.id && { cardId: parseInt(ccData.id) }),
            ...{ memberId: parseInt(memberId) },
            ...(transformedObj && { creditCard: transformedObj })
          });
          self.applyWithApiStatus({ creditCard });
          return creditCard;
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject();
        }
      }),
      createEcheck: flow(function* (eCheckData, memberId) {
        try {
          const { echeck } = yield self.mutate(
            CREATE_ECHECK,
            {
              ...(eCheckData.id && { echeckId: parseInt(eCheckData.id) }),
              ...(memberId && { memberId: parseInt(memberId) }),
              ...{ echeck: eCheckData }
            },
            { isCritical: false }
          );
          self.applyWithApiStatus({ echeck });
          return echeck;
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject();
        }
      }),
      validateCreditCard: flow(function* (ccData) {
        // cardId
        // billing_name: "aa"
        // billing_zip: "10304"
        // card_type: "visa"
        // credit_card_response_code: "0000"
        // credit_card_token: "prGLvcQ5E8wn5fmpLRvjluMK8YTWWEIqMj9L2IjlqqvPbMvX5kZe8yMJLK-CaPbEDR0"
        // expired_at: "21/11/1"
        // last_4: "1111"
        // litle_response_message: ""
        // litle_response_time: ""
        // litle_response_type: ""
        // litle_transaction_id: ""
        // physically_swiped: ""
        // save_card_for_guarantor: "false"
        let transformedObj;
        if (ccData.credit_card_token && ccData.credit_card_response_code) {
          ccData.physically_swiped = ccData.physically_swiped === 'true';
          //change '05/22 to 2022/05/1'
          ccData.save_card_for_guarantor =
            ccData.save_card_for_guarantor.toString();
          transformedObj = objectKeysToCamelCase(ccData);
        } else if (!ccData?.id) {
          //no correct CC data found
          return yield Promise.reject();
        }

        try {
          const { validateCreditCard } = yield self.query(
            VALIDATE_CREDIT_CARD,
            {
              ...(ccData.id && { cardId: parseInt(ccData.id) }), //parseInt() is temp!
              ...(transformedObj && { creditCard: transformedObj })
            }
          );
          //erros:["expired_at"]
          //valid: true
          const { valid } = validateCreditCard;
          valid && (_ccDetails = ccData);
          return Promise.resolve(validateCreditCard);
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject();
        }
      }),
      validateEcheck: flow(function* (eCheckData) {
        // billingName:"Yuval Test",
        // routingNumber:"000000123",
        // accountNumber:"000000456",
        // accountNumberConfirmation: "000000456",
        // accountType:"savings"
        try {
          const { validateEcheck } = yield self.query(
            VALIDATE_ECHECK,
            {
              echeck: {
                ...eCheckData
              }
            },
            { isCritical: false }
          );
          if (validateEcheck.valid) {
            _eCheckDetails = {
              ...eCheckData
            };
          }
          return Promise.resolve({
            ...validateEcheck
          });
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject();
        }
      }),
      validatePaypalDetails: flow(function* (paypalData) {
        try {
          _paypalDetails = {
            processorToken: paypalData.nonce,
            email: paypalData.details.email,
            firstName: paypalData.details.firstName,
            lastName: paypalData.details.lastName
          };

          if (paypalData.details.billingAddress) {
            _paypalDetails.postcode =
              paypalData.details.billingAddress.postalCode;
            _paypalDetails.state = paypalData.details.billingAddress.state;
          }
          return Promise.resolve({ valid: true });
        } catch (e) {
          console.error('Cant set Paypal Details');
          return Promise.reject();
        }
      }),
      performCreditCardPayment: flow(function* (
        amount,
        date,
        emailData,
        billIds,
        feeAmount,
        scheduledPaymentEnabled,
        discount,
        isTowardsPayzenPlan,
        appointmentsParams,
        billIdsOnPaymentPlan
      ) {
        try {
          const appointmentsAmount =
            appointmentsParams?.appointmentsPaymentsAttributes?.amount || 0;
          const creditCardDetails = getCreditCardDetails();
          const commonParams = getCommonPaymentParams(
            amount,
            billIds,
            discount,
            appointmentsAmount,
            billIdsOnPaymentPlan
          );
          let { creditCardPayment } = yield self.mutate(
            CREDIT_CARD_PAYMENT,
            {
              ...getEmailParams(emailData),
              ...commonParams,
              feeAmount,
              ...creditCardDetails,
              scheduledOn:
                scheduledPaymentEnabled && isFutureDate(date)
                  ? formatScheduledDate(date)
                  : null,
              isTowardsPayzenPlan: isTowardsPayzenPlan,
              ...appointmentsParams
            },
            { isCritical: false, skipRetry: true }
          );
          if (creditCardPayment?.pullPaymentStatus) {
            const requestId = creditCardPayment?.pullPaymentRequestId;
            creditCardPayment = yield self.performaPullPaymentCheckStatus(
              requestId
            );
          }
          if (creditCardPayment.errors?.length) {
            const specificError = creditCardPayment.errors[2];
            const generalError = creditCardPayment.errors[0];
            const error = specificError || generalError;
            return yield Promise.reject(error);
          }
          return Promise.resolve(creditCardPayment.payment.uniqueId);
        } catch (e) {
          console.error('Payments Model error', e);
          return yield Promise.reject(e);
        }
      }),
      performaPullPaymentCheckStatus: flow(function* (requestId) {
        try {
          for (let index = 0; index < 10; index++) {
            const { paymentStatusCheck } = yield self.query(
              PAYMENT_STATUS_CHECK,
              {
                requestId
              },
              { isCritical: false, skipRetry: true }
            );
            if (paymentStatusCheck?.status === 'failed') {
              return yield Promise.reject(paymentStatusCheck?.response);
            } else if (paymentStatusCheck.status === 'success') {
              return yield Promise.resolve({
                payment: { uniqueId: paymentStatusCheck?.response?.unique_id },
                errors: paymentStatusCheck?.response?.errors || []
              });
            }
            yield sleep(1000);
          }
        } catch (e) {
          return yield Promise.reject(e);
        }
      }),
      performEcheckPayment: flow(function* (
        amount,
        emailData,
        billIds,
        feeAmount,
        discount,
        isTowardsPayzenPlan,
        eCheckData,
        appointmentsParams,
        billIdsOnPaymentPlan
      ) {
        try {
          if (!!eCheckData?.isSavedEcheckPayment) {
            let { id, isSavedEcheckPayment, ...filteredEcheckData } =
              eCheckData;
            yield self.validateEcheck(filteredEcheckData);
          }
          const appointmentsAmount =
            appointmentsParams?.appointmentsPaymentsAttributes?.amount || 0;
          const { echeckPayment } = yield self.mutate(
            ECHECK_PAYMENT,
            {
              ...getEmailParams(emailData),
              ...getCommonPaymentParams(
                amount,
                billIds,
                discount,
                appointmentsAmount,
                billIdsOnPaymentPlan
              ),
              feeAmount,
              ...getEcheckDetails(_eCheckDetails),
              isTowardsPayzenPlan: isTowardsPayzenPlan,
              ...appointmentsParams
            },
            { isCritical: false, skipRetry: true }
          );

          return Promise.resolve(echeckPayment.payment.uniqueId);
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject();
        }
      }),
      performPaypalPayment: flow(function* (
        amount,
        emailData,
        billIds,
        discount,
        appointmentsParams,
        billIdsOnPaymentPlan
      ) {
        try {
          const appointmentsAmount =
            appointmentsParams?.appointmentsPaymentsAttributes?.amount || 0;
          const { paypalPayment } = yield self.mutate(
            PAYPAL_PAYMENT,
            {
              ...getEmailParams(emailData),
              ...getCommonPaymentParams(
                amount,
                billIds,
                discount,
                appointmentsAmount,
                billIdsOnPaymentPlan
              ),
              paypal: { ..._paypalDetails },
              ...appointmentsParams
            },
            { isCritical: false, skipRetry: true }
          );

          return Promise.resolve(paypalPayment.payment.uniqueId);
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject();
        }
      }),
      performCuraePayment: flow(function* (
        amount,
        emailData,
        billIds,
        feeAmount,
        discount,
        handleTransactionStatusCallback,
        appointmentsParams,
        billIdsOnPaymentPlan
      ) {
        try {
          const appointmentsAmount =
            appointmentsParams?.appointmentsPaymentsAttributes?.amount || 0;
          const { curaePayment } = yield self.mutate(
            CURAE_PAYMENT,
            {
              ...getEmailParams(emailData),
              ...getCommonPaymentParams(
                amount,
                billIds,
                discount,
                appointmentsAmount,
                billIdsOnPaymentPlan
              ),
              feeAmount,
              ...appointmentsParams
            },
            { isCritical: false, skipRetry: true }
          );
          const { checkCuraeTransactionStatus } = yield pollCuraeTransactionId(
            curaePayment.curaeTransactionId
          );
          handleTransactionStatusCallback(checkCuraeTransactionStatus);
          return curaePayment.curaeTransactionId;
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject();
        }
      }),
      createOrUpdatePaymentPlan: flow(function* (
        ppData,
        emailData,
        savedEcheckId = null,
        ccData = null,
        isWalletPayment = false
      ) {
        if (
          !self.isPPv4 ||
          (self.isPPv4 &&
            (!ppData.paymentPlanId || ppData.isOverridingFinancedPlan))
        ) {
          return yield self.createPaymentPlan(
            ppData,
            emailData,
            savedEcheckId,
            ccData,
            isWalletPayment
          );
        }
        return yield self.updateExistingPaymentPlan(
          ppData,
          emailData,
          savedEcheckId,
          ccData,
          isWalletPayment
        );
      }),
      createPaymentPlan: flow(function* (
        ppData,
        emailData,
        savedEcheckId,
        ccData,
        isWalletPayment
      ) {
        try {
          const { createPaymentPlan } = yield self.mutate(
            CREATE_PAYMENT_PLAN,
            {
              ...getEmailParams(emailData),
              ...getCreditCardDetails(ccData, isWalletPayment),
              ...getEcheckDetails(_eCheckDetails, savedEcheckId),
              ...getPaymentPlanParams(ppData),
              paymentPlanId: ppData.paymentPlanId || null,
              ...((self.supportAppointments && { version: '1-1' }) || {})
            },
            { isCritical: false, skipRetry: true }
          );

          if (self.isPPv4) {
            return pollPaymentPlanCommandId(createPaymentPlan.commandId);
          } else {
            //PPv2
            if (createPaymentPlan.paymentPlan.uniqueId) {
              return Promise.resolve({});
            } else {
              return Promise.reject();
            }
          }
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject(e);
        }
      }),
      updateExistingPaymentPlan: flow(function* (
        ppData,
        emailData,
        savedEcheckId,
        ccData,
        isWalletPayment
      ) {
        try {
          const { updatePaymentPlan } = yield self.mutate(
            UPDATE_PAYMENT_PLAN,
            {
              ...getEmailParams(emailData),
              ...getCreditCardDetails(ccData, isWalletPayment),
              ...getEcheckDetails(_eCheckDetails, savedEcheckId),
              ...getPaymentPlanParams(ppData),
              paymentPlanId: ppData.paymentPlanId,
              ...((self.supportAppointments && { version: '1-1' }) || {})
            },
            { isCritical: false, skipRetry: true }
          );
          return yield pollPaymentPlanCommandId(updatePaymentPlan.commandId);
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject(e);
        }
      }),
      convertPaymentPlan: flow(function* (
        paymentPlanId,
        emailData,
        ccData = null,
        isWalletPayment = false
      ) {
        try {
          const { editPaymentPlanBilling } = yield self.mutate(
            CONVERT_PAYMENT_PLAN,
            {
              ...getEmailParams(emailData),
              ...getCreditCardDetails(ccData, isWalletPayment),
              ...getEcheckDetails(_eCheckDetails),
              paymentPlanId,
              accountNumber: SessionParams.accountNumber
            },
            { isCritical: false, skipRetry: true }
          );

          if (self.isPPv4) {
            return yield pollPaymentPlanCommandId(
              editPaymentPlanBilling.commandId
            );
          } else {
            //PPv2
            if (editPaymentPlanBilling.paymentPlan.uniqueId) {
              return Promise.resolve();
            }
            return Promise.reject();
          }
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject();
        }
      }),
      clearPaymentData: () => {
        _ccDetails = null;
        _eCheckDetails = null;
      },
      setMemberId: id => {
        self.memberId = id;
      },
      listenToMemberIdChange: flow(function* () {
        return new Promise(resolve => {
          onPatch(self, patch => {
            if (patch.path === '/memberId') {
              resolve();
            }
          });
        });
      }),
      payTowardFinancedPlan: flow(function* ({
        amount,
        payableId,
        genericIdentifier
      }) {
        try {
          const { payTowardFinancedPlan } = yield self.mutate(
            PAY_TOWARD_FINANCED_PLAN,
            {
              amount,
              payableId,
              genericIdentifier,
              memberId: self.memberId,
              ...getCreditCardDetails(),
              ...getEcheckDetails(_eCheckDetails)
            },
            { isCritical: false, skipRetry: true }
          );
          return Promise.resolve(payTowardFinancedPlan);
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject(e);
        }
      }),
      getPayzenLegalDocs: flow(function* (
        accountHolderName,
        accountNumber,
        company,
        offerId,
        paymentInformation,
        paymentMethodData
      ) {
        try {
          const { payzenLegalDocs } = yield self.query(
            GET_PAYZEN_LEGAL_DOCS,
            {
              accountHolderName,
              accountNumber,
              company,
              offerId,
              paymentInformation,
              paymentMethodData
            },
            { isCritical: false, skipRetry: true }
          );
          self.applyWithApiStatus({
            payzenPlanTermsLinks: payzenLegalDocs.links
          });
          return Promise.resolve();
        } catch (e) {
          console.error('Payments Model error', e);
          return Promise.reject(e);
        }
      }),
      createDigitalWalletSession: flow(function* ({
        walletType,
        isPaymentPlan,
        initiativeContextUrl
      }) {
        try {
          const { digitalWalletCreateSession } = yield self.mutate(
            DIGITAL_WALLET_CREATE_SESSION,
            {
              walletType,
              isPaymentPlan,
              initiativeContextUrl
            },
            { isCritical: false, skipRetry: true }
          );
          return Promise.resolve(
            digitalWalletCreateSession.sessionResponse['session_object']
          );
        } catch (e) {
          console.error('Digital wallet session creation error', e);
          return Promise.reject(e);
        }
      }),
      performDigitalWalletPayment: flow(function* (
        amount,
        emailDetails,
        paymentSelectedBillsIds,
        discount,
        digitalWalletData
      ) {
        try {
          const { digitalWalletPayment } = yield self.mutate(
            DIGITAL_WALLET_PAYMENT,
            {
              ...getEmailParams(emailDetails),
              ...getCommonPaymentParams(
                amount,
                paymentSelectedBillsIds,
                discount
              ),
              digitalWallet: {
                ...digitalWalletData
              }
            },
            { isCritical: false, skipRetry: true }
          );
          return Promise.resolve(digitalWalletPayment.payment.uniqueId);
        } catch (e) {
          console.error('Digital wallet payment error', e);
          return Promise.reject(e);
        }
      })
    };
  });

export default types.compose(ApiModel, PaymentModel);
