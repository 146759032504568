import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export const GET_PROVIDER_NAME = gql`
  query providerName {
    providerName ${CLIENT} {
      name
    }
  }
`;

export const GET_PROVIDER_DETAILS = gql`
  query providerDetails($provider: String!) {
    providerDetails(provider: $provider) ${CLIENT} {
      name
      internalName
      companyInternalName
      cautiousLogin
      phone
      email
      facilityName
    }
  }
`;

export const GET_PROVIDER_LINKS = gql`
  query providerLinks {
    providerLinks ${CLIENT} {
      helpLink
      providerMessageLink
    }
  }
`;
