import { applySnapshot, flow, getSnapshot, types } from 'mobx-state-tree';
import ApiModel from './base/ApiModel';
import {
  LOGIN,
  LOGOUT,
  IS_LOGGED_IN,
  LOGIN_DURATION_MONITOR,
  LOGIN_FROM_MY_CHART,
  LOGIN_WITH_JWT
} from '../graphql/mutations';
import { GET_LOGIN_FIELDS } from '../graphql/queries';
import { objectKeysToCamelCase } from '../utils/String';
import queryString from 'querystring';
import { hasProp } from '../utils/Object';
import { camelCase } from 'lodash';
import { getRandomId } from '../graphql/client';
import { postOnLoginChangeMessage } from '../utils/Broadcast';

const AUTH_BY_ZIP = 'zip';
const AUTH_BY_SSN = 'ssn';

const LoginModel = types.model('SessionLogin', {
  acceptedTerms: types.optional(types.boolean, false),
  dateOfBirth: types.maybeNull(types.string),
  accountNumber: types.maybeNull(types.string),
  zip: types.maybeNull(types.string),
  ssn: types.maybeNull(types.string),
  captchaKey: types.maybeNull(types.string),
  rememberMe: types.optional(types.boolean, false),
  lastName: types.maybeNull(types.string)
});

const PreAuthenticationIdentifiers = types.model(
  'PreAuthenticationIdentifiers',
  {
    notification: types.maybeNull(types.string),
    notificationId: types.maybeNull(types.string),
    billId: types.maybeNull(types.string)
  }
);

const SessionModel = types
  .model('SessionModel', {
    isLoggedIn: types.optional(types.boolean, false),
    isMyChartLoggedIn: types.maybeNull(types.boolean),
    myChartLoginPath: types.optional(types.boolean, false),
    loginModel: types.optional(LoginModel, {}),
    isLoginProcessDone: types.optional(types.boolean, false), //True after login true and data loaded
    isLoggedInWithJwtToken: types.optional(types.boolean, false),
    preAuthenticationIdentifiers: types.optional(
      PreAuthenticationIdentifiers,
      {}
    ),
    isPreAuthenticationProcess: types.optional(types.boolean, false),
    authenticationBy: types.optional(types.string, 'accountNumber'),
    payableId: types.maybeNull(types.string),
    isConvertFromMail: types.optional(types.boolean, false),
    requestId: types.optional(types.string, getRandomId()),
    linkFromBO: types.optional(types.boolean, false),
    boUserEmail: types.maybeNull(types.string)
  })
  .views(self => ({
    get sessionSource() {
      if (self.isPreAuthenticationProcess) {
        return 'pre authentication';
      } else if (self.isLoggedInWithJwtToken) {
        return 'SSO - Affiliates';
      } else if (self.linkFromBo) {
        return 'linked from BO';
      } else {
        return 'Organic';
      }
    },
    isZipAuth: () => self.authenticationBy == AUTH_BY_ZIP,
    isSsnAuth: () => self.authenticationBy == AUTH_BY_SSN,
    isAccountAuth: () => !self.isZipAuth() && !self.isSsnAuth()
  }))
  .actions(self => {
    let _initialState = {};
    return {
      afterCreate: () => {
        _initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self, _initialState);
      },
      setIsLoginProcessDone: async (
        provider = null,
        company = null,
        payableIds = null,
        shouldSendDurationMonitor = false
      ) => {
        self.isLoginProcessDone = true;
        if (shouldSendDurationMonitor) {
          const durationMonitorParams = {
            provider: provider,
            company: company,
            payableIds: payableIds,
            browser: self.checkBrowserType(),
            device: navigator.platform
          };
          await self.mutate(
            LOGIN_DURATION_MONITOR,
            { ...durationMonitorParams },
            { isCritical: false }
          );
        }
      },
      setIsLoggedIn: val => {
        self.isLoggedIn = val;
        val && self.setIsLoginProcessDone();
      },
      setLoginFields: (prop, val) => {
        if (hasProp(self.loginModel, prop) && self.loginModel[prop] !== val) {
          self.loginModel[prop] = val;
        } else if (!hasProp(self.loginModel, prop)) {
          console.warn(
            `SessionModel prop ${prop} has not been declared in model and will not be saved`
          );
        }
      },
      setMultipleLoginFields: values => {
        if (!values) return;
        for (const key of Object.keys(values)) {
          self.setLoginFields(key, values[key]);
        }
      },
      setIsConvertFromMail: val => {
        self.isConvertFromMail = val;
      },
      verifyRegularSession: flow(function* (provider, billId) {
        const { verifyOrClearSession } = yield self.mutate(
          IS_LOGGED_IN,
          {
            provider: provider,
            billId: billId
          },
          { isCritical: false, skipRetry: true, ignoreErrorLogs: true }
        );
        const { hasSession, boUserEmail } = verifyOrClearSession;
        self.boUserEmail = boUserEmail;
        return hasSession;
      }),
      verifySessionWithJwtToken: flow(function* (provider, jwtToken) {
        const { loginWithJwt } = yield self.mutate(
          LOGIN_WITH_JWT,
          {
            provider: provider,
            token: jwtToken
          },
          { isCritical: false, skipRetry: true, ignoreErrorLogs: true }
        );
        return loginWithJwt;
      }),
      checkIsLoggedIn: flow(function* (provider) {
        if (!provider) {
          self.isLoggedIn = false;
        } else {
          try {
            const results = self.getQueryParams();
            let billId, jwtToken, is;
            if (results) {
              billId = results.billId;
              jwtToken = results.jwtToken;
            }
            if (jwtToken) {
              self.isLoggedIn = yield self.verifySessionWithJwtToken(
                provider,
                jwtToken
              );
              self.isLoggedInWithJwtToken = self.isLoggedIn;
            } else {
              self.isLoggedIn = yield self.verifyRegularSession(
                provider,
                billId
              );
            }
            console.log(
              `---SessionModel verified session for ${provider}, value: ${self.isLoggedIn}`
            );
          } catch (e) {
            console.log(
              `---SessionModel mutate verify session for: ${provider} failed due to ${e}`
            );
            self.isLoggedIn = false;
          }
        }
      }),
      loginFromMyChart: flow(function* (provider) {
        try {
          const results = self.getQueryParams();
          let accountNumber, timestamp, hash;
          if (results) {
            accountNumber = results.guarantorAccountId;
            timestamp = results.timestamp;
            hash = results.hash;
          } else {
            console.log(
              '---SessionModel MyChart trying to login without parameters'
            );
            self.isLoggedIn = false;
            self.isMyChartLoggedIn = false;
            return false;
          }

          console.log('---SessionModel MyChart verify login for: ', provider);
          const { loginFromMyChart } = yield self.mutate(
            LOGIN_FROM_MY_CHART,
            {
              provider: provider,
              accountNumber: accountNumber,
              timestamp: timestamp,
              hash: hash
            },
            { isCritical: false, skipRetry: true }
          );
          self.isLoggedIn = loginFromMyChart;
          self.isMyChartLoggedIn = loginFromMyChart;
          console.log(
            `---SessionModel verified mychart login for ${provider}, value: ${self.isLoggedIn}`
          );
        } catch (e) {
          console.log(
            `---SessionModel mutate verify mychart login for: ${provider} failed due to ${e}`
          );
          self.isLoggedIn = false;
          self.isMyChartLoggedIn = false;
          self.myChartLoginPath = false;
        } finally {
          console.log(
            `---SessionModel myChart login status for ${provider} is ${self.isLoggedIn}`
          );
          return self.isLoggedIn;
        }
      }),

      afterAttach: () => {
        self.myChartLoginPath =
          window.location.pathname.includes('/payer/oauth');
      },

      login: flow(function* (provider, loginValues = {}) {
        try {
          console.log('---SessionModel Trying to login... ');
          const { login } = yield self.mutate(
            LOGIN,
            {
              //TODO IL-67 need to remove self.loginModel in the future after removing the old form
              ...self.loginModel,
              ...loginValues,
              provider: provider,
              payableIds: [self.payableId]
            },
            { isCritical: false, skipRetry: true } // Do not crash when can't login
          );
          self.isLoggedIn = login;
          postOnLoginChangeMessage();
        } catch (e) {
          self.isLoggedIn = false;
        } finally {
          console.log(
            `---SessionModel login status for ${provider} is ${self.isLoggedIn}`
          );
          return self.isLoggedIn;
        }
      }),
      logout: flow(function* () {
        self.setIsLoggedIn(false);
        yield self.mutate(LOGOUT, {}, { isCritical: false });
        postOnLoginChangeMessage();
      }),
      getQueryParams: () => {
        try {
          const { href } = window.location;
          const params = href.split('?')[1];
          let results;

          // Be sure url params exist
          if (params && params !== '') {
            results = objectKeysToCamelCase(queryString.parse(params));
          }

          return results;
        } catch {
          return false;
        }
      },
      setPreAuthenticationIdentifier: flow(function* (provider) {
        const results = self.getQueryParams();
        if (results) {
          applySnapshot(self.preAuthenticationIdentifiers, results);

          // Go to server only if notificationId or billId exists
          if (
            self.preAuthenticationIdentifiers.notificationId ||
            self.preAuthenticationIdentifiers.billId
          ) {
            const { loginFields } = yield self.query(
              GET_LOGIN_FIELDS,
              {
                ...self.preAuthenticationIdentifiers,
                provider: provider
              },
              { isCritical: false }
            );

            if (!loginFields.error) {
              self.authenticationBy = camelCase(loginFields.loginField);
              self.payableId = loginFields.payableId;
              self.isPreAuthenticationProcess = true;
            }
          }
        }
      }),
      checkBrowserType: () => {
        const userAgent = navigator.userAgent;
        const chromeAgent = userAgent.indexOf('Chrome') > -1;

        if (chromeAgent) {
          return 'Chrome';
        } else if (
          userAgent.indexOf('MSIE') > -1 ||
          userAgent.indexOf('rv:') > -1
        ) {
          return 'IE';
        } else if (userAgent.indexOf('FireFox') > -1) {
          return 'FireFox';
        } else if (userAgent.indexOf('OP') > -1) {
          return 'Opera';
        } else if (userAgent.indexOf('Safari') > -1) {
          return 'Safari';
        } else {
          return userAgent;
        }
      },
      setSessionDataFromQueryParams: () => {
        const results = self.getQueryParams();
        if (results) {
          self.setIsConvertFromMail(!!results.convertAutoPlan);
          self.linkFromBo = !!results.fromBo;
        }
      }
    };
  });

export default types.compose(ApiModel, SessionModel);
