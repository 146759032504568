import { types } from 'mobx-state-tree';
import { loginParams } from '../constants/login';
const { TOKEN, PROVIDER_ROUTE } = loginParams;

const AffiliatePayable = types
  .model('AffiliatePayable', {
    providerName: types.string,
    providerInternalName: types.string,
    standingAmount: types.number,
    token: types.string,
    url: types.string
  })
  .views(self => ({
    get urlWithToken() {
      //url will look like this: omega_med.simpleepay.com/app/providers/omega_med?jwtToken=123
      const urlWithToken =
        self.url +
        PROVIDER_ROUTE +
        self.providerInternalName +
        '?' +
        TOKEN +
        '=' +
        self.token;
      return urlWithToken;
    }
  }));

export default AffiliatePayable;
