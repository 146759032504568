import { flow, types } from 'mobx-state-tree';
import { GET_PROVIDER_DETAILS } from '../graphql/queries';
import ApiModel from './base/ApiModel';
import SurveyMonkeyModel from './SurveyMonkey';

const Provider = types
  .model('Provider', {
    id: types.maybeNull(types.string),
    address1: types.maybeNull(types.string),
    address2: types.maybeNull(types.string),
    contactLink: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    hoursOfOperation: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    internalName: types.maybeNull(types.string),
    facilityName: types.maybeNull(types.string),
    companyInternalName: types.maybeNull(types.string),
    cautiousLogin: types.maybeNull(types.boolean),
    phone: types.maybeNull(types.string),
    tollFreePhone: types.maybeNull(types.string),
    surveyMonkeyModel: types.maybeNull(SurveyMonkeyModel)
  })
  .actions(self => ({
    loadWithoutSession: flow(function* (providerName) {
      try {
        const { providerDetails } = yield self.query(
          GET_PROVIDER_DETAILS,
          {
            provider: providerName
          },
          { isCritical: false }
        );

        self.applyWithApiStatus(providerDetails);
        self.setSurveyMonkeyModel();
        return Promise.resolve();
      } catch (e) {
        console.error('Provider details fetch error', e);
        return Promise.reject();
      }
    }),
    setSurveyMonkeyModel: () => {
      self.surveyMonkeyModel = SurveyMonkeyModel.create({
        providerName: self.internalName,
        companyName: self.companyInternalName
      });
    }
  }))
  .views(self => ({
    get address() {
      return self.address1
        ? self.address1 + (self.address2 ? ` ,${self.address2}` : '')
        : '';
    },
    get getSurveyMonkeyParams() {
      return self.surveyMonkeyModel.getBaseUrlParams();
    }
  }));

export default types.compose(ApiModel, Provider);
