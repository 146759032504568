import { flow, types } from 'mobx-state-tree';
import { currencyToNumber } from '../utils/Number';

import {
  CC_GET_AUTHENTICATION_TOKEN,
  CC_GET_TRANSACTION_DETAILS,
  GET_MEMBERS_ADDRESS_INFO,
  GET_PAYABLE_STANDING_AMOUNT
} from '../graphql/queries';
import {
  CC_AUTHENTICATION,
  CC_TRANSACTION,
  CC_LOOKUP
} from '../graphql/mutations';
import ApiModel from './base/ApiModel';
import SessionParams from '../utils/SessionParams';

const AuthenticationErrors = {
  tokenNotFound: 'Token Not Found',
  authenticationDetailsError: 'Error while fetching authentication Details',
  mutationError: 'Failed to get command ID',
  commandIdMissing: 'Command ID Missing',
  maxAttemptsExceeded: 'max attempts exceeded',
  unresolvedError: 'Unresolved Error',
  transactionDetailsError: 'Error while fetching transaction Details',
  zeroBalance: 'zero_balance',
  standingAmountDiscrepancyErrorHeader: 'Your balance has changed.',
  standingAmountDiscrepancyDetails:
    'A recent payment has been applied to your account and changed your balance. To ensure you are paying toward the most up to date balance, you will be taken back to the dashboard.',
  getStandingAmountErrorHeader: 'Failed to get Standing Amount',
  zeroBalanceDetails:
    'Your current balance is zero, so payment is not possible at this time. Please return to the previous page to review the current status of your account.'
};

const defaultCardHolderValues = {
  address: '',
  address2: '',
  city: '',
  externalAccountId: '',
  firstName: '',
  lastName: '',
  state: '',
  zipCode: ''
};

const careCreditCardHolder = types.model('careCreditCardHolder', {
  address: types.maybeNull(types.string),
  address2: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  externalAccountId: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  zipCode: types.maybeNull(types.string)
});

const careCreditAuthentication = types.model('careCreditAuthentication', {
  tokenId: types.optional(types.string, ''),
  merchantId: types.optional(types.string, ''),
  clientTransId: types.optional(types.string, ''),
  childMid: types.optional(types.string, ''),
  pcgc: types.optional(types.string, '')
});

const payment = types.model('payment', {
  uniqueId: types.string,
  amount: types.number,
  createdAt: types.string
});

const CareCredit = types
  .model('CareCredit', {
    promo: types.optional(types.string, ''),
    amount: types.optional(types.string, ''),
    availableErrors: types.frozen(AuthenticationErrors),
    careCreditCardHolder: types.optional(
      careCreditCardHolder,
      defaultCardHolderValues
    ),
    careCreditAuthentication: types.maybeNull(careCreditAuthentication),
    payment: types.maybeNull(payment)
  })
  .actions(self => {
    const handleErrorOccurred = errorMessage => {
      if (errorMessage === 'max attempts exceeded') {
        return {
          errors: self.availableErrors.maxAttemptsExceeded
        };
      } else {
        return {
          errors: self.availableErrors.unresolvedError,
          details: errorMessage
        };
      }
    };
    const mutationErrorOccurred = (commandId, mutationErrors) => {
      if (mutationErrors) {
        return {
          errors: self.availableErrors.mutationError,
          details: mutationErrors
        };
      }
      if (!commandId) {
        return {
          errors: self.availableErrors.commandIdMissing
        };
      }
    };
    const fetchAuthenticationToken = async commandId => {
      console.log('fetchAuthenticationToken', commandId);
      return await self.query(
        CC_GET_AUTHENTICATION_TOKEN,
        {
          commandId: commandId
        },
        { isCritical: false }
      );
    };
    const fetchTransactionDetails = async commandId => {
      console.log('fetchTransactionDetails', commandId);
      return await self.query(
        CC_GET_TRANSACTION_DETAILS,
        {
          commandId: commandId
        },
        { isCritical: false }
      );
    };

    const transactionStopPollFunc = ({ careCreditTransactionDetails }) => {
      console.log('transactionStopPollFunc', careCreditTransactionDetails);
      // Do not stop poll until you have a not null response
      return !!careCreditTransactionDetails;
    };
    const authenticationStopPollFunc = ({
      careCreditAuthenticationDetails
    }) => {
      console.log(
        'authenticationStopPollFunc',
        careCreditAuthenticationDetails
      );
      // Do not stop poll until you have a not null response
      return !!careCreditAuthenticationDetails;
    };
    return {
      setCareCreditSelection: ({ amount, promo }) => {
        self.amount = parseFloat(amount / 100.0).toFixed(2);
        self.promo = promo;
      },
      getTransactionDetails: flow(function* (
        memberId,
        accountId,
        billsAndAmounts
      ) {
        try {
          const { careCreditTransaction } = yield self.mutate(
            CC_TRANSACTION,
            {
              memberId: memberId,
              accountId: accountId,
              sessionToken: self.careCreditAuthentication.tokenId,
              ...(billsAndAmounts?.length && {
                billsAndAmounts: billsAndAmounts
              })
            },
            { isCritical: false }
          );

          const { commandId, errors: mutationError } = careCreditTransaction;
          const error = mutationErrorOccurred(commandId, mutationError);
          if (error) return error;

          const { careCreditTransactionDetails } = yield self.poll(
            () => fetchTransactionDetails(commandId),
            transactionStopPollFunc
          );

          if (careCreditTransactionDetails.errors?.length) {
            return {
              from_server: true,
              errors: self.availableErrors.transactionDetailsError,
              details: careCreditTransactionDetails.errors
            };
          }

          if (careCreditTransactionDetails.payment) {
            self.applyWithApiStatus(careCreditTransactionDetails);
          }
          return {};
        } catch (e) {
          return handleErrorOccurred(e);
        }
      }),
      validatePayableStandingAmount: flow(function* (currentStandingAmount) {
        try {
          console.log(
            `Fetch Payable Standing Amount for account id: ${SessionParams.accountId}`
          );
          const { payableStandingAmount } = yield self.query(
            GET_PAYABLE_STANDING_AMOUNT
          );

          if (payableStandingAmount != currentStandingAmount) {
            return {
              errors_header:
                self.availableErrors.standingAmountDiscrepancyErrorHeader,
              details: self.availableErrors.standingAmountDiscrepancyDetails,
              reload_needed: true,
              from_server: true
            };
          }

          return {};
        } catch (e) {
          return handleErrorOccurred(e);
        }
      }),
      getAuthenticationToken: flow(function* (memberId, accountId) {
        try {
          console.log(
            `[CareCredit] - fetching command id for member: ${memberId} account: ${accountId}`
          );
          const { careCreditAuthentication } = yield self.mutate(
            CC_AUTHENTICATION,
            {
              memberId: memberId,
              accountId: accountId
            },
            { isCritical: false }
          );
          const { commandId, errors: mutationError } = careCreditAuthentication;
          const error = mutationErrorOccurred(commandId, mutationError);

          if (error) {
            if (
              error.details?.length &&
              error.details[0] === AuthenticationErrors.zeroBalance
            ) {
              error.from_server = true;
              error.details = AuthenticationErrors.zeroBalanceDetails;
            }
            return error;
          }

          console.log(
            `[CareCredit] - polling authentication details for command ${commandId}`
          );
          const { careCreditAuthenticationDetails } = yield self.poll(
            () => fetchAuthenticationToken(commandId),
            authenticationStopPollFunc
          );

          if (careCreditAuthenticationDetails.errors?.length) {
            return {
              from_server: true,
              errors: self.availableErrors.authenticationDetailsError,
              details: careCreditAuthenticationDetails.errors
            };
          }
          if (!careCreditAuthenticationDetails.careCreditCardHolder) {
            try {
              // Load guarantor details
              const { guarantorOfAccount } = yield self.query(
                GET_MEMBERS_ADDRESS_INFO,
                { accountId: SessionParams.accountId },
                { isCritical: false }
              );

              // If guarantor exists, modify response as cardHolder response
              if (guarantorOfAccount) {
                const flatGuarantorDetails = {
                  ...guarantorOfAccount,
                  ...guarantorOfAccount.addressDetails
                };
                // Converting `c` to Capital `C` as careCredit Expect to get.
                flatGuarantorDetails.zipCode = flatGuarantorDetails.zipcode;

                // use default values, and override what came from guarantor (there is some that guarantor doesnt
                // return and the cardHolder need.
                careCreditAuthenticationDetails.careCreditCardHolder = {
                  ...defaultCardHolderValues,
                  ...flatGuarantorDetails
                };
              }
            } catch (e) {
              console.log('Cant load guarantor details'); // Do nothing, will use default in the finally.
            } finally {
              if (!careCreditAuthenticationDetails.careCreditCardHolder) {
                careCreditAuthenticationDetails.careCreditCardHolder = defaultCardHolderValues;
              }
            }
          }
          self.applyWithApiStatus(careCreditAuthenticationDetails);

          return {};
        } catch (e) {
          return handleErrorOccurred(e);
        }
      }),
      lookup: flow(function* () {
        try {
          // We dont care about the result, just fire the mutation
          yield self.mutate(
            CC_LOOKUP,
            { accountId: SessionParams.accountId },
            { isCritical: false }
          );
          return true;
        } catch (e) {
          console.log(`Cant lookup for accountId ${SessionParams.accountId}`);
          return false;
        }
      })
    };
  })
  .views(self => ({
    get digitalBuyAuthentication() {
      const {
        merchantId: merchantID,
        ...otherCCAuthenticationFields
      } = self.careCreditAuthentication;
      return {
        ...otherCCAuthenticationFields,
        ...{
          merchantID,
          transAmount1: self.amount,
          transPromo1: self.promo
        },
        ...{
          custAddress1: self.careCreditCardHolder.address || '',
          custAddress2: self.careCreditCardHolder.address2 || '',
          custCity: self.careCreditCardHolder.city || '',
          custState: self.careCreditCardHolder.state || '',
          custZipCode: self.careCreditCardHolder.zipCode || '',
          custFirstName: self.careCreditCardHolder.firstName || '',
          custLastName: self.careCreditCardHolder.lastName || ''
        }
      };
    },
    get isCardHolder() {
      return (
        self.careCreditCardHolder?.externalAccountId !==
        defaultCardHolderValues.externalAccountId
      );
    }
  }));

export default types.compose(ApiModel, CareCredit);
