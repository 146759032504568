import { createTheme, adaptV4Theme } from '@mui/material/styles';
import {
  STANDARD_RADIUS,
  STANDARD_SPACING as spacing
} from './constantUnits.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { blue } from '@mui/material/colors';

let defaultTheme = createTheme();

/*breakpoints: {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },*/

const palette = {
  primary: { main: '#076600' },
  secondary: { main: '#0061ff' },
  error: { main: '#cc0033' },
  warning: { main: '#ff941a' },
  green: '#138651',
  lightgreen: '#33AE75',
  white: '#ffffff',
  black: '#1b2733',
  customOrange: '#ff941a',
  pitchBlack: '#000000',
  text: {
    primary: '#555555',
    secondary: '#737373'
  },
  formBorder: '#e6e5e5',
  lightgreyBorder: '#e1e1e1',
  header: '#ffffff',
  offWhiteBackground: '#fafafa',
  brightBackground: '#e4f2ff',
  lightGreenBackground: '#e7f4ee',
  lightGrayBackground: '#f4f5f6',
  lightGrey: '#5a687b',
  veryLightGrey: '#f5f5f5',
  lightRed: '#fbeae5',
  darkGrey: '#7b7b7b',
  button: {
    primary: {
      text: '#ffffff',
      background: {
        default: '#0061ff',
        hover: '#004dc9',
        active: '#003994',
        disabled: '#d3d3d3'
      }
    },
    secondary: {
      text: '#0061ff',
      background: {
        default: '#ffffff',
        hover: '#f5f5f5',
        active: '#e3e3e3',
        disabled: '#ffffff'
      },
      border: '#e0e0e0'
    }
  }
};

export default {
  ...createTheme({
    ...adaptV4Theme({
      overrides: {
        MuiSelect: {
          root: {
            color: palette.text.primary
          }
        },
        MuiAppBar: {
          root: {
            '&&': {
              backgroundColor: palette.white
            }
          }
        },
        MuiCard: {
          root: {
            borderRadius: STANDARD_RADIUS
          }
        },
        MuiTable: {
          root: {
            [defaultTheme.breakpoints.down('sm')]: {
              '& th, td, button .text': {
                fontSize: `${spacing / 10}rem`
              }
            }
          }
        },
        MuiFormLabel: {
          asterisk: {
            color: palette.warning.main,
            '&$error': {
              color: palette.warning.main
            }
          }
        },
        MuiPaper: {
          borderRadius: STANDARD_RADIUS,
          root: {
            borderRadius: STANDARD_RADIUS
          }
        }
      }
    }),
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: palette.pitchBlack
          },
          colorPrimary: {
            color: palette.primary.main
          },
          colorSecondary: {
            color: palette.secondary.main
          },
          colorError: {
            color: palette.error.main
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          rounded: {
            borderRadius: '0.8rem'
          },
          root: {
            '.Mui-focusVisible': {
              outline: '2px solid black',
              'outline-offset': '-2px'
            }
          }
        }
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            paddingBottom: spacing,
            paddingTop: spacing
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            marginTop: '.5rem',
            marginBottom: '.5rem'
          }
        }
      },
      MuiSelect: {
        defaultProps: {
          IconComponent: ExpandMoreIcon,
          variant: 'standard'
        }
      },
      MuiTextField: {
        defaultProps: {
          background: blue,
          variant: 'standard'
        }
      },
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            boxShadow:
              '0 2px 12px 0 rgba(0, 0, 0, 0.07), 0 2px 5px 0 rgba(0, 0, 0, 0.12)'
          }
        }
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: palette.secondary.main
            },
            '&.Mui-completed': {
              color: palette.secondary.main
            }
          }
        }
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          color: 'primary',
          focusRipple: false
        },
        styleOverrides: {
          root: {
            '&.Mui-focusVisible': {
              outline: '2px solid black',
              'outline-offset': '-2px'
            }
          },
          textSecondary: {
            border: `2px solid ${palette.button.secondary.border}`
          },
          outlinedSecondary: {
            border: `2px solid ${palette.button.secondary.border}`,
            '&:hover': {
              border: `1px solid ${palette.secondary.main}`
            }
          },
          textPrimary: {
            color: palette.text.primary
          },
          containedPrimary: {
            backgroundColor: palette.button.primary.background.default,
            '&:hover': {
              backgroundColor: palette.button.primary.background.hover
            },
            '&:active': {
              backgroundColor: palette.button.primary.background.active
            },
            '&:disabled': {
              backgroundColor: palette.button.primary.background.disabled
            }
          }
        }
      },
      MuiIconButton: {
        defaultProps: {
          focusRipple: false
        },
        styleOverrides: {
          root: {
            '&.Mui-focusVisible': {
              outline: '2px solid black',
              'outline-offset': '-2px'
            }
          }
        }
      },
      MuiFab: {
        defaultProps: {
          focusRipple: false
        },
        styleOverrides: {
          root: {
            '&.Mui-focusVisible': {
              outline: '2px solid black',
              'outline-offset': '-2px'
            }
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            '&.Mui-focusVisible': {
              outline: '2px solid black',
              'outline-offset': '-2px'
            }
          }
        }
      },
      MuiTab: {
        defaultProps: {
          focusRipple: false
        },
        styleOverrides: {
          root: {
            '&.Mui-focusVisible': {
              outline: '2px solid black',
              'outline-offset': '-2px'
            }
          }
        }
      },
      MuiSwitch: {
        defaultProps: {
          focusRipple: false
        },
        styleOverrides: {
          root: {
            '.Mui-focusVisible': {
              outline: '2px solid black',
              'outline-offset': '-2px'
            }
          }
        }
      },
      MuiCheckbox: {
        defaultProps: {
          focusRipple: false
        },
        styleOverrides: {
          root: {
            '&.Mui-focusVisible': {
              outline: '2px solid black',
              'outline-offset': '-2px'
            }
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&.Mui-focusVisible': {
              outline: '2px solid black',
              'outline-offset': '-2px'
            }
          }
        }
      }
    },
    palette: palette,

    typography: {
      htmlFontSize: 10,
      useNextVariants: true,
      fontFamily: '"Rubik", "Helvetica", "Arial", sans-serif',
      h1: {
        fontSize: '4.2rem',
        lineHeight: '6rem',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '3.2rem'
        }
      },
      h2: {
        fontSize: '3.6rem',
        lineHeight: '5.2rem',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '3rem'
        }
      },
      h3: {
        fontSize: '3.2rem',
        lineHeight: '4.6rem',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '2.2rem'
        }
      },
      h4: {
        fontSize: '2.4rem',
        fontWeight: 400,
        lineHeight: '3.4rem'
      },
      h5: {
        fontSize: '2rem',
        lineHeight: '2.8rem',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '1.8rem'
        }
      },
      h6: {
        fontSize: '1.8rem',
        lineHeight: '2.4rem'
      },
      subtitle1: {
        fontWeight: 400,
        lineHeight: '2.4rem',
        fontSize: '1.6rem',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '1.4rem'
        }
      },
      subtitle2: {
        fontSize: '1.4rem',
        lineHeight: '2.2rem'
      },
      body1: {
        fontSize: '1.3rem',
        fontWeight: 400,
        textTransform: 'none',
        letterSpacing: 0,
        lineHeight: '1.85rem'
      },
      body2: {
        fontSize: '1.2rem',
        fontWeight: 400,
        textTransform: 'none',
        letterSpacing: 0,
        lineHeight: '1.6rem'
      },
      caption: {
        fontSize: '1.1rem',
        lineHeight: '1.45rem'
      }
    }
  }),
  spacing: factor => spacing * factor
};
