//client-side simulations for server responses
//https://github.com/apollographql/apollo-client/issues/4843
import { gql } from '@apollo/client';
import { cloneDeep, merge } from 'lodash';
import { fullOffersFields } from './queries/offers';
import { memberFields } from './queries/offers';

const loginEnv =
  typeof process.env.REACT_APP_LOGIN_VAR === 'string' &&
  process.env.REACT_APP_LOGIN_VAR.trim() === 'true';

const ppv4Env =
  typeof process.env.REACT_APP_PPV4_VAR === 'string' &&
  process.env.REACT_APP_PPV4_VAR.trim() === 'true';

let _createPpCommandId,
  _pollingTries = 0;

export const resolvers = {
  Query: {
    loginFields: (_, { billId, notificationId }, { cache }) => {
      const returnObj = {
        __typename: 'LoginFields',
        payableId: '12',
        error: null
      };

      const identifier = billId || notificationId;

      switch (identifier) {
        case 'ssnScenario': {
          merge(returnObj, { loginField: 'ssn' });
          break;
        }
        case 'accountNumberScenario': {
          merge(returnObj, { loginField: 'account_number' });
          break;
        }
        case 'zipScenario': {
          merge(returnObj, { loginField: 'zip' });
          break;
        }
        default:
          merge(returnObj, {
            loginField: null,
            payableId: null,
            error: 'getField failed'
          });
      }

      return returnObj;
    },
    payables: (_, { filterClosedBills = true }, { cache }) => {
      const query = gql`
        {
          payables {
            hasGrouping
            affiliatePayables {
              providerName
              providerInternalName
              standingAmount
              url
              token
            }
            payables {
              accountNumber
              accountId
              id
              standingAmount
              offer
              aNextPayment
              bills {
                id
                admissionDate
                amountDue
                displayName
                isBeforeCollection
                isBillPastDue
                rawVisitType
                aPromptDiscount
                selfPay
                status
                eligiblePromptDiscount
                dunningCode
                claimNo
                paymentPlanExists
                partnerName
                adjustments {
                  kind
                }
                aPromptPayDiscount
                aReliefDiscount
                aPendingInsurance
              }
              paymentPlan {
                id
                balanceRemaining
                planType
                expectedAmount
                standingAmount
                amountOutOfPlan
                status
                nextStandingAmount
                missedInstallmentsCount
                missedInstallmentsAmount
                startDate
                lastInstallmentDate
                leftInstallmentsCount
                installmentsNumber
                fundedBy
                genericIdentifier
                remainingInstallmentsTotal
                amountPerInstallment
                lastInstallmentAmount
                totalPaidBalance
                payableId
                paymentMethod {
                  id
                  cardType
                  expiredAt
                  last4
                }
                nextInstallment {
                  installmentDate
                  amountToPay
                  amountToPayWithFee
                  serviceFee
                }
                billIds
                claimNumbers
                serviceFeeAmount
              }
              assistMaxPpSetting
              assistOffersByScore {
                patientScore
                defaultOption
                firstOffer
                secondOffer
                thirdOffer
                otherOptionsPatient
              }
              limits {
                maxPaymentsNumber
                minStandingAmount
                lastPaymentMinAmount
                minPaymentAmount
              }
              payee {
                companyName
                companyDisplayName
                paypage
                paypageId
                creditCardProcessor
                vaultUrl
                processorsGenericFields
                echeckPermissions
                creditCardPermissions
                paypalPermissions
                paypalClientToken
                paymentApplicationStrategy
                provider {
                  id
                  contactLink
                  email
                  hoursOfOperation
                  internalName
                  name
                  phone
                  state
                  tollFreePhone
                  zipcode
                  facilityName
                }
              }
              guarantor {
                score {
                  capacity
                  segmentCode
                }
                externalId
              }
              scheduledPayments {
                id
                amount
                userPayment
              }
              discount {
                expirationDate
                uniqueIdentifier
              }
              ${fullOffersFields}
            }
          }
        }
      `;

      const { payables } = cloneDeep(cache.readQuery({ query }));
      payables.payables.forEach(singlePayable => {
        if (
          singlePayable.scheduledPayments &&
          singlePayable.scheduledPayments.length &&
          typeof singlePayable.scheduledPayments[1].userPayment === 'string'
        ) {
          //TODO: manipulating original object, need to create copy
          singlePayable.scheduledPayments.forEach(payment => {
            const value = JSON.parse(payment.userPayment);
            payment.userPayment = {
              scheduled_on: value.scheduled_on,
              id: value.id
            };
          });
        }

        if (filterClosedBills) {
          singlePayable.bills = singlePayable.bills.filter(
            bill => bill.amountDue > 0
          );
        }
      });

      return payables;
    },
    limits: (_, { standingAmount, offer }, { cache }) => {
      const minPaymentAmount = 5000;
      const maxPayments = offer
        ? Math.min(
            36,
            offer,
            standingAmount
              ? Math.floor(standingAmount / minPaymentAmount)
              : offer
          )
        : 36;
      return {
        __typename: 'GetLimits',
        maxPaymentsNumber: maxPayments,
        minStandingAmount: 45000,
        lastPaymentMinAmount: 100,
        minPaymentAmount: minPaymentAmount
      };
    },
    bill: (_, { id }, { cache }) => {
      const query = gql`
        {
          payables {
            payables {
              id
              bills {
                id
                displayName
                admissionDate
                rawVisitType
                isBillPastDue
                amountDue
                visitNumber
                accountNumber
                aPendingInsurance
                aInsuranceResp
                aPositiveCollectionPlacement
                aNegativeCollectionPlacement
                aPayerDiscount
                aPatientPayments
                insuranceAmount
                aTotalBilled
                dunningCode
                selfPay
                claimNo
                paymentPlanExists
                eligiblePromptDiscount
                adjustments {
                  kind
                }
                charges {
                  id
                  amount
                  cptCode {
                    code
                    #grouper
                    translation
                  }
                  date
                  description
                  #quantity
                  revenueCode {
                    code
                    translation
                  }
                }
                planCoverages {
                  payer
                  link
                  phone
                  priority
                  insuranceName
                  insuranceId
                  insurancePlan
                }
              }
            }
          }
        }
      `;
      const { payables } = cloneDeep(cache.readQuery({ query }));

      let foundBill;
      const payablesArr = payables.payables;
      for (let i = 0, len = payablesArr.length; i < len; i++) {
        for (
          let j = 0, billsLen = payablesArr[i].bills.length;
          j < billsLen;
          j++
        ) {
          if (payablesArr[i].bills[j].id === id) {
            foundBill = payablesArr[i].bills[j];
            break;
          }
        }
      }
      return foundBill;
    },
    translationInfo: (_, args, { cache }) => {
      const query = gql`
        {
          translationInfo {
            currentLanguage
            currentCompany
            translationFiles {
              language
              links
            }
          }
        }
      `;

      const { translationInfo } = cloneDeep(cache.readQuery({ query }));
      return translationInfo;
    },
    settings: (_, args, { cache }) => {
      const query = gql`
        {
          settings {
            id
            disabled
            configurations
            settingName
          }
        }
      `;

      const { settings } = cloneDeep(cache.readQuery({ query }));

      const cloneSettings = [];
      settings.forEach(setting => {
        cloneSettings.push({
          ...setting,
          configurations: JSON.parse(setting.configurations)
        });
      });

      if (ppv4Env) {
        let ppv4Settings = cloneSettings.find(
          setting => setting.settingName === 'payment_plan_v4'
        );

        let revenueModelSettings = cloneSettings.find(
          setting => setting.settingName === 'revenue_model_type'
        );
        ppv4Settings.disabled = false;
      }

      return cloneSettings;
    },

    providerDetails: (_, { providerName }, { cache }) => {
      const query = gql`
        {
          providerDetails {
            name
            contactLink
            hoursOfOperation
            zipcode
            address1
            address2
            state
            tollFreePhone
            phone
            email
            internalName
            companyInternalName
            cautiousLogin
            facilityName
          }
        }
      `;

      const { providerDetails } = cloneDeep(cache.readQuery({ query }));
      return providerDetails;
    },

    publicSettings: (_, { provider }, { cache }) => {
      const query = gql`
        {
          publicSettings {
            id
            disabled
            configurations
            settingName
          }
        }
      `;

      const { publicSettings } = cloneDeep(cache.readQuery({ query }));

      const clonePublicSettings = [];
      publicSettings.forEach(setting => {
        clonePublicSettings.push({
          ...setting,
          configurations: JSON.parse(setting.configurations)
        });
      });

      return clonePublicSettings;
    },

    mixpanelData: (_, args, { cache }) => {
      console.log('----mixpanelData query');
      const query = gql`
        {
          mixpanelData {
            data
          }
        }
      `;
      const { data } = cloneDeep(cache.readQuery({ query }));
      return data;
    },
    pastStatementsInfo: (_, { payableIds, accountNumber }, { cache }) => {
      const query = gql`
        {
          pastStatementsInfo {
            id
            createdAt
            balanceSnapshot
            statementUniqueId
            paperUploadedAt
          }
        }
      `;

      const { pastStatementsInfo } = cloneDeep(cache.readQuery({ query }));
      return pastStatementsInfo;
    },

    pastStatementsContent: (_, { id }, { cache }) => {
      const query = gql`
        {
          pastStatementsContent {
            id
            imageFormat
            preview
          }
        }
      `;

      const { pastStatementsContent } = cloneDeep(cache.readQuery({ query }));
      return pastStatementsContent;
    },

    previousPayments: (_, args, { cache }) => {
      const query = gql`
        {
          previousPayments {
            amount
            date
            paymentMethod {
              last4
              paymentSubType
              paymentType
            }
            status
          }
        }
      `;
      const { previousPayments } = cloneDeep(cache.readQuery({ query }));
      return previousPayments;
    },

    providerLinks: (_, args, { cache }) => {
      const query = gql`
        {
          providerLinks {
            helpLink
            providerMessageLink
          }
        }
      `;

      const { providerLinks } = cloneDeep(cache.readQuery({ query }));
      return providerLinks;
    },
    guarantorOfAccount: (_, { accountNumber }, { cache }) => {
      const query = gql`
        {
          guarantorOfAccount {
            id
            guarantorAccountNumbers
            firstName
            lastName
            name
            phoneNumber {
              allowSubscriptionChange
              createdAt
              displayValue
              id
              isOptedIn
              isPending
              number
              smsState
              lineType
            }
            addressDetails {
              address
              address2
              state
              city
              zipcode
            }
            emailAddresses {
              id
              email
              isOptedIn
              isPaperlessOptedIn
              isPaperlessPending
              isPaperlessSubscribed
              createdAt
              primary
            }
          }
        }
      `;

      const { guarantorOfAccount } = cloneDeep(cache.readQuery({ query }));
      return guarantorOfAccount;
    },
    getOffer: (_, { standingAmount }, { cache }) => {
      let months;
      if (standingAmount > 1500000) {
        months = 36;
      } else if (standingAmount < 1500000 && standingAmount > 1000000) {
        months = 24;
      } else if (standingAmount < 1000000 && standingAmount > 700000) {
        months = 12;
      } else if (standingAmount < 700000 && standingAmount > 100000) {
        months = 6;
      } else {
        months = 1;
      }
      return { __typename: 'GetOffer', offer: months };
    },

    creditCards: (_, { memberId }, { cache }) => {
      const query = gql`
        {
          creditCards {
            id
            expiredAt
            last4
            cardType
          }
        }
      `;

      const { creditCards } = cloneDeep(cache.readQuery({ query }));
      return creditCards;
    },

    echecks: (_, { memberId }, { cache }) => {
      const query = gql`
        {
          echecks {
            accountNumber
            accountType
            billingName
            id
            routingNumber
          }
        }
      `;

      const { echecks } = cloneDeep(cache.readQuery({ query }));
      return echecks;
    },
    validateCreditCard: (_, { ...args }, { cache }) => {
      return { __typename: 'ValidateCreditCard', valid: true, errors: null };
    },
    validateEcheck: (_, { ...args }, { cache }) => {
      return {
        __typename: 'ValidateEcheck',
        valid: true, //false,
        errors: [] //['routing_number', 'account_number']
      };
    },
    termsResult: (_, { termsInput }, { cache }) => {
      const { amountPerInstallment, serviceFee, totalBalance } = termsInput;
      const payments = Math.round(
        totalBalance / (amountPerInstallment - serviceFee)
      );
      const lastPayment =
        totalBalance - (payments - 1) * (amountPerInstallment - serviceFee);
      const totalFees = payments * serviceFee;

      return {
        __typename: 'TermsResult',
        errors: null,
        terms: {
          __typename: 'TermsResultTerms',
          amountPerInstallment: amountPerInstallment,
          amountPerInstallmentTotal: amountPerInstallment + serviceFee,
          lastInstallmentAmount: lastPayment,
          lastInstallmentAmountWarning: (serviceFee / lastPayment) * 100 > 25,
          lastInstallmentTotal: lastPayment + serviceFee,
          serviceFee: serviceFee,
          totalBalance: totalBalance,
          totalBalanceIncludingFees: totalBalance + totalFees,
          totalFees: totalFees,
          totalNumberOfInstallments: payments
        }
      };
    },
    fullOffersResult: (_, { totalBalance }, { cache }) => {
      const serviceFee = 395;
      let offer;
      if (totalBalance >= 1000000) {
        offer = 60;
      } else if (totalBalance >= 300000) {
        offer = 36;
      } else if (totalBalance >= 150000) {
        offer = 24;
      } else if (totalBalance >= 100000) {
        offer = 12;
      } else if (totalBalance >= 2000) {
        offer = 6;
      } else if (totalBalance >= 500) {
        offer = 1;
      } else {
        offer = null;
      }

      const amountPerInstallment = totalBalance / offer;
      const lastPayment =
        totalBalance - (offer - 1) * (amountPerInstallment - serviceFee);

      const getPayments = (offer, offerType) => {
        const amountPerInstallment = Math.round(totalBalance / offer);
        return {
          __typename: 'fullOffers',
          [`${offerType}Offer`]: offer || null,
          [`${offerType}OfferTotalFees`]: offer ? offer * serviceFee : null,
          [`${offerType}OfferAmountPerInstallment`]: offer
            ? amountPerInstallment
            : null,
          [`${offerType}OfferLastInstallmentAmount`]: offer
            ? lastPayment
            : null,
          [`${offerType}OfferTotalAmountPerInstallment`]: offer
            ? amountPerInstallment + serviceFee
            : null,
          [`${offerType}OfferTotalBalanceIncludingFees`]: offer
            ? totalBalance + offer * serviceFee
            : null,
          [`${offerType}OfferLastInstallmentTotalAmount`]: offer
            ? lastPayment + serviceFee
            : null
        };
      };

      const availableOffers = [1, 6, 12, 24, 36, 60];
      const offerIndex = availableOffers.indexOf(offer);

      return {
        __typename: 'FullOffersResult',
        errors: null,
        fullOffers: {
          ...getPayments(offer, 'recommended'),
          ...getPayments(availableOffers[offerIndex - 1], 'short'),
          ...getPayments(availableOffers[offerIndex + 1], 'long'),
          totalBalance,
          serviceFee
        }
      };
    },
    careCreditAuthenticationDetails: (_, { id }, { cache }) => {
      if (id === 'unresolvedCommandId') {
        return {
          __typename: 'careCreditAuthenticationDetails',
          careCreditAuthentication: {
            __typename: 'careCreditAuthentication',
            tokenId: '',
            merchantId: '',
            clientTransId: '',
            childMid: '',
            pcgc: ''
          },
          careCreditCardHolder: null,
          errors: ['Error']
        };
      }
      return {
        __typename: 'careCreditAuthenticationDetails',
        careCreditAuthentication: {
          __typename: 'careCreditAuthentication',
          tokenId: '145655432141',
          merchantId: '124345654524',
          clientTransId: '123456788876523',
          childMid: '12344',
          pcgc: '1234'
        },
        careCreditCardHolder: null,
        errors: []
      };
    },
    careCreditTransactionDetails: (_, { id }, { cache }) => {
      if (id === 'unresolvedCommandId') {
        return {
          __typename: 'CareCreditTransactionDetails',
          errors: ['Error'],
          payment: null
        };
      }
      if (id === 'patientClosedCommandId') {
        return {
          __typename: 'CareCreditTransactionDetails',
          errors: [],
          payment: null
        };
      } else {
        return {
          __typename: 'CareCreditTransactionDetails',
          errors: [],
          payment: {
            __typename: 'payment',
            uniqueId: 'uniqueId62',
            createdAt: '2020-09-30T03:43:35-07:00',
            amount: 4000
          }
        };
      }
    },
    cernerAuthenticationDetails: (_, { id }, { cache }) => {
      return {
        __typename: 'cernerAuthenticationDetails',
        errors: [],
        sessionId: 'auth-token-from-server',
        providerInternalName: 'cerner_provider',
        issuer: 'https://p1941.playground.patientportal.us-1.healtheintent.com'
      };
    },
    paymentPlanCommand: (_, { id }, { cache }) => {
      if (id === _createPpCommandId) {
        _pollingTries++;
      }
      //wait for 3 polling commands before returning valid response
      if (_pollingTries > 2) {
        _pollingTries = 0;
        return {
          __typename: 'CreatePaymentPlanCommandRes',
          errors: [],
          paymentPlan: {
            __typename: 'CreatePaymentPlanPaymentCommandPp',
            id: 1,
            fundedBy: 'provider'
          }
        };
      }
      return null;
    },
    feePaymentAmount: (
      _,
      { accountNumber, claimNumbers, amount },
      { cache }
    ) => {
      return 395;
    },
    getCuraeMonthlyPaymentInfo: (
      _,
      { monthlyAmount, monthDuration, apr },
      { cache }
    ) => {
      const query = gql`
        {
          getCuraeMonthlyPaymentInfo {
            monthlyAmount
            monthDuration
            apr
          }
        }
      `;

      const { getCuraeMonthlyPaymentInfo } = cloneDeep(
        cache.readQuery({ query })
      );
      return getCuraeMonthlyPaymentInfo;
    },
    getCuraeAccountStatus: (_, { id }, { cache }) => {
      const query = gql`
        {
          getCuraeAccountStatus {
            status
            availableCredit
            externalAccountId
          }
        }
      `;

      const { getCuraeAccountStatus } = cloneDeep(cache.readQuery({ query }));
      return getCuraeAccountStatus;
    },
    getCuraeUrlStatus: (_, { id }, { cache }) => {
      const query = gql`
        {
          getCuraeUrlStatus {
            status
            url
          }
        }
      `;

      const { getCuraeUrlStatus } = cloneDeep(cache.readQuery({ query }));
      return getCuraeUrlStatus;
    },
    financedOffers: (_, { id }, { cache }) => {
      const query = gql`
        {
          financedOffers {
            ${fullOffersFields},
            ${memberFields},
            errors
          }
        }
      `;
      const { financedOffers } = cloneDeep(cache.readQuery({ query }));
      return financedOffers;
    },
    verifyCode: (_, { ...args }, { cache }) => {
      return {
        __typename: 'verifyCode',
        accountNumber: 'Test1'
      };
    },
    phoneNumber: (_, { id }, { cache }) => {
      const query = gql`
        {
          phoneNumber {
            allowSubscriptionChange
            createdAt
            displayValue
            id
            isOptedIn
            isPending
            number
            smsState
            lineType
          }
        }
      `;

      const { phoneNumber } = cloneDeep(cache.readQuery({ query }));
      return phoneNumber;
    },
    commandV2: (_, args, { cache }) => {
      const query = gql`
        {
          commandV2 {
            status
            result {
              isSuccess
              description
            }
          }
        }
      `;
      const { commandV2 } = cloneDeep(cache.readQuery({ query }));
      return commandV2;
    }
  },
  /*Mutations*/
  Mutation: {
    subscribeEmail: (_, { id, optIn, optInPaperless }, { cache }) => {
      const query = gql`
        {
          guarantorOfAccount {
            id
            emailAddresses {
              id
              email
              isOptedIn
              isPaperlessOptedIn
              isPaperlessPending
              isPaperlessSubscribed
              createdAt
              primary
            }
          }
        }
      `;

      const { guarantorOfAccount } = cloneDeep(cache.readQuery({ query }));
      const foundEmailAddress = guarantorOfAccount.emailAddresses.find(
        email => email.id === id
      );

      if (foundEmailAddress) {
        foundEmailAddress.isOptedIn = optIn;
        foundEmailAddress.isPaperlessOptedIn = optInPaperless;
        foundEmailAddress.isPaperlessPending = optInPaperless;

        cache.writeQuery({
          query,
          variables: {},
          data: { guarantorOfAccount }
        });
      }

      return { emailAddress: foundEmailAddress };
    },
    addEmail: (_, { emailAddress, guarantorId, optInPaperless }, { cache }) => {
      const query = gql`
        {
          guarantorOfAccount {
            id
            emailAddresses {
              id
              email
              isOptedIn
              isPaperlessOptedIn
              isPaperlessPending
              isPaperlessSubscribed
            }
          }
        }
      `;

      console.warn(emailAddress, guarantorId, optInPaperless);
      const { guarantorOfAccount } = cloneDeep(cache.readQuery({ query }));

      const newEmail = {
        email: emailAddress,
        id: (Math.floor(Math.random() * 10000) + 1).toString(),
        createdAt: new Date().toString(),
        isOptedIn: true,
        isPaperlessOptedIn: false,
        isPaperlessSubscribed: false,
        isPaperlessPending: optInPaperless,
        primary: false,
        __typename: 'MemberEmailAddress'
      };

      guarantorOfAccount.emailAddresses.push(newEmail);

      cache.writeQuery({
        query,
        variables: {},
        data: { guarantorOfAccount }
      });
      return { emailAddress: newEmail };
    },
    deleteEmail: (_, { id, guarantorId }, { cache }) => {
      console.warn('---removeEmail...');
      const query = gql`
        {
          guarantorOfAccount {
            id
            emailAddresses {
              id
              email
              isOptedIn
              isPaperlessOptedIn
              isPaperlessPending
              isPaperlessSubscribed
              primary
            }
          }
        }
      `;

      let { guarantorOfAccount } = cloneDeep(cache.readQuery({ query }));
      guarantorOfAccount.emailAddresses =
        guarantorOfAccount.emailAddresses.filter(email => email.id !== id);

      cache.writeQuery({
        query,
        data: { guarantorOfAccount }
      });

      console.warn('about to return id:', id);
      return { id, __typename: 'DeleteEmail' };
    },
    addressChange: (_, { addressChange }, { cache }) => {
      const {
        address,
        address2,
        city,
        email,
        stateShortName,
        zipcode,
        primaryPhoneNumber
      } = addressChange;
      const query = gql`
        {
          guarantorOfAccount {
            id
            phoneNumber {
              number
            }
            addressDetails {
              address
              address2
              city
              state
              zipcode
            }
            emailAddresses {
              id
              email
              createdAt
              isOptedIn
              isPaperlessOptedIn
              isPaperlessPending
              isPaperlessSubscribed
            }
          }
        }
      `;

      console.log('---about to update details....');

      let { guarantorOfAccount } = cloneDeep(cache.readQuery({ query }));
      let primaryEmail;
      primaryPhoneNumber && (guarantorOfAccount.phone = primaryPhoneNumber);
      address && (guarantorOfAccount.addressDetails.address = address);
      address2 && (guarantorOfAccount.addressDetails.address2 = address2);
      city && (guarantorOfAccount.addressDetails.city = city);
      stateShortName &&
        (guarantorOfAccount.addressDetails.state = stateShortName);
      zipcode && (guarantorOfAccount.addressDetails.zipcode = zipcode);

      primaryEmail =
        guarantorOfAccount.emailAddresses.find(email => email.primary) ||
        guarantorOfAccount.emailAddresses[0] ||
        '';

      email && (primaryEmail.email = email);

      cache.writeQuery({
        query,
        variables: {},
        data: { guarantorOfAccount }
      });

      console.info('...about to return addressChange...');
      return {
        __typename: 'AddressChange',
        addressChange: {
          __typename: 'AddressChangeDetails',
          accountNumber: '000000000001',
          firstName: guarantorOfAccount.firstName,
          lastName: guarantorOfAccount.lastName,
          address: guarantorOfAccount.addressDetails.address,
          address2: guarantorOfAccount.addressDetails.address2,
          city: guarantorOfAccount.addressDetails.city,
          email: primaryEmail.email,
          primaryPhoneNumber: guarantorOfAccount.phone,
          stateShortName: guarantorOfAccount.addressDetails.state,
          zipcode: guarantorOfAccount.addressDetails.zipcode
        }
      };
    },
    resendPaperlessEmail: (_, { id, guarantorId }, { cache }) => {
      console.warn('---resendPaperlessEmail...');
      const query = gql`
        {
          guarantorOfAccount {
            id
            emailAddresses {
              id
              email
              isOptedIn
              isPaperlessOptedIn
              isPaperlessPending
              isPaperlessSubscribed
              primary
            }
          }
        }
      `;

      return { id, __typename: 'ResendPaperlessEmail' };
    },
    insuranceChange: (_, args, { cache }) => {
      const query = gql`
        {
          insuranceChange {
            accountNumber
            address
            city
            effectiveDate
            groupNumber
            id
            insuranceName
            insuranceNumber
            insuredEmployer
            insuredName
            insuredsDob
            patientRelation
            phoneNumber
            stateShortName
            zipcode
          }
        }
      `;

      let { insuranceChange } = cloneDeep(cache.readQuery({ query }));
      insuranceChange = merge(insuranceChange, args.insuranceChange);

      cache.writeQuery({
        query,
        variables: {},
        data: { insuranceChange }
      });

      return insuranceChange;
    },
    setLanguage: (_, { language }, { cache }) => {
      console.log(
        'resolver: setLanguage mutation -- setting language:',
        language
      );
      return true;
    },
    setSessionPayable: (_, { payableId, provider }, { cache }) => {
      //TODO: can save payableId and return next queries according to it
      return { setSessionPayable: true };
    },
    creditCardPayment: (_, { ...args }, { cache }) => {
      return {
        __typename: 'CreditCardPaymentRes',
        errors: null,
        payment: {
          __typename: 'CreditCardPaymentResPayment',
          id: 1,
          email: 'email@email.com',
          payments: { id: '12345', __typename: 'SinglePayment' },
          creditCard: {
            id: '123',
            expiredAt: '06/2020',
            __typename: 'CreditCardPayment'
          },
          uniqueId: 'abc123'
        }
      };
    },
    echeckPayment: (_, { ...args }, { cache }) => {
      return {
        __typename: 'EcheckPaymentMutationPayload',
        errors: null,
        payment: {
          __typename: 'ECheckPayment',
          id: '11',
          email: 'email@email.com',
          createdAt: '2020-09-30T03:43:35-07:00',
          payments: { id: '12345', __typename: 'SinglePayment' },
          creditCard: {
            id: '123',
            expiredAt: '06/2020',
            __typename: 'EcheckPayment'
          },
          uniqueId: 'bncy'
        }
      };
    },
    createPaymentPlan: (_, { ...args }, { cache }) => {
      const query = gql`
        {
          settings {
            id
            disabled
            configurations
            settingName
          }
        }
      `;

      const { settings } = cloneDeep(cache.readQuery({ query }));

      const isPPv4 = !settings.find(
        setting => setting.settingName === 'payment_plan_v4'
      ).disabled;
      if (isPPv4) {
        _createPpCommandId = Math.round(Math.random() * 10000000).toString();
      }

      return {
        __typename: 'CreatePaymentPlanRes',
        errors: null,
        ...(_createPpCommandId && { commandId: _createPpCommandId }),
        paymentPlan: {
          __typename: 'CreatePaymentPlanPayment',
          id: 1,
          email: 'email@email.com',
          payments: { id: '12345', __typename: 'SinglePayment' },
          creditCard: {
            id: '123',
            expiredAt: '06/2020',
            __typename: 'CreditCard'
          },
          uniqueId: '8'
        }
      };
    },
    editPaymentPlanBilling: (_, { ...args }, { cache }) => {
      return {
        __typename: 'EditPaymentPlanRes',
        errors: null,
        paymentPlan: {
          __typename: 'EditBillingMutationPayload',
          paymentMethodType: 'PaymentsService::CreditCard',
          paymentMethod: {
            __typename: 'PaymentMethodType',
            cardType: 'visa',
            id: '403'
          },
          uniqueId: '8'
        }
      };
    },
    paypalPayment: (_, { ...args }, { cache }) => {
      return {
        __typename: 'PaypalPaymentRes',
        errors: null,
        paymentPlan: {
          __typename: 'PaypalPaymentResPayment',
          id: 1,
          paymentMethodType: '',
          paymentMethod: {
            cardType: '',
            id: '231'
          }
        }
      };
    },
    verifyOrClearSession: (_, { provider }, { cache }) => {
      if (loginEnv || provider === 'invalidLogin') {
        console.log(`raising an error - user is not logged in`);
        throw 'stimulate an error from the graphQL server';
      }
      console.log(
        `resolver: verifyOrClearSession mutation -- setting isLoggedIn for ${provider} to true`
      );
      return { hasSession: true, boUserEmail: 'daisy@me.rolling' };
    },
    login: (_, { loginInput }, { cache }) => {
      console.log(`resolver organicLogin for ${loginInput.provider}`);
      return loginInput.provider !== 'invalidProvider';
    },
    loginFromMyChart: (_, { provider }, { cache }) => {
      console.log(`resolver organicLoginFromMyChart for ${provider}`);
      return provider == 'validProvider';
    },
    loginWithJwt: (_, { loginInput }) => {
      console.log(`resolver login with jwt token for ${loginInput.provider}`);
      return loginInput.token == 'validToken';
    },
    careCreditAccountLookup: (_, { accountId }, { cache }) => {
      console.log(`inside Lookup -> accountId ${accountId}`);
      if (accountId === 'failureAccount') {
        throw 'simulate error from graphQL';
      } else {
        return {
          __typename: 'careCreditAccountLookup',
          createdRequest: true
        };
      }
    },
    careCreditAuthentication: (
      _,
      { authParams: { memberId, accountId } },
      { cache }
    ) => {
      const returnObject = {
        __typename: 'careCreditAuthentication'
      };

      switch (memberId) {
        case 'missingCommandId':
          merge(returnObject, { commandId: null, errors: null });
          break;
        case 'mutationError':
          merge(returnObject, { commandId: '', errors: ['Some Error'] });
          break;
        case 'failedCommandTransaction':
          merge(returnObject, {
            commandId: 'unresolvedCommandId',
            errors: null
          });
          break;
        default:
          merge(returnObject, {
            commandId: '56789-12452343-1241342-asfsdvsd',
            errors: null
          });
      }

      return returnObject;
    },
    careCreditTransaction: (
      _,
      {
        transactionParams: {
          memberId,
          accountId,
          sessionToken,
          billsAndAmounts
        }
      },
      { cache }
    ) => {
      const returnObject = {
        __typename: 'careCreditTransaction'
      };
      switch (sessionToken) {
        case 'missingCommandId':
          merge(returnObject, { commandId: null, errors: null });
          break;
        case 'mutationError':
          merge(returnObject, { commandId: null, errors: ['Some Error'] });
          break;
        case 'failedToQuery':
          merge(returnObject, {
            commandId: 'unresolvedCommandId',
            errors: null
          });
          break;
        case 'patientClosedScenario':
          merge(returnObject, {
            commandId: 'patientClosedCommandId',
            errors: null
          });
          break;
        default:
          merge(returnObject, {
            commandId: 'transaction-details-command-id',
            errors: null
          });
      }
      return returnObject;
    },
    cernerAuthentication: (_, { bcsToken }, { cache }) => {
      console.log('-------cernerAuthentication:', bcsToken);
      return {
        __typename: 'cernerAuthentication',
        errors: [],
        commandId: 'cerner-authentication-command-id'
      };
    },
    updateConsent: (_, { ...args }, { cache }) => {
      return {
        __typename: 'UpdateConsentPayload',
        approved: true
      };
    },
    curaeAccountLookup: (_, { ssn, memberId }, { cache }) => {
      return {
        __typename: 'curaeAccountLookup',
        id: '1'
      };
    },
    curaeCreateUrl: (_, { memberId, payableId, accountId }, { cache }) => {
      return {
        __typename: 'curaeCreateUrl',
        id: '1'
      };
    },
    appointments: (_, { ...args }, { cache }) => {
      const query = gql`
        query appointments() {
          appointments() {
            id
            visitId
            providerName
            amountPaid
            nextPayment
            remainingBalance
            originalBalance
            cptCodes
            hasPaymentPlan
            patientName
            appearances {
              id
              status
              appearanceDate
              active
            }
          }
        }
      `;

      const { appointments } = cloneDeep(cache.readQuery({ query }));
      return appointments;
    },
    updatePhoneNumber: (_, { ...args }, { cache }) => {
      return true;
    },
    resendSms: (_, { ...args }, { cache }) => {
      return '7de54beb-9277-4932-a0a4-20f36922959f';
    },
    addPhoneNumber: (_, { ...args }, { cache }) => {
      return true;
    },
    updateSmsSubscription: (_, { ...args }, { cache }) => {
      const query = gql`
        {
          phoneNumber {
            isOptedIn
          }
        }
      `;

      const { phoneNumber } = cloneDeep(cache.readQuery({ query }));
      phoneNumber.isOptedIn = args.action === 'SUBSCRIBE';
      cache.writeQuery({
        query,
        variables: {},
        data: { phoneNumber }
      });
      return true;
    }
  }
};
