export const PAYABLE_SETTINGS_LIST = [
  'billsGroups',
  'defaultSorting',
  'showPastBills',
  'select_a_bill',
  'payment_plan',
  'assist_feature',
  'payment_method_permission',
  'care_credit_digital_buy',
  'payment_plan_v4',
  'quick_action_page_experience',
  'care_credit_links',
  'beautiful_bill_charge_config',
  'account_identifier_to_display',
  'curae',
  'limit_pp_offers',
  'payzen',
  'pre_service',
  'display_SB490_verbiage_in_PE'
];
