import { flow, types } from 'mobx-state-tree';
import { UPDATE_CONSENT } from '../graphql/mutations';
import ApiModel from './base/ApiModel';

const PatientConsent = types
  .model('PatientConsent', {
    approved: types.optional(types.boolean, false),
    isConsentPage: types.optional(types.boolean, false)
  })
  .actions(self => ({
    updateConsent: flow(function* (
      provider,
      payableId,
      consentId,
      confirmHash
    ) {
      try {
        const { updateConsent } = yield self.mutate(
          UPDATE_CONSENT,
          {
            provider: provider,
            payableId: payableId,
            confirmHash: confirmHash,
            consentId: consentId
          },
          { isCritical: false }
        );

        self.applyWithApiStatus(updateConsent);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject('PatientConsent updateConsent error: ' + e);
      }
    }),
      afterAttach: () => {
        self.isConsentPage = window.location.pathname.includes('/consents');
      },
  }));

export default types.compose(ApiModel, PatientConsent);
