//https://github.com/apollographql/apollo-client/issues/4843
import { gql } from '@apollo/client';
import { CLIENT } from '../offline_setup';

export {
  CREDIT_CARD_PAYMENT,
  ECHECK_PAYMENT,
  PAYPAL_PAYMENT,
  CREATE_PAYMENT_PLAN,
  UPDATE_PAYMENT_PLAN,
  CONVERT_PAYMENT_PLAN,
  CURAE_PAYMENT,
  PAY_TOWARD_FINANCED_PLAN,
  CREATE_CREDIT_CARD,
  CREATE_ECHECK,
  DIGITAL_WALLET_CREATE_SESSION,
  DIGITAL_WALLET_PAYMENT
} from './payment';

export { CC_AUTHENTICATION, CC_TRANSACTION, CC_LOOKUP } from './careCredit';
export { CURAE_ACCOUNT_LOOKUP, CREATE_URL } from './curae';
export { CERNER_AUTHENTICATION } from './cernerPagelet';
export {
  LOGIN,
  IS_LOGGED_IN,
  LOGIN_WITH_JWT,
  LOGIN_FROM_MY_CHART,
  LOGIN_DURATION_MONITOR,
  LOGOUT
} from './login';

export {
  CHANGE_NOTIFICATION_SETTINGS,
  REMOVE_EMAIL_ADDRESS,
  RESEND_PAPERLESS_EMAIL,
  ADD_EMAIL_ADDRESS,
  CHANGE_ADDRESS_INFORMATION
} from './notifications';

export { FIND_ACCOUNT } from './accountLookup';

export const SET_LANGUAGE = gql`
  mutation setLanguage($language: String!, $provider: String) {
    setLanguage(language: $language, provider: $provider) ${CLIENT}
  }
`;

export const UPDATE_MIXPANEL_DATA = gql`
  mutation updateMixpanelData($mixpanelData: String!) {
    updateMixpanelData(mixpanelData: $mixpanelData) ${CLIENT} {
      mixpanelData {
        data
      }
    }
  }
`;

export const SEND_JS_ERROR_LOG = gql`
  mutation jsErrorLog(
    $errorMessage: String!
    $errorStack: String
    $browser: String
    $clientUrl: String
    $clientActions: String
  ) ${CLIENT} {
    jsErrorLog(
      errorMessage: $errorMessage
      errorStack: $errorStack
      browser: $browser
      clientUrl: $clientUrl
      clientActions: $clientActions
    )
  }
`;

export const SET_PAYABLE = gql`
  mutation setSessionPayable($payableId: ID!, $provider: String!) {
    setSessionPayable(payableId: $payableId, provider: $provider) ${CLIENT}
  }
`;

export const CHANGE_INSURANCE_INFORMATION = gql`
  mutation changeInsuranceInformartion(
    $accountNumber: String!
    $effectiveDate: String!
    $insuranceName: String!
    $insuranceNumber: String!
    $groupNumber: String!
    $phoneNumber: String!
    $insuredEmployer: String!
    $address: String!
    $city: String!
    $stateShortName: String!
    $zipcode: String!
    $insuredName: String!
    $insuredsDob: String!
    $patientRelation: String!
  ) {
    insuranceChange(
      insuranceChange: {
        accountNumber: $accountNumber
        effectiveDate: $effectiveDate
        insuranceName: $insuranceName
        insuranceNumber: $insuranceNumber
        groupNumber: $groupNumber
        phoneNumber: $phoneNumber
        insuredEmployer: $insuredEmployer
        address: $address
        city: $city
        stateShortName: $stateShortName
        zipcode: $zipcode
        insuredName: $insuredName
        insuredsDob: $insuredsDob
        patientRelation: $patientRelation
      }
    ) ${CLIENT} {
      insuranceChange {
        accountNumber
        address
        city
        effectiveDate
        groupNumber
        id
        insuranceName
        insuranceNumber
        insuredEmployer
        insuredName
        insuredsDob
        patientRelation
        phoneNumber
        stateShortName
        zipcode
      }
    }
  }
`;

export const UPDATE_CONSENT = gql`
  mutation updateConsent(
    $consentId: ID!
    $payableId: ID!
    $confirmHash: String!
    $provider: String!
  ) {
    updateConsent(
      consentUpdate: {
        provider: $provider
        payableId: $payableId
        consentId: $consentId
        confirmHash: $confirmHash
      }
    ) ${CLIENT} {
      approved
    }
  }
`;
